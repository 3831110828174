import React, { useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Tickers from './ticker/Tickers';
import OrderBook from './orderBook/OrderBook';
import OrderForm from './orderForm/OrderForm';
import OrderHistory from './orderHistory/OrderHistory';
import Trades from './trades/Trades';

const ResponsiveGridLayout = WidthProvider(Responsive);
const ROW_HEIGHT = 4005 / 573;

function GridLayout() {
  const layouts = useMemo(() => ({
    lg: [
      {
        i: 'charts',
        x: 0,
        y: 0,
        w: 481,
        h: 573 / ROW_HEIGHT,
        minW: 481,
        minH: 573 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'tickers',
        x: 481,
        y: 0,
        w: 198,
        h: 801 / ROW_HEIGHT,
        minW: 198,
        minH: 801 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'order-book',
        x: 0,
        y: 573 / ROW_HEIGHT,
        w: 240.5,
        h: 744 / ROW_HEIGHT,
        minW: 240.5,
        minH: 744 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'order-form', x: 240.5, y: 573 / ROW_HEIGHT, w: 240.5, h: 431 / ROW_HEIGHT, static: true,
      },
      {
        i: 'order-history',
        x: 240.5,
        y: (573 + 431) / ROW_HEIGHT,
        w: 240.5,
        h: 313 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'trades', x: 481, y: 801 / ROW_HEIGHT, w: 198, h: 516 / ROW_HEIGHT, static: true,
      },
    ],
    md: [
      {
        i: 'charts',
        x: 0,
        y: 0,
        w: 481,
        h: 573 / ROW_HEIGHT,
        minW: 481,
        minH: 573 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'tickers',
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
      },
      {
        i: 'order-book',
        x: 0,
        y: 573 / ROW_HEIGHT,
        w: 240.5,
        h: 744 / ROW_HEIGHT,
        minW: 240.5,
        minH: 744 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'order-form', x: 240.5, y: 573 / ROW_HEIGHT, w: 240.5, h: 431 / ROW_HEIGHT, static: true,
      },
      {
        i: 'order-history',
        x: 240.5,
        y: (573 + 431) / ROW_HEIGHT,
        w: 240.5,
        h: 313 / ROW_HEIGHT,
        static: true,
      },
      {
        i: 'trades', x: 481, y: 801 / ROW_HEIGHT, w: 481, h: 516 / ROW_HEIGHT, static: true,
      },
    ],
  }
  ), []);

  return (
    <ResponsiveGridLayout
      class="layout"
      style={{
        maxWidth: 1440, margin: 'auto',
      }}
      layouts={layouts}
      containerPadding={[0, 0]}
      margin={[6, 6]}
      rowHeight={1}
      breakpoints={{ lg: 1023, md: 0 }}
      cols={{ lg: 481 + 198, md: 481 }}>
      <section key="tickers">
        <Tickers />
      </section>
      <section key="order-book">
        <OrderBook />
      </section>
      <section key="order-form">
        <OrderForm />
      </section>
      <section key="order-history">
        <OrderHistory />
      </section>
      <section key="trades">
        <Trades />
      </section>
    </ResponsiveGridLayout>
  );
}

export default GridLayout;
