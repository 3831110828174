import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import "./Header3.scss";
import logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as JoinImg } from "../../assets/images/join_img.svg";
import twitterImg from "../../assets/images/twitter_img.png";
import telegramImg from "../../assets/images/telegram_img.png";
import mediumImg from "../../assets/images/medium_img.png";
import pdf from "../../assets/pdf/volare.pdf";
import hamburgerImg from "../../assets/images/hamburger_img.png";
import closeImg from "../../assets/images/close_img.png";
import linkToImg from "../../assets/images/link_to_image.png";
import walletImg from "../../assets/images/wallet_img.png";
import disconnectWalletIng from "../../assets/images/disconnect_wallet_img.png";
import dropDownOpenImg from "../../assets/images/dropdown_open_img.png";
import dropDownCloseImg from "../../assets/images/dropdown_close_img.png";
import verifyKycImg from "../../assets/images/verify_kyc_img.png";
import searchAddressImg from "../../assets/images/search_img.png";
import disconnectWalletTabImg from "../../assets/images/close_fill_img.png";
import { useMediaQuery } from "react-responsive";
import MetamaskConnectButton from "../metamask/MetamaskConnectButton";
import EQWalletConnect from "../EQWalletConnect";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, signOut, user_info } from "../../../store/user/userSlice";
import { kycStatusTextInfo, statusLabel } from "../../../utils/KycUtil";
import { camelCase } from "../../../utils/CommonUtils";
import { dispatchRequest } from "../../../utils/dispatchRequest";
import { getSales } from "../../../store/public-sale/publicSaleThunk";
import _ from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { checkAddressKycStatus, createKycApplicant, getKycAccessToken, logout } from "../../../store/user/userThunk";
import BouncingText from "react-bouncing-text";
import { ModalContext } from "../../../App";
import MovingText from "react-moving-text";

export const VOLARE_KYC = "VOLAREKYC";

// const HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS", "WHITEPAPER"];
const HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS"];
// const MOBILE_HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS", "WHITEPAPER", "LITEPAPER"];
const MOBILE_HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS"];

const WHITE_PAPER_URL = process.env.REACT_APP_WHITEPAPER_URL;
const LITE_PAPER_URL = process.env.REACT_APP_LITEPAPER_URL;

const PAPER_LIST = [
  {
    id: 1,
    label: "WHITEPAPER",
    name: "white-paper",
    url: WHITE_PAPER_URL,
  },
  {
    id: 2,
    label: "LITEPAPER",
    name: "lite-paper",
    url: LITE_PAPER_URL,
  },
];

function Header3(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(user_info);
  const { kycVerification, EQAddress } = userInfo || {};
  const { openModal } = useContext(ModalContext);

  const modalId = useRef(-1);

  const [currentScroll, setCurrentScroll] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [openWalletTab, setOpenWalletTab] = useState(false);
  const [isExistPublicSale, setIsExistPublicSale] = useState(false);

  const closeWalletTab = () => {
    setOpenWalletTab(false);
  };

  const getPublicSaleData = async () => {
    const sales = camelCase(
      await dispatchRequest(dispatch, getSales, {
        lastId: 0,
        limit: 1,
        category: "all",
        stage: "presale",
      })
    );

    if (sales?.length > 0) setIsExistPublicSale(true);
  };

  const onClickTabFunction1 = () => {
    let popupX = document.body.offsetWidth / 2 - 700 / 2;
    let popupY = window.screen.height / 2 - 800 / 2;
    switch (kycVerification.isVerify) {
      case 0: {
        window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px, left=${popupX}, top=${popupY}, resizable=yes`);
        return;
      }
      case 1:
        switch (kycVerification.checkKycResult.status) {
          case 0: {
            window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px, left=${popupX}, top=${popupY}, resizable=yes`);
            return;
          }
          case 1: {
            const newId = openModal("KycStatus", {
              status: 1,
            });
            modalId.current = newId;
            return;
          }
          case 2: {
            window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px, left=${popupX}, top=${popupY}, resizable=yes`);
            return;
          }
          case 3: {
            const newId = openModal("KycStatus", {
              status: 3,
            });
            modalId.current = newId;
            return;
          }
          case 4:
            return;
          default:
            return;
        }
      default:
        return;
    }
  };

  const onClickTabFunction3 = () => {
    let popupX = document.body.offsetWidth / 2 - 420 / 2;
    let popupY = window.screen.height / 2 - 500 / 2;
    window.open(`${process.env.REACT_APP_CONNECTION_URL}disconnect`, "Disconnect", `width=420px,height=500px, left=${popupX}, top=${popupY},resizable=no`);
    dispatch(logout());
    setOpenWalletTab(false);
  };

  // const onClickTabFunction4 = () => {
  //   window.open("https://medium.com/volare/how-to-complete-kyc-verification-for-volare-public-sale-8ef2f8517dcc");
  //   setOpenWalletTab(false);
  // };

  const wallet_tab = [
    {
      icon: verifyKycImg,
      label: statusLabel(kycVerification, kycStatusTextInfo),
      name: "verify-KYC",
      onClickFunc: onClickTabFunction1,
    },
    // {
    //     icon: searchAddressImg,
    //     label: "Open in Volrscan",
    //     additionalIcon: linkToImg,
    //     onClickFunc: onClickTabFunction2,
    // },
    // {
    //   icon: disconnectWalletTabImg,
    //   label: "How to do KYC?",
    //   name: "how-to-do-KYC",
    //   onClickFunc: onClickTabFunction4,
    // },
    {
      icon: disconnectWalletTabImg,
      label: "Disconnect",
      onClickFunc: onClickTabFunction3,
    },
  ];

  const checkAddressKycStatus_ = async () => {
    try {
      return unwrapResult(
        await dispatch(
          checkAddressKycStatus({
            externalUserId: JSON.stringify(userInfo.addressUser.user_id),
          })
        )
      );
    } catch (e) {
      alert(e.message);
    }
  };

  const isPc = useMediaQuery({
    query: "(min-width:1200px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:992px)",
  });
  const isMobile = useMediaQuery({
    query: "(min-width:768px)",
  });
  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const checkScroll = useCallback(() => {
    setCurrentScroll(window.scrollY);
  }, []);

  useEffect(() => {
    if (selectedTab !== "") {
      window.location.href = `${window.location.origin}/#${selectedTab}`;
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   getPublicSaleData();
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
  }, [checkScroll]);

  const [mobileTab, setMobileTab] = useState(false);

  useEffect(() => {
    if (mobileTab) {
      document.body.classList.add("ReactModal__Body--open");
    } else {
      document.body.classList.remove("ReactModal__Body--open");
    }
  }, [mobileTab]);

  useEffect(() => {
    setMobileTab(false);
  }, [isTablet]);

  const headerTabClickHandler = (item) => {
    if (item === "WHITEPAPER") {
      setSelectedTab("");
      return;
    }

    setSelectedTab(item);
  };

  const mobileHeaderTabClickHandler = (item) => {
    if (item === "WHITEPAPER") {
      window.open(WHITE_PAPER_URL);
      return;
    }
    if (item === "LITEPAPER") {
      window.open(LITE_PAPER_URL);
      return;
    }

    setSelectedTab(item);
    setMobileTab(false);
  };

  const [isShowWhitePaperList, setIsShowWhitePaperList] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("exchange")) {
      setCurrentScroll(101);
    }
  }, [location]);

  useEffect(() => {
    if (openWalletTab) {
      checkAddressKycStatus_();
      window.addEventListener("click", closeWalletTab);
      return () => {
        window.removeEventListener("click", closeWalletTab);
      };
    }
  }, [openWalletTab]);

  const clickLogo = () => {
    setSelectedTab("");
    window.scrollTo(0, 0);
  };

  return (
    <header style={{ backgroundColor: currentScroll < 1 ? "transparent" : "black" }}>
      <nav id={"top-nav3"}>
        <Link to={"/#"} className="header-logo-box" onClick={() => clickLogo()}>
          <img className="top-logo" src={logo} alt={"Volare"} />
        </Link>
        {isTablet ? (
          <>
            <div style={{ display: "flex" }}>
              {HEADER_TAB.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      key={`header-${index}`}
                      className={`top-nav-button`}
                      onClick={() => headerTabClickHandler(item)}
                      onMouseEnter={() => {
                        if (item === "WHITEPAPER") {
                          setIsShowWhitePaperList(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (item === "WHITEPAPER") {
                          setIsShowWhitePaperList(false);
                        }
                      }}
                    >
                      {/* {item === "VOLRSCAN" && <JoinImg className="join-img" />} */}
                      <p className={`top-nav-button-label  ${selectedTab === item ? "selected" : ""}`}>{item}</p>
                      {isShowWhitePaperList && item === "WHITEPAPER" && (
                        <div className="paper-list">
                          <div className="paper-list-box">
                            {PAPER_LIST.map((el, index) => {
                              return (
                                <div className={`paper-list-box-item ${el.name}`} key={`paper-${el.id}`} onClick={() => window.open(el.url)}>
                                  {el.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* TODO :: 세일 프로젝트 시작 전 coming soon alert 출력 >> 세일 프로젝트 진행하게 되면 아래 주석된 Link 태그로 변경 */}
            {/* <div
              className={"top-nav-button sale-button"}
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => window.alert("september 27 open")}
            > */}
            <Link to={"/sale"} className={"top-nav-button sale-button"} style={{ display: "flex", alignItems: "center" }} onClick={() => setSelectedTab("")}>
              <div className="moving-text">VOLR SALE</div>
            </Link>
            {/* </div> */}
            {/*<MetamaskConnectButton className={'gradient-button'}>*/}
            {/*    Connect Wallet*/}
            {/*</MetamaskConnectButton>*/}
            {/* <div style={{ position: "relative" }}>
              <EQWalletConnect
                className={EQAddress ? "header-button connect" : "header-button connect-button"}
                isConnected={!!EQAddress}
                setOpenWalletTab={setOpenWalletTab}
              >
                {EQAddress ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img className={"disconnect-wallet-img"} src={disconnectWalletIng} alt="disconnect-wallet" />
                    {`${EQAddress.slice(0, 4)}...${EQAddress.slice(-4)}`}
                    <img className={"dropdown-navigate-img"} src={openWalletTab ? dropDownCloseImg : dropDownOpenImg} alt="dropdown" />
                  </div>
                ) : (
                  <>
                    <img className={"wallet-img"} src={walletImg} alt="wallet" />
                    Connect Wallet
                  </>
                )}
              </EQWalletConnect>
              {openWalletTab && (
                <div className="wallet-tab-wrapper">
                  {wallet_tab.map((el, index) => {
                    return (
                      <div className={`wallet-tab ${el.name}`} key={index} onClick={el.onClickFunc}>
                        <img className={"wallet-tab-icon"} src={el.icon} alt="tab-icon" />
                        {el.label}
                        {el.additionalIcon && <img className={"wallet-tab-icon additional"} src={el.additionalIcon} alt="tab-icon" />}
                      </div>
                    );
                  })}
                </div>
              )}
            </div> */}

            {/*<EQWalletConnect id={'eq-wallet-connect'} className={'gradient-button header'} isConnected={!!EQAddress}>*/}
            {/*    {EQAddress ? `${EQAddress.slice(0,4)}...${EQAddress.slice(-4)}` :*/}
            {/*        <>*/}
            {/*            <img className={'wallet-img'} src={walletImg} alt=""/>*/}
            {/*            Connect Wallet*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</EQWalletConnect>*/}
            {/*<div className='sns-wrapper'>*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('twitter')} src={twitterImg} alt={'twitterImg'} />*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('telegram')} src={telegramImg} alt={'telegramImg'} />*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('medium')} src={mediumImg} alt={'mediumImg'} />*/}
            {/*</div>*/}
          </>
        ) : (
          <button className="mobile-btn" onClick={() => setMobileTab(true)}>
            <img src={hamburgerImg} className="mobile-btn-img" alt="hamburger" />
          </button>
        )}
        {/* active mobile header */}
        {!isTablet && (
          <div className={`mobile-tab-box`} style={mobileTab ? { right: 0 } : {}}>
            <div className="mobile-tab-wrapper">
              <div className="mobile-close-tab">
                <button className="mobile-btn" onClick={() => setMobileTab(false)}>
                  <img src={closeImg} alt="close" className="mobile-btn-img" />
                </button>
              </div>
              {MOBILE_HEADER_TAB.map((item, index) => {
                return (
                  <div key={`tab-${index}`} className={`mobile-tab`} onClick={() => mobileHeaderTabClickHandler(item)}>
                    {/* {item === "VOLRSCAN" && <JoinImg className="join-img" />} */}
                    {item}
                  </div>
                );
              })}
              {/* TODO :: 세일 프로젝트 시작 전 coming soon alert 출력 >> 세일 프로젝트 진행하게 되면 아래 주석된 Link 태그로 변경 */}
              {/* <div
                onClick={() => {
                  window.alert("september 27 open");
                  setMobileTab(false);
                }}
                className={"mobile-tab"}
                style={{ display: "flex", alignItems: "center" }}
              > */}
              <Link to={"/sale"} onClick={() => setMobileTab(false)} className={"mobile-tab"} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                  <BouncingText text="VOLR SALE" className="top-nav-button-label highlight" clickable hoverable delay={30} duration={300} />
                </div>
              </Link>
              {/* </div> */}
              {/* <div className="mobile-tab">
                <EQWalletConnect
                  className={EQAddress ? "header-button connect" : "header-button"}
                  isConnected={!!EQAddress}
                  setOpenWalletTab={setOpenWalletTab}
                >
                  {EQAddress ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img className={"disconnect-wallet-img"} src={disconnectWalletIng} alt="disconnect-wallet" />
                      {`${EQAddress.slice(0, 4)}...${EQAddress.slice(-4)}`}
                      <img className={"dropdown-navigate-img"} src={openWalletTab ? dropDownCloseImg : dropDownOpenImg} alt="dropdown" />
                    </div>
                  ) : (
                    <>
                      <img className={"wallet-img"} src={walletImg} alt="wallet" />
                      Connect Wallet
                    </>
                  )}
                </EQWalletConnect>
              </div> */}
            </div>
          </div>
        )}
        {mobileTab && <div className="overlay" onClick={() => setMobileTab(false)} />}
      </nav>
    </header>
  );
}

export default Header3;
