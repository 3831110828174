import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import './CheckingModal.scss';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { BigNumber } from 'bignumber.js';
import {
  accountAddress,
  selectAccountAssetByIdInDex,
  selectAccountAssetByIdInWallet,
} from 'store/exchange/account/accountSlice';
import { currentNetworkId, selectNetworkById } from 'store/exchange/network/networksSlice';
import { selectAllCurrencies, selectCurrenciesTotal, selectCurrencyById } from 'store/exchange/currencies/currenciesSlice';
import { formatDigits } from 'view/router/exchange/util/numberUtil';
import { tokenImageColor } from 'view/router/exchange/util/assetImageSelector';
import { sendSignatureRequest } from 'view/common/listener/SignatureListener';
import { requestTransaction } from 'view/common/listener/ExtensionListener';
import {MAIN_DOWN_COLOR, MAX_DIGITS} from 'view/router/exchange/util/literal';
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";

const CHECKING_DEPOSIT = 0;
const CHECKING_WITHDRAW = 1;

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const checkingTypeArray = [
  { title: 'Deposit', type: CHECKING_DEPOSIT },
  { title: 'Withdraw', type: CHECKING_WITHDRAW },
];

function ModalChecking({ isOpen, setIsOpen }) {
  const currencies = useSelector(selectAllCurrencies);
  const currenciesTotal = useSelector(selectCurrenciesTotal);

  const [checkingBalance, setCheckingBalance] = useState(0);
  const [checkingType, setCheckingType] = useState(CHECKING_DEPOSIT);

  const symbolOptions = useMemo(() => currencies
    .map((el) => ({ value: el.id, label: el.name, image: tokenImageColor[el.name] || el.image }
    )), [currencies]);

  const [selectedOption, setSelectedOption] = useState(null);

  const selectedSymbol = useMemo(() => selectedOption?.label ?? '', [selectedOption]);

  const selectedCurrency = useSelector((state) => selectCurrencyById(state, selectedOption?.value));

  const { depositFee, withdrawFee } = selectedCurrency || {};

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (currenciesTotal > 0) {
      const first = _.head(currencies);
      const { id, name, image } = first || {};
      setSelectedOption({ value: id, label: name, image: tokenImageColor[name] || image });
    }
  }, [currencies, currenciesTotal]);

  // 입력 값 default 0으로 초기화
  useEffect(() => {
    if (!checkingBalance) {
      setCheckingBalance(0);
    }
  }, [checkingBalance]);

  useEffect(() => {
    setCheckingBalance(0);
  }, [checkingType, selectedOption]);

  const selectedAssetInfoInWallet = useSelector(
    (state) => selectAccountAssetByIdInWallet(state, selectedSymbol),
  ) ?? { unit: selectedSymbol, balance: 0 };

  const { balance: myWalletBalance } = selectedAssetInfoInWallet;

  const selectedAssetInfoInDex = useSelector(
    (state) => selectAccountAssetByIdInDex(state, selectedSymbol),
  ) ?? { unit: selectedSymbol, balance: 0 };

  const { balance: myDexBalance } = selectedAssetInfoInDex;

  const reduxCurrentNetworkId = useSelector(currentNetworkId);

  const reduxCurrentNetwork = useSelector(
    (state) => selectNetworkById(state, reduxCurrentNetworkId),
  ) ?? {};

  const reduxAccountAddress = useSelector(accountAddress);

  const fee = useMemo(
    () => (checkingType === CHECKING_DEPOSIT ? depositFee : withdrawFee),
    [checkingType, depositFee, withdrawFee],
  );

  const checkingTableInfo = useMemo(() => [
    { title: 'Wallet', balance: formatDigits(new BigNumber(myWalletBalance).toNumber(), MAX_DIGITS, 0) },
    { title: 'DEX', balance: formatDigits(new BigNumber(myDexBalance).toNumber(), MAX_DIGITS, 0) },
    // { title: 'fee', balance: formatDigits(new BigNumber(fee).toNumber(), 8, 0) },
  ], [myWalletBalance, myDexBalance]);

  const checkingResultTableInfo = useMemo(() => [
    {
      title: 'Wallet',
      balance: formatDigits(
        checkingType === CHECKING_DEPOSIT
          ? new BigNumber(myWalletBalance)
            .minus(checkingBalance ?? 0)
            .toNumber()
          : new BigNumber(myWalletBalance)
            .plus(checkingBalance ?? 0)
            .minus(checkingBalance ? fee : 0)
            .toNumber(),
        8,
        0,
      ),
    },
    {
      title: 'DEX',
      balance: formatDigits(checkingType === CHECKING_DEPOSIT
        ? new BigNumber(myDexBalance)
          .plus(checkingBalance ?? 0)
          .minus(checkingBalance ? fee : 0)
          .toNumber()
        : new BigNumber(myDexBalance).minus(checkingBalance ?? 0).toNumber(), MAX_DIGITS, 0),
    },
  ], [myWalletBalance, myDexBalance, checkingBalance, fee, checkingType]);

  const depositDisabled = useMemo(
    () => Boolean(new BigNumber(checkingBalance ?? 0).isLessThanOrEqualTo(fee)
        || new BigNumber(checkingBalance ?? 0).isGreaterThan(myWalletBalance)),
    [checkingBalance, myWalletBalance, fee],
  );

  const withdrawDisabled = useMemo(
    () => Boolean(new BigNumber(checkingBalance ?? 0).isLessThanOrEqualTo(fee)
    || new BigNumber(checkingBalance ?? 0).isGreaterThan(myDexBalance)),
    [checkingBalance, myDexBalance, fee],
  );

  const btnDisabledCondition = useMemo(
    () => (checkingType === CHECKING_DEPOSIT ? depositDisabled : withdrawDisabled),
    [checkingType, depositDisabled, withdrawDisabled],
  );

  useEffect(() => {
    if (!isOpen) {
      setCheckingType(CHECKING_DEPOSIT);
      setCheckingBalance(0);
    }
  }, [isOpen]);

  const onClickChecking = useCallback(() => {
    const {
      chain_id: chainId,
      chain_network_id: chainNetworkId,
      contract_address: contractAddress,
      micro_chain_id: microChainId,
    } = reduxCurrentNetwork || {};
    const loginType = sessionStorage.getItem('loginType');
    if (loginType * 1 === 1) {
      requestTransaction({
        networkId: reduxCurrentNetworkId,
        chainNetworkId,
        chainId,
        microChainId,
        address: reduxAccountAddress,
        contractAddressQuery: contractAddress,
        nonceDomain: `${process.env.REACT_APP_EXCHANGE_SERVER_URL}${process.env.REACT_APP_API_VERSION}dex/request/nonce`,
        transactionDomain: `${process.env.REACT_APP_EXCHANGE_SERVER_URL}${process.env.REACT_APP_API_VERSION}dex/user/${checkingType === CHECKING_DEPOSIT ? 'deposit' : 'withdraw'}`,
        functionName: checkingType === CHECKING_DEPOSIT ? 'Deposit' : 'Withdraw',
        tx_data: {
          symbol: selectedSymbol,
          amount: new BigNumber(checkingBalance).toString(),
        },
      });
    } else {
      sendSignatureRequest({
        network_id: reduxCurrentNetworkId,
        chain_network_id: chainNetworkId,
        chain_id: chainId,
        micro_chain_id: microChainId,
        address: reduxAccountAddress,
        contract_address: contractAddress,
        function_name: checkingType === CHECKING_DEPOSIT ? 'Deposit' : 'Withdraw',
        tx_data: {
          symbol: selectedSymbol,
          amount: new BigNumber(checkingBalance).toString(),
        },
      });
    }
    setIsOpen(false);
  }, [checkingBalance, checkingType, selectedSymbol,
    reduxAccountAddress, reduxCurrentNetworkId, reduxCurrentNetwork]);

  return (
      <div className='checking-modal'>
          <ModalHeader title={'Deposit/Withdraw'} />
          <ModalContent>
            <div className={'modal-content-scroll-area'}>
              <div id={'select-checking-tab'}>
                {
                  checkingTypeArray.map((el) => (
                      <label key={el.title}>
                        <input type={'radio'}
                               name='checking'
                               value={el.type}
                               checked={checkingType === el.type}
                               onChange={() => setCheckingType(el.type)}
                        />
                        <span>{el.title}</span>
                      </label>
                  ))
                }
              </div>
              <div className={'notice'}>
                {
                  checkingType === CHECKING_DEPOSIT
                      ? 'Deposited balance would be available with registering orders'
                      : 'Withdrawn balance would be available with wallet'
                }
              </div>
              <div style={{ width: '100%' }}>
                <Select
                    styles={{
                      control: (styles, {isFocused}) => {
                        return ({...styles, backgroundColor: '#222', borderColor: '#363636', '&:hover': {borderColor: MAIN_DOWN_COLOR},
                          boxShadow: `0 0 0 1px ${MAIN_DOWN_COLOR}`
                        });
                      },
                      singleValue: (styles) => ({...styles, color: '#e5e5e5'}),
                      option: (styles, { isDisabled, isFocused, isSelected}) => {
                        return({
                          ...styles,
                          color: '#e5e5e5',
                          backgroundColor: isDisabled ? undefined : isSelected ? MAIN_DOWN_COLOR : isFocused ? MAIN_DOWN_COLOR : undefined,
                          ':active': {...styles[':active'], backgroundColor: undefined}
                        })
                      },
                      menuList: (styles) => ({...styles, backgroundColor: '#222'})
                    }}
                    value={selectedOption}
                    options={symbolOptions}
                    onChange={handleChange}
                    formatOptionLabel={(token) => (
                        <div className={'flex flex-vertical-align'}>
                          <img className={'token-img'} src={token.image} alt="token-image" />
                          <span style={{ marginLeft: 5 }}>{token.label}</span>
                        </div>
                    )}
                />
              </div>
              <div style={{ width: '100%' }}>
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                  <tr>
                    <td width={'40%'} style={{ fontWeight: '500' }}>{checkingTypeArray[checkingType].title} Balance</td>
                    <td width={'60%'}>
                      <div className={'flex'} style={{ alignItems: 'center' }}>
                        <CurrencyInput className={'currency-input'}
                                       allowNegativeValue={false}
                                       disableAbbreviations
                                       decimalsLimit={8}
                                       value={checkingBalance}
                                       onValueChange={(value) => setCheckingBalance(value)}
                        />
                        <span className={'symbol'}>{selectedSymbol}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width={'40%'} />
                    <td width={'60%'} style={{ fontSize: 12 }}>
                      Transaction Fee : {formatDigits(fee, MAX_DIGITS, 0)}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ width: '100%' }}>
                <table cellPadding={0} cellSpacing={0}>
                  <thead>
                  <tr>
                    <td colSpan={2}>Before</td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    checkingTableInfo.map((el) => (
                        <tr key={el.title}>
                          <td width={'40%'}>{el.title}</td>
                          <td width={'60%'} >{el.balance}<span className={'symbol'}>{selectedSymbol}</span></td>
                        </tr>
                    ))
                  }
                  </tbody>
                </table>
                <div style={{
                  height: 1, backgroundColor: '#363636', marginTop: 5, marginBottom: 5,
                }} />
                <table cellPadding={0} cellSpacing={0}>
                  <thead>
                  <tr>
                    <td colSpan={2}>After</td>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    checkingResultTableInfo.map((el) => (
                        <tr key={el.title}>
                          <td width={'40%'}>{el.title}</td>
                          <td width={'60%'} >{el.balance}<span className={'symbol'}>{selectedSymbol}</span></td>
                        </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              <button style={{ width: '100%' }} className={'checking-button'} disabled={btnDisabledCondition} onClick={onClickChecking}>
                {checkingTypeArray[checkingType].title}
              </button>
            </div>
          </ModalContent>
      </div>
  );
}

export default ModalChecking;
