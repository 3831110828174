import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getAccountBalance, getAccountWalletBalance } from './accountThunk';

// Adapter 영역 - 선언부
const accountAssetAdapter = createEntityAdapter({
  selectId: (asset) => asset.unit,
});

const accountWalletBalanceAdapter = createEntityAdapter({
  selectId: (asset) => asset.unit,
});

const initialState = {
  address: '',
  wallet: { ...accountWalletBalanceAdapter.getInitialState(), status: 'idle', error: null },
  dex: { ...accountAssetAdapter.getInitialState(), status: 'idle', error: null },
};

export const {
  selectAll: selectAllAccountAssetsInDex,
  selectIds: selectAccountAssetsIdsInDex,
  selectById: selectAccountAssetByIdInDex,
} = accountAssetAdapter.getSelectors((state) => state.account.dex);

export const {
  selectAll: selectAllAccountAssetsInWallet,
  selectById: selectAccountAssetByIdInWallet,
} = accountAssetAdapter.getSelectors((state) => state.account.wallet);

// Slice 영역 - 선언부
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountAddress(state, action) {
      state.address = action.payload;
    },
    clearAccountAssets(state) {
      accountAssetAdapter.removeAll(state.dex);
      accountWalletBalanceAdapter.removeAll(state.wallet);
    },
  },
  extraReducers: {
    [getAccountWalletBalance.pending]: (state) => {
      if (state.wallet.status === 'idle') {
        state.wallet.status = 'pending';
      }
      state.wallet.error = null;
    },
    [getAccountWalletBalance.rejected]: (state, action) => {
      state.wallet.status = 'rejected';
      if (action.payload) {
        state.wallet.error = action.payload.message;
      } else {
        state.wallet.error = action.error;
      }
    },
    [getAccountWalletBalance.fulfilled]: (state, action) => {
      accountWalletBalanceAdapter.upsertMany(state.wallet, action.payload);
      state.wallet.status = 'fulfilled';
    },
    [getAccountBalance.pending]: (state) => {
      if (state.dex.status === 'idle') {
        state.dex.status = 'pending';
      }
      state.dex.error = null;
    },
    [getAccountBalance.rejected]: (state, action) => {
      state.dex.status = 'rejected';
      if (action.payload) {
        state.dex.error = action.payload.message;
      } else {
        state.dex.error = action.error;
      }
    },
    [getAccountBalance.fulfilled]: (state, action) => {
      accountAssetAdapter.upsertMany(state.dex, action.payload);
      state.dex.status = 'fulfilled';
    },
  },
});

// Action 영역
export const { setAccountAddress, clearAccountAssets, setAccountUsedAmount } = accountSlice.actions;

// Store 데이터 영역
export const accountAddress = (RootState) => RootState.account.address;
export const accountWalletStatus = (RootState) => RootState.account.wallet.status;
export const accountDexStatus = (RootState) => RootState.account.dex.status;

// Reducer 영역
export default accountSlice.reducer;
