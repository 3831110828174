import React from "react";
import "./Home.scss";
import HomeSection1 from "../home/component/HomeSection1";
import HomeSection2 from "../home/component/HomeSection2";
import HomeSection3 from "../home/component/HomeSection3";
// import HomeSection4 from './home/component/HomeSection4';
import HomeSection5 from "../home/component/HomeSection5";
import Banner from "./component/Banner";

function Home(props) {
  return (
    <div id={"Home"}>
      <Banner />
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      {/* <HomeSection4 /> */}
      <HomeSection5 />
    </div>
  );
}

export default Home;
