import KEY from './Key';

export const eqbrTransaction = async (
    network_id,
    chain_network_id,
    chain_id,
    address,
    txNonce,
    contract,
    functionName,
    tx_data,
    priKey,
) => {
  let tx = {
    network_id: chain_network_id,
    chain_id: Number(chain_id).toString(),
    from: address,
    nonce: txNonce,
    contract: contract,
    function: functionName,
    data: tx_data,
  };

  const txHash = await KEY.MakeThash(tx);

  return {
    networkId: Number(network_id).toString(),
    transaction: tx,
    txHash,
    pubKey: KEY.MakePublicKey(priKey),
    signature: await KEY.MakeSignature(
        txHash,
        priKey,
        KEY.MakePublicKey(priKey),
    ),
  };
};
