import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {user_access, user_info} from "../../../store/user/userSlice";
import {oauthCallback} from "store/user/userThunk";

function RedirectOAuth(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const userAccess = useSelector(user_access);
    const params = new URLSearchParams(location.search);

    const oauthCallback_ = async () => {
        try {
            await dispatch(oauthCallback({
                code: params.get("code"),
            }));
            window.close();
        } catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        if (!Boolean(userAccess)) {
            //볼라레 서버로 code 전송
            oauthCallback_();
        }
    }, [location, userAccess]);

    return <div className={"RedirectOAuth"}></div>;
}

export default RedirectOAuth;
