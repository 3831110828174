import { BigNumber } from 'bignumber.js';
import { MAX_DIGITS } from 'view/router/exchange/util/literal';

const _ = require('lodash');

const abbreviation = [
  'K',
  'M',
  'B',
  't',
  'q',
  'Q',
  's',
  'S',
  'o',
  'n',
  'd',
  'U',
  'D',
  'T',
  'Qt',
  'Qd',
  'Sd',
  'St',
  'O',
  'N',
  'v',
  'c',
];

export const formatDigits = (num, max = 2, min = 0) => {
  if (typeof num === 'string' && Number.isNaN(num * 1)) return num;
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(num * 1);
};

export const contractNum = (num, mode = 'float') => {
  let result;
  switch (mode) {
    case 'float':
      result = parseFloat(num).toFixed(2);
      break;

    case 'int':
      result = parseInt(num, 10);
      break;
    default:
  }
  let deleteNum = -1;
  while (result >= 1000) {
    result = parseInt(result / 10, 10);
    deleteNum += 1;
  }
  if (deleteNum % 3 === 0) {
    return (result / 100).toFixed(1) + abbreviation[parseInt(deleteNum / 3, 10)];
  }
  if (deleteNum > -1) {
    return result / 10 ** (2 - (deleteNum % 3)) + abbreviation[parseInt(deleteNum / 3, 10)];
  }
  return result;
};

export const unitInspection = (num) => {
  // const number = commaNum(num, 2, 2);
  if (num >= 10000) {
    return `${formatDigits(num / 1000000)}M`;
  }
  return `${formatDigits(num, MAX_DIGITS, 2)}`;
};

export const getDecimals = (price) => {
  if (price.toString().includes('.')) {
    const target = _.head(_.takeRight(price.toString().split('.')));
    return target.length;
  }
  return 0;
}

export const dollar = (num) => {
  if (typeof num === 'string' && Number.isNaN(num * 1)) return num;
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num * 1);
};

export const rateWithSign = (num) => {
  if (typeof num === 'string' && Number.isNaN(num * 1)) return num;
  return `${num * 1 > 0 ? '+' : ''}${Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num * 1)}%`;
};

export const tmpPriceDigits = (price) => {
  if (typeof price === 'number') {
    if (new BigNumber(price).isGreaterThanOrEqualTo(1000)) {
      return new BigNumber(price).toFixed(2, 1);
    } if (price >= 0.001) {
      return new BigNumber(price).toPrecision(6, 1);
    }
    return new BigNumber(price).toFixed(MAX_DIGITS, 1);
  }
  return price;
};

export const getPriceMaxDigits = (price) => {
  if (Number(price) === 0) {
    return 8;
  }
  if (price.toString().includes('.')) {
    const target = _.head(_.takeRight(price.toString().split('.')));
    if (target.length > 8) {
      return 8;
    } if (target.length < 2) {
      return 2;
    }
    return target.length;
  }
  return 2;
};

export const getAmountMaxDigits = (price) => {
  const digit = Math.floor(Math.log(price) / Math.log(10));
  /* 전략 기획팀 요청 :: minimum Amount 1000 ~ 0.0001 사이 */
  if (digit <= -6) return -3;
  if (digit >= 2) return 4;
  return digit + 3;
};
