import React, { createContext, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./view/style/index.scss";
import "./view/style/reset.scss";
import ScrollToTop from "./view/hooks/ScrollToTop";
import AllModals from "./view/common/modal/AllModals";
import RootRouter from "./view/router/RootRouter";
import useScrollbarWidth from "./view/hooks/useScrollbarWidth";
import RouteChangeTracker from "view/router/RouteChangeTracker";

const App = function () {
  useScrollbarWidth();

  return (
    <div id={"app"}>
      <BrowserRouter>
        <ScrollToTop />
        <RouterWithWindowName />
      </BrowserRouter>
    </div>
  );
};

export const ModalContext = createContext({
  id: 0,
  isOpen: false,
  activeModal: "",
  params: {},
  result: {},
  onModalClose: () => {},
  openModal: () => {},
});

const RouterWithWindowName = function () {
  const id = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [params, setParams] = useState({});
  const [result, setResult] = useState({});
  const onModalClose = () => {
    setIsOpen(false);
  };
  const openModal = (modalName, params) => {
    setIsOpen(true);
    setActiveModal(modalName);
    setParams(params);
    id.current++;
    return id.current;
  };

  RouteChangeTracker();

  return (
    <div className="container">
      <ModalContext.Provider
        value={{ isOpen, setIsOpen, id: id.current, activeModal, setActiveModal, params, setParams, result, setResult, onModalClose, openModal }}
      >
        <AllModals />
        <RootRouter />
      </ModalContext.Provider>
    </div>
  );
};

export default App;
