import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import './SignatureModal.scss';
import keythereum from 'keythereum';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { eqbrTransaction  } from 'modules/transaction';
import { getNonceByAddress } from 'store/exchange/account/accountThunk';
import { registerDeposit, registerWithdraw } from 'store/exchange/checking/checkingThunk';
import { cancelOrder, registerOrder } from 'store/exchange/order/orderThunk';
import { eqbrSignature } from 'modules/signature';
import Button from 'view/common/button/Button';
import Input from 'view/common/input/Input';
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";
import {ModalContext} from "../../../App";

function ModalSignature({close}) {

  const {params} = useContext(ModalContext);
  const {signatureInfo} = params || {};
  const {
    address,
    network_id: networkId,
    chain_network_id: chainNetworkId,
    chain_id: chainId,
    micro_chain_id: microChainId,
    contract_address: contractAddress,
    function_name: functionName,
    tx_data: txData,
    data_need_signature: needSignature,
  } = signatureInfo || {};

  const ellipsisAddress = useMemo(
    () => `${(address ?? '').slice(0, 5)}...${(address ?? '').slice(-4)}`,
    [address],
  );

  const [keystorePassword, setKeystorePassword] = useState('');

  const [keystoreInfo, setKeystoreInfo] = useState(null);
  const [transactionInfo, setTransactionInfo] = useState(null);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setKeystoreInfo(sessionStorage.getItem('keystore'));
  }, []);

  const confirmDisabled = useMemo(() => {
    if (isValid) {
      return false;
    }
    return !keystoreInfo || keystorePassword.length === 0;
  }, [isValid, keystorePassword, keystoreInfo]);

  const dispatch = useDispatch();

  const getNonceFromServer = useCallback(async () => unwrapResult(
    await dispatch(
      getNonceByAddress({
        address,
        chainNetworkId,
        chainId,
        microChainId,
      }),
    ),
  ), [signatureInfo]);

  const checkValidPassword = useCallback(async () => {
    try {
      if (keystoreInfo) {
        const decryptedTarget = JSON.parse(keystoreInfo);
        const recoveredPrivateKey = keythereum.recover(keystorePassword, decryptedTarget);
        const hexPrivateKey = Buffer.from(recoveredPrivateKey).toString('hex');
        if (hexPrivateKey) {
          const nonce = await getNonceFromServer();
          const signature = await eqbrSignature(txData, hexPrivateKey);
          const transaction = await eqbrTransaction(
            networkId,
            chainNetworkId,
            chainId,
            address,
            nonce,
            contractAddress,
            functionName,
            needSignature ? { ...txData, ...signature } : txData,
            hexPrivateKey,
          );
          setTransactionInfo(transaction);
          setIsValid(true);
        } else {
          throw new Error({
            message: 'invalid key',
          });
        }
      } else {
        window.alert('there is no valid keystore file');
      }
    } catch (e) {
      console.error(e);
      window.alert(e.message);
    }
  }, [keystoreInfo, keystorePassword]);

  const sendTransactionToServer = useCallback(async (payload) => {
    switch (functionName) {
      case 'Deposit':
        return unwrapResult(await dispatch(registerDeposit(payload)));
      case 'Withdraw':
        return unwrapResult(await dispatch(registerWithdraw(payload)));
      case 'PlaceOrder':
        return unwrapResult(await dispatch(registerOrder(payload)));
      case 'CancelOrder':
        return unwrapResult(await dispatch(cancelOrder(payload)));
      default:
        return console.warn('unknown function');
    }
  }, [functionName]);

  const onClickConfirm = useCallback(async () => {
    try {
      if (isValid) {
        const { txHash, networkId, ...rest } = transactionInfo || {};
        await sendTransactionToServer({
          ...rest,
          microChainId,
          chainNetworkId,
          chainId,
        });
        close();
      } else {
        await checkValidPassword();
      }
    } catch (e) {
      toast(e?.message ?? e, { icon: '❌' });
    }
  }, [isValid, checkValidPassword, transactionInfo, signatureInfo]);

  const onClickCancel = useCallback(async () => {
    if (isValid) {
      setKeystorePassword('');
      setIsValid(false);
      setTransactionInfo(null);
    } else {
      close();
    }
  }, [isValid]);

  return (
    <div className={'signature-modal'}>
      <ModalHeader title={'Signature Request'}/>
      <ModalContent>
        <div className='modal-content-scroll-area'>
          <div className='notice'>
          <p>
            {'This website is asking you to sign for the following\nitems: Before signing, make sure this website is trusted.'}
          </p>
            <div>account to sign : {ellipsisAddress}</div>
            <div style={{marginTop: 4}}>
              {'request site : '}
              <strong>
                {window.location.origin}
              </strong>
            </div>
          </div>
          <div className={'signature-modal-info-box'} style={{ marginTop: 18, marginBottom: 14 }}>
            {
              isValid
                  ? <div>
                    Message
                    <div>
                      tx_hash: {transactionInfo?.txHash ?? ''}
                    </div>
                    <div>
                      pub_key: {transactionInfo?.pubKey ?? ''}
                    </div>
                    <div>
                      signature: {transactionInfo?.signature ?? ''}
                    </div>
                  </div>
                  : <div>
                    <div>Keystore</div>
                    <div>{keystoreInfo ?? 'No Keystore Info'}</div>
                  </div>
            }
          </div>
          {
              !isValid
              && <Input
                  style={{ marginBottom: 31 }}
                  type={'password'}
                  placeholder={'Enter Keystore File Password'}
                  value={keystorePassword}
                  onChange={(e) => setKeystorePassword(e.target.value)}
              />
          }
          <div style={{ width: '100%' }} className={'flex justify-content-flex-end'}>
            <div className='cancel-wrapper'>
              <Button className={'default-button cancel'} style={{ marginRight: 7 }} onClick={onClickCancel}>
                Cancel
              </Button>
            </div>
            <div className='confirm-wrapper'>
              <Button className={'default-button red'} disabled={confirmDisabled} onClick={onClickConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </div>
  );
}

export default ModalSignature;
