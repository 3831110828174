import React, {useContext} from 'react';
import "./modal.scss";
import './ModalInvalidDevice.scss';
import Button from "../button/Button";
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";
import {ModalContext} from "../../../App";

function ModalInvalidDevice(props) {

    const {params, onModalClose} = useContext(ModalContext);

    const onClickConfirm = () => {
        onModalClose();
    }

    return (
        <div className={'modal-invalid-device'}>
            <ModalHeader title={'Notice'}/>
            <ModalContent>
                <div className={'content-text'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom:'20px'}}>
                    'Connect Wallet' is not available on mobile devices.
                </div>
                <div className={'content-text'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom:'20px'}}>
                    Please connect with laptop or desktop.
                </div>
                <Button className={'gradient-button'}
                        style={{display: 'flex', margin: '18px auto 0'}}
                        onClick={onClickConfirm}
                >OK</Button>
            </ModalContent>
        </div>
    );
}

export default ModalInvalidDevice;


