import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';

export const getNetworks = createAsyncThunk('networks/getNetworks', async (payload, { getState, rejectWithValue }) => {
  try {
    let result;
    if (isSocketConnected(getState)) {
      result = await getEmittedResult('dexGetNetworks', {});
    } else {
      result = await getApiResult('networks', 'get');
    }
    const { networks } = result || {};
    if (networks !== undefined) {
      return networks;
    }
    return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
  } catch (e) {
    throw errorCatcher(e);
  }
});
