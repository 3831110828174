import React, { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { user_info } from "../../store/user/userSlice";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import Header2 from "../common/header/Header2";
import { useMediaQuery } from "react-responsive";
import Header3 from "view/common/header/Header3";

function Layout(props) {
  const { pathname } = useLocation();

  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const isShowHeader = useMemo(() => {
    return pathname !== '/redirect'
  }, [pathname]);

  const isShowFooter = useMemo(() => {
    return pathname !== '/redirect'
  }, [pathname]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative" }}>
      {isShowHeader && <Header3 />}
      <Outlet />
      {isShowFooter && <Footer />}
    </div>
  );
}

export default Layout;
