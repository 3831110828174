import createError from 'http-errors';

export const errorCatcher = (e) => {
  if (e.response) {
    return (createError(e.response.status, e.response.data.message));
  } if (e.request) {
    console.log(e.request);
    return e;
  }
  return e;
};
