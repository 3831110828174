import React from 'react';
import ModalInput from "./ModalInput";
import "./ModalInputWithUnit.scss"

function ModalInputWithUnit(props) {

    const {unit, ...rest} = props || {};

    return (
        <div className={'modal-input-with-unit'} style={{position: 'relative'}}>
            <ModalInput {...rest} isUnit={true}/>
            <p className='modal-input-with-unit-unit'>{unit}</p>
        </div>
    );
}

export default ModalInputWithUnit;