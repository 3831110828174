import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Banner.scss";
import bannerBackgroundImg from "../../../assets/images/new_banner_background_img.png";
import leftContentImg from "../../../assets/images/banner_left_content_img.png";
import rightContentImg from "../../../assets/images/banner_right_content_img.png";
import btn from "../../../assets/images/banner_btn.png";
import btnHover from "../../../assets/images/banner_btn_hover.png";

const Banner = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isNotPortable = useMediaQuery({ query: "(min-width: 992px)" });
  const [leftBtnHover, setLeftBtnHover] = useState(false);
  const [rightBtnHover, setRightBtnHover] = useState(false);
  const probitLink = () => {
    window.open("https://www.probit.com/app/exchange/VOLR-USDT");
  };
  const bitMartLink = () => {
    window.open("https://www.bitmart.com/trade/en?symbol=VOLR_USDT&layout=basic&theme=dark");
  };

  return (
    <section className="banner-container">
      <div className="banner-section">
        <div className="banner-section-content">
          <div className="banner-section-content-background">
            <img src={bannerBackgroundImg} alt="" className="banner-section-content-background-src" />
          </div>
          <div className="banner-section-content-container">
            <div className="banner-section-content-wrapper">
              <div className="banner-section-content-box" onClick={isNotPortable ? probitLink : null}>
                <img src={leftContentImg} alt="" className="banner-section-content-left-img" />
                <div
                  className="banner-section-content-btn"
                  onClick={isNotPortable ? null : probitLink}
                  onMouseOver={() => setLeftBtnHover(!leftBtnHover)}
                  onMouseOut={() => setLeftBtnHover(!leftBtnHover)}
                >
                  Buy Volare in ProBit
                  {leftBtnHover ? (
                    <img src={btnHover} alt="" className="banner-section-content-btn-img" />
                  ) : (
                    <img src={btn} alt="" className="banner-section-content-btn-img" />
                  )}
                </div>
                {/* {isMobile ? <div className="banner-section-content-text">Listing 2022-10-31 UTC</div> : <div />} */}
              </div>
              <div className="banner-section-content-line" />
              <div className="banner-section-content-box" onClick={isNotPortable ? bitMartLink : null}>
                <img src={rightContentImg} alt="" className="banner-section-content-right-img" />
                <div
                  className="banner-section-content-btn"
                  onClick={isNotPortable ? null : bitMartLink}
                  onMouseOver={() => setRightBtnHover(!rightBtnHover)}
                  onMouseOut={() => setRightBtnHover(!rightBtnHover)}
                >
                  Buy Volare in BitMart
                  {rightBtnHover ? (
                    <img src={btnHover} alt="" className="banner-section-content-btn-img" />
                  ) : (
                    <img src={btn} alt="" className="banner-section-content-btn-img" />
                  )}
                </div>
                {/* {isMobile ? <div className="banner-section-content-text">Listing 2022-10-31 UTC</div> : <div />} */}
              </div>
            </div>
            {/* {isMobile ? <div /> : <div className="banner-section-content-text">Listing 2022-10-31 UTC</div>} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
