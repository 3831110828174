import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getMarkets } from './marketsThunk';

const marketsAdapter = createEntityAdapter({
  selectId: (market) => market.id,
});

export const {
  selectAll: selectAllMarkets,
  selectIds: selectMarketsIds,
  selectById: selectMarketById,
  selectTotal: selectMarketsTotal,
} = marketsAdapter.getSelectors((state) => state.markets);

const marketsSlice = createSlice({
  name: 'markets',
  initialState: marketsAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    clearMarket: marketsAdapter.removeAll,
  },
  extraReducers: {
    [getMarkets.pending]: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    [getMarkets.rejected]: (state, action) => {
      if (state.status === 'pending') {
        state.status = 'rejected';
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      }
    },
    [getMarkets.fulfilled]: (state, action) => {
      if (state.status === 'pending') {
        marketsAdapter.setMany(state, action.payload.map((el) => ({
          ...el,
          price: 0,
          withdraw: 0,
          deposit: 0,
          match: 0,
          cancel: 0,
        })));
        state.status = 'fulfilled';
      }
    },
  },
});

// Action 영역
export const { clearMarket } = marketsSlice.actions;

// Store 영역
export const marketStatus = (RootState) => RootState.markets.status;

export default marketsSlice.reducer;
