import React from 'react';
import './InlineContent.scss'

function InlineContent(props) {

    const { id, backgroundClassName, innerClassName, children } = props || {};

    return (
        <div id={id} className={`inline-content-wrapper${backgroundClassName !== undefined ? ` ${backgroundClassName}` : ''}`}>
            <div className={`inline-content${innerClassName !== undefined ? ` ${innerClassName}` : ''}`}>
                {children}
            </div>
        </div>
    );
}

export default InlineContent;