import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const registerDeposit = createAsyncThunk(
  'checking/registerDeposit',
  async (query, { getState, rejectWithValue }) => {
    let result;
    try {
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexDeposit', query);
      } else {
        const {
          microChainId, chainNetworkId, chainId, ...rest
        } = query || {};
        const urlQuery = makeObjToUrlQuery({ microChainId, chainNetworkId, chainId });
        result = await getApiResult(`user/deposit${urlQuery}`, 'post', rest);
      }
      const { success, message } = result;
      if (success) return success;
      return invalidServerResponseHandler(message, [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const registerWithdraw = createAsyncThunk(
  'checking/withdrawDeposit',
  async (query, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexWithdraw', query);
      } else {
        const {
          microChainId, chainNetworkId, chainId, ...rest
        } = query || {};
        const urlQuery = makeObjToUrlQuery({ microChainId, chainNetworkId, chainId });
        result = await getApiResult(`user/withdraw${urlQuery}`, 'post', rest);
      }
      const { success, message } = result;
      if (success) return success;
      return invalidServerResponseHandler(message, [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
