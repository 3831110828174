import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getDepositFee = createAsyncThunk(
  'fee/getDepositFee',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetDepositFee', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`fee/deposit${urlQuery}`, 'get');
      }
      const { success, data, msg } = result || {};
      if (success && data !== undefined) return data;
      return invalidServerResponseHandler(msg ?? 'Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getWithdrawFee = createAsyncThunk(
  'fee/getWithdrawFee',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetWithdrawFee', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`fee/withdraw${urlQuery}`, 'get');
      }
      const { success, data, msg } = result || {};
      if (success && data !== undefined) return data;
      return invalidServerResponseHandler(msg ?? 'Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getCancelOrderFee = createAsyncThunk(
  'fee/getCancelOrderFee',
  // eslint-disable-next-line consistent-return
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetCancelOrderFee', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`fee/cancel-order${urlQuery}`, 'get');
      }
      const { success, data, msg } = result || {};
      if (success && data !== undefined) return data;
      invalidServerResponseHandler(msg ?? 'Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getMatchedOrderFee = createAsyncThunk(
  'fee/getMatchedOrderFee',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetMatchedOrderFee', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`fee/matched-order${urlQuery}`, 'get');
      }
      const { success, data, msg } = result || {};
      if (success && data !== undefined) return data;
      return invalidServerResponseHandler(msg ?? 'Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  }

  ,
);
