import axios from 'axios';
import { getSocket } from 'middleware/socketManager';
import createError from 'http-errors';

export const withTimeout = (onSuccess, onTimeout, timeout) => {
  let called = false;

  const timer = setTimeout(() => {
    if (called) {
      return;
    }
    called = true;
    onTimeout();
  }, timeout);

  return (...args) => {
    if (called) {
      return;
    }
    called = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  };
};

export const getEmittedResult = (channel, requestData) => new Promise((resolve, reject) => {
  const socket = getSocket();
  if (socket) {
    socket.emit(
      channel,
      requestData,
      withTimeout(
        (data) => {
          const { error } = data || {};
          if (error) {
            const httpError = createError(error.code, error.message);
            reject(httpError);
            return;
          }
          resolve(data); // success
        },
        (err) => {
          const error = createError(504, 'socket timeout');
          reject(error); // timeout
        },
        5000,
      ),
    );
  }
});
export const getApiResult = (channel, type, requestData) => new Promise((resolve, reject) => {
  axios[type](`${process.env.REACT_APP_EXCHANGE_SERVER_URL}${process.env.REACT_APP_API_VERSION}dex/${channel}`, requestData)
    .then((res) => {
      const { status } = res || {};
      if (status === 200) {
        resolve(res.data);
      } else {
        reject(status);
      }
    })
    .catch((err) => {
      reject(err);
    });
});

export const isSocketConnected = (getState) => {
  const state = getState();
  switch (state.socket.status) {
    case 'OPENED':
      return true;
    default:
      return false;
  }
};
