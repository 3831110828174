import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ModalContext} from "../../../App";

export const sendSignatureRequest = (payload) => {
  window.postMessage({
    source: 'SignatureRequest',
    payload,
  }, window.origin);
};

function SignatureListener() {

  const {
    id,
    result,
    openModal
  } = useContext(ModalContext);
  const modalId = useRef(-1);

  const openModalKYCVerification = (payload) => {
    const newId = openModal('Signature', {
      signatureInfo: {...payload},
    });
    modalId.current = newId
  }

  const messageEventHandler = useCallback(async (event) => {
    const { data } = event || {};
    const { source, payload } = data || {};
    if (source === 'SignatureRequest') {
      openModalKYCVerification(payload);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, []);

  return (
      <Fragment key='signature-listener' />
  );
}

export default SignatureListener;
