import React, {
  Fragment,
  useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import './Trade.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { unwrapResult } from '@reduxjs/toolkit';
import toast, {Toaster} from 'react-hot-toast';
import { getSocket } from 'middleware/socketManager';
import SignatureListener from 'view/common/listener/SignatureListener';
import ExtensionListener from 'view/common/listener/ExtensionListener';
import ErrorFallback from 'view/common/ui/ErrorFallback';
import LoadingComponent from 'view/common/Loading';
import useSocket from 'view/hooks/useSocket';
import {
  pairStatus, selectPairById, selectPairsIds, selectPairsTotal, updatePairs,
} from 'store/exchange/pairs/pairsSlice';
import {
  currentNetworkId,
  networkStatus,
  selectNetworkById,
  setCurrentNetworkId,
  updateNetwork
} from 'store/exchange/network/networksSlice';
import { getCurrentPrice, getPairs } from 'store/exchange/pairs/pairsThunk';
import { marketStatus, selectMarketsIds, selectMarketsTotal } from 'store/exchange/markets/marketsSlice';
import { currencyStatus, updateCurrenciesFees } from 'store/exchange/currencies/currenciesSlice';
import { getMarkets } from 'store/exchange/markets/marketsThunk';
import { getCurrencies } from 'store/exchange/currencies/currenciesThunk';
import { socketStatus } from 'store/exchange/socket/socketSlice';
import {
  accountAddress, accountDexStatus,
  accountWalletStatus,
  clearAccountAssets,
  setAccountAddress,
} from 'store/exchange/account/accountSlice';
import { getAccountBalance, getAccountWalletBalance } from 'store/exchange/account/accountThunk';
import { getFavoritePairs } from 'store/exchange/favorites/favoritesThunk';
import {
  getDepositFee, getMatchedOrderFee, getWithdrawFee,
} from 'store/exchange/fee/feeThunk';
import { favoriteStatus } from 'store/exchange/favorites/favoritesSlice';
import { feeStatus } from 'store/exchange/fee/feeSlice';
import MobileLayout from './component/MobileLayout';
import GridLayout from './component/GridLayout';
import SocketPairRoom from './component/SocketPairRoom';
import SocketPairAddressRoom from './component/SocketPairAddressRoom';
import { makeOrderBookFormat } from './util/makeOrderBookFormat';
import ParamsValidation from './component/ParamsValidation';
import TradePageTitle from './component/TradePageTitle';
import { TradeContext } from './context/TradeContext';
import CallsForSelectedPair from './component/CallsForSelectedPair';
import _ from "lodash";
import {getNetworks} from "store/exchange/network/networkThunk";
import {BigNumber} from "bignumber.js";
import {setUserInfo} from "store/user/userSlice";
import useScrollbarWidth from "view/hooks/useScrollbarWidth";

BigNumber.config({ ROUNDING_MODE: 1, EXPONENTIAL_AT: 1e9 });

function Trade() {
  /* --- redux, context, props, hook 등으로 받은 전역값 --- */

  const reduxMarketStatus = useSelector(marketStatus);
  const reduxPairStatus = useSelector(pairStatus);
  const reduxCurrencyStatus = useSelector(currencyStatus);
  const reduxAccountWalletStatus = useSelector(accountWalletStatus);
  const reduxAccountDexStatus = useSelector(accountDexStatus);
  const reduxFeeStatus = useSelector(feeStatus);
  const reduxFavoriteStatus = useSelector(favoriteStatus);

  const { pairId: currentPairId } = useParams() || {};

  const selectedPair = useSelector((state) => selectPairById(state, currentPairId)) || {};

  /* -------------------------------------------------------------------------------------------- */

  useEffect(() => {
    // console.log('App::serverURL:', process.env.REACT_APP_EXCHANGE_SERVER_URL);
  }, []);

  // 모바일 Safari offset height 가 100%가 되지 않는 오류를 해결하기 위해 style 에 설정하는 code
  const appHeight = () => {
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  // Modal 열렸을 때 스크롤바만큼 움직이지 않도록 해당 width 를 style 에 설정하는 code
  const { scrollbarWidth } = useScrollbarWidth();

  useEffect(() => {
    window.addEventListener('resize', appHeight);
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  const reduxNetworkStatus = useSelector(networkStatus);
  /* --- trade --- */
  const [feePromiseStatus, setFeePromiseStatus] = useState('pending');
  const [cancelFeePromiseStatus, setCancelFeePromiseStatus] = useState('pending');
  const isLoading = [reduxMarketStatus, reduxPairStatus, reduxFeeStatus, reduxFavoriteStatus,
    reduxCurrencyStatus, reduxAccountDexStatus, reduxAccountWalletStatus, feePromiseStatus,
    cancelFeePromiseStatus, reduxNetworkStatus]
    .some((el) => el === 'pending');

  const [orderBook, setOrderBook] = useState(makeOrderBookFormat({
    sell: [],
    buy: [],
  }));
  const [pairMatchedOrder, setPairMatchedOrder] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [activeOrder, setActiveOrder] = useState([]);
  const [price, setPrice] = useState(0);

  const [tradable, setTradable] = useState(true);
  /* -------------------------------------------------------------------------------------------- */

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setSelectedTab(0);
    return () => {
      setOrderBook(makeOrderBookFormat({
        sell: [],
        buy: [],
      }));
      setActiveOrder([]);
      setTradeHistory([]);
      setPairMatchedOrder([]);
      setPrice(0);
      setTradable(true);
    };
  }, [currentPairId]);

  const pairValue = useMemo(() => ({
    pair: selectedPair,
    orderBook,
    setOrderBook,
    matchedOrder: pairMatchedOrder,
    setMatchedOrder: setPairMatchedOrder,
    activeOrder,
    setActiveOrder,
    tradeHistory,
    setTradeHistory,
    price,
    setPrice,
    selectedTab,
    setSelectedTab,
    feePromiseStatus,
    setFeePromiseStatus,
    tradable,
    setTradable,
  }), [
    selectedPair, orderBook, setOrderBook, activeOrder,
    setActiveOrder, pairMatchedOrder, setPairMatchedOrder,
    tradeHistory, setTradeHistory, price, setPrice,
    selectedTab, setSelectedTab, feePromiseStatus, setFeePromiseStatus,
    tradable, setTradable,
  ]);

  return (
    <ErrorBoundary FallbackComponent={(props) => <div className='page'><ErrorFallback {...props} /></div>}>
      <Toaster position="bottom-right" reverseOrder={false} toastOptions={{style: { background: '#363636', color: '#e5e5e5' }}} />
      <TradeContext.Provider value={pairValue}>
        <Network />
        <MicroChain />
        <Pair />
        <Account />
        <Socket />
        <CallsForSelectedPair
          status={cancelFeePromiseStatus}
          setStatus={setCancelFeePromiseStatus}
        />
        <ParamsValidation />
      {
        isLoading ?
            <div className='flex justify-content-center align-items-center'
                 style={{ width: '100%', height: '100vh'}}>
              <LoadingComponent />
            </div>
            :
            <article id="trade" className="page">
              <TradePageTitle />
              <SocketPairAddressRoom />
              <SocketPairRoom />
              <SignatureListener />
              <ExtensionListener />
              {isTabletOrMobile ? <MobileLayout /> : <GridLayout />}
            </article>
      }
      </TradeContext.Provider>
    </ErrorBoundary>
  );
}

function Network() {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const setNetworksToRedux = useCallback(async () => unwrapResult(
      await dispatch(
          getNetworks(),
      ),
  ), [dispatch]);

  useLayoutEffect(() => {
    setNetworksToRedux()
        .catch((e) => {
          handleError(e);
          throw e;
        });
  }, []);

  return <Fragment key={'network'} />;
}

function MicroChain() {
  // 여기에서 Market & Currencies 데이터 세팅 (micro-chain 하위)
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const { feePromiseStatus, setFeePromiseStatus } = useContext(TradeContext);

  const reduxCurrentNetworkId = useSelector(currentNetworkId);
  const reduxSelectedNetwork = useSelector(
    (state) => selectNetworkById(state, reduxCurrentNetworkId),
  );

  const microChainId = useMemo(() => reduxSelectedNetwork?.micro_chain_id, [reduxSelectedNetwork]);

  const setMarketsToRedux = useCallback(
    async () => unwrapResult(await dispatch(
      getMarkets({
        microChainId,
      }),
    )),
    [microChainId],
  );

  const setCurrenciesToRedux = useCallback(async () => {
    const currencies = unwrapResult(await dispatch(
      getCurrencies({
        microChainId,
      }),
    ));
    const depositFees = await Promise.all(
      currencies
        ?.map((el) => el.symbol)
        .map(async (symbol) => unwrapResult(await dispatch(
          getDepositFee({
            symbol, microChainId,
          }),
        ))),
    );
    const withdrawFees = await Promise.all(
      currencies
        ?.map((el) => el.symbol)
        .map(async (symbol) => unwrapResult(await dispatch(
          getWithdrawFee({
            symbol, microChainId,
          }),
        ))),
    );
    return currencies
      ?.map(
        (c, i) => ({ ...c, depositFee: depositFees[i], withdrawFee: withdrawFees[i] }),
      );
  }, [microChainId]);

  useLayoutEffect(() => {
    if (microChainId && feePromiseStatus === 'pending' && setFeePromiseStatus) {
      setMarketsToRedux()
        .catch((e) => {
          handleError(e);
          // console.error('[App]::setMarketsToRedux:', e);
          // setLoading(false);
        });
      setCurrenciesToRedux()
        .then((res) => {
          setFeePromiseStatus('fulfilled');
          dispatch(updateCurrenciesFees(res));
        })
        .catch((e) => {
          handleError(e);
          // console.error('[App]::setCurrenciesToRedux:', e);
          setFeePromiseStatus('failed');
        });
    }
  }, [microChainId, setFeePromiseStatus, feePromiseStatus]);

  return <Fragment key={'micro-chain'} />;
}

function Pair() {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const marketsIds = useSelector(selectMarketsIds);
  const marketTotal = useSelector(selectMarketsTotal);

  const pairsIds = useSelector(selectPairsIds);
  const pairsTotal = useSelector(selectPairsTotal);

  const reduxCurrentNetworkId = useSelector(currentNetworkId);

  const setPairsToRedux = useCallback(
    async () => unwrapResult(await dispatch(
      getPairs({
        marketIds: marketsIds,
      }),
    )),
    [marketsIds],
  );

  const setPairsCurrentPricesToRedux = useCallback(
    async () => unwrapResult(await dispatch(
      getCurrentPrice({
        pairIds: pairsIds,
      }),
    )),
    [pairsIds],
  );

  useLayoutEffect(() => {
    if (marketTotal > 0) {
      setPairsToRedux()
        .catch((e) => {
          handleError(e);
          // console.error('[App]::setPairsToRedux:', e);
        });
    }
  }, [marketTotal]);

  useLayoutEffect(() => {
    if (reduxCurrentNetworkId !== '' && pairsTotal > 0) {
      setPairsCurrentPricesToRedux().catch((e) => {
        handleError(e);
        // console.error('[App]::setPairsCurrentPricesToRedux:', e);
      });
    }
  }, [reduxCurrentNetworkId, pairsTotal]);

  return <Fragment key={'pair'} />;
}

function Socket() {
  const dispatch = useDispatch();

  const reduxSocketStatus = useSelector(socketStatus);
  const socket = getSocket();

  const reduxCurrentNetworkId = useSelector(currentNetworkId);
  const reduxSelectedNetwork = useSelector(
    (state) => selectNetworkById(state, reduxCurrentNetworkId),
  );
  const microChainId = useMemo(() => reduxSelectedNetwork?.micro_chain_id, [reduxSelectedNetwork]);

  const reduxAccountAddress = useSelector(accountAddress);

  const setAccountWalletBalanceToRedux = useCallback(async () => unwrapResult(await dispatch(
    getAccountWalletBalance({
      address: reduxAccountAddress,
      microChainId,
    }),
  )), [reduxAccountAddress, microChainId]);

  const setAccountBalanceToRedux = useCallback(async () => unwrapResult(await dispatch(
    getAccountBalance({
      address: reduxAccountAddress,
      microChainId,
    }),
  )), [reduxAccountAddress, microChainId]);

  const setMatchedOrderFeeToRedux = useCallback(async () => {
    const result = unwrapResult(
      await dispatch(
        getMatchedOrderFee({
          address: reduxAccountAddress,
          microChainId,
        }),
      ),
    );
    dispatch(updateNetwork({ id: reduxCurrentNetworkId * 1, matchedFee: result * 1 }));
    return result;
  }, [reduxAccountAddress, microChainId, reduxCurrentNetworkId]);

  useEffect(() => {
    if (microChainId && reduxSocketStatus === 'OPENED' && socket && socket.connected && reduxAccountAddress !== '') {
      // console.log('[App]::joinRoom', `dex_${microChainId}_${reduxAccountAddress}`);
      socket.emit('joinRoom', { roomName: `dex_${microChainId}_${reduxAccountAddress}` });
      socket.on('getCurrentMatchedOrderByAddress', async () => {
        try {
          await setAccountBalanceToRedux();
        } catch (e) {
          // console.error('[Trade::getCurrentMatchedOrderByAddress]', e);
          toast('something wrong during getting data... please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentRegisterOrderSuccessByAddress', async () => {
        try {
          await setAccountBalanceToRedux();
          await setMatchedOrderFeeToRedux();
        } catch (e) {
          // console.error('[Trade::getCurrentRegisterOrderSuccessByAddress]', e);
          toast('something wrong during getting data... please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentRegisterOrderFailByAddress', (data) => {
        if (data?.message) {
          toast(data.message, { icon: '❌' });
        } else {
          toast('Network is unstable. Please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentCancelOrderSuccessByAddress', async () => {
        try {
          await setAccountBalanceToRedux();
        } catch (e) {
          // console.error('[Trade::getCurrentCancelOrderSuccessByAddress]', e);
          toast('something wrong during getting data... please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentCancelOrderFailByAddress', (data) => {
        if (data?.message) {
          toast(data.message, { icon: '❌' });
        } else {
          toast('Network is unstable. Please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentDepositSuccessByAddress', async () => {
        try {
          toast('Deposit request is successfully filled', { icon: '👏' });
          await setAccountBalanceToRedux();
          await setAccountWalletBalanceToRedux();
        } catch (e) {
          // console.error('[Trade::getCurrentDepositSuccessByAddress]', e);
          toast('something wrong during getting data... please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentDepositFailByAddress', (data) => {
        if (data?.message) {
          toast(data.message, { icon: '❌' });
        } else {
          toast('Network is unstable. Please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentWithdrawSuccessByAddress', async () => {
        try {
          toast('Withdraw request is successfully filled', { icon: '👏' });
          await setAccountBalanceToRedux();
          await setAccountWalletBalanceToRedux();
        } catch (e) {
          // console.error('[Trade::getCurrentWithdrawSuccessByAddress]', e);
          toast('something wrong during getting data... please try again', { icon: '❌' });
        }
      });
      socket.on('getCurrentWithdrawFailByAddress', (data) => {
        if (data?.message) {
          toast(data.message, { icon: '❌' });
        } else {
          toast('Network is unstable. Please try again', { icon: '❌' });
        }
      });
    }
    return () => {
      if (socket && socket.connected) {
        socket.off('getCurrentMatchedOrderByAddress');
        socket.off('getCurrentRegisterOrderSuccessByAddress');
        socket.off('getCurrentRegisterOrderFailByAddress');
        socket.off('getCurrentCancelOrderSuccessByAddress');
        socket.off('getCurrentCancelOrderFailByAddress');
        socket.off('getCurrentDepositSuccessByAddress');
        socket.off('getCurrentDepositFailByAddress');
        socket.off('getCurrentWithdrawSuccessByAddress');
        socket.off('getCurrentWithdrawFailByAddress');
      }
    };
  }, [socket, microChainId, reduxAccountAddress, reduxSocketStatus]);

  useEffect(() => {
    if (reduxSocketStatus === 'OPENED' && socket && socket.connected && microChainId) {
      // console.log('[App]::joinRoom', `dex_${microChainId}`);
      socket.emit('joinRoom', {
        roomName: `dex_${microChainId}`,
      });
      socket.on('getPriceData', (res) => {
        // console.log('[socket]::getPriceData', res);
        const {
          current_price: currentPrice,
          pair_id: pairId,
          price_last_24h: priceLast24h,
        } = res || {};
        const {
          change: twentyFourChange,
          gap: twentyFourGap,
          high: twentyFourHigh,
          low: twentyFourLow,
          volume: twentyFourVolume,
          ...priceLast24hRest
        } = priceLast24h || {};
        const {
          previous_close_price: previousClose,
          ...currentPriceRest
        } = currentPrice || {};
        const parsedData = {
          id: pairId,
          twentyFourChange,
          twentyFourGap,
          twentyFourHigh,
          twentyFourLow,
          twentyFourVolume,
          ...priceLast24hRest,
          previousClose,
          ...currentPriceRest,
        };
        dispatch(updatePairs(parsedData));
      });
    }
    return () => {
      if (socket && socket.connected && microChainId) {
        socket.emit('leaveRoom', {
          roomName: `dex_${microChainId}`,
        });
        socket.off('getPriceData');
      }
    };
  }, [socket, reduxSocketStatus, microChainId]);

  useSocket(reduxSocketStatus);

  return <Fragment key={'socket'} />;
}

function Account() {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const reduxCurrentNetworkId = useSelector(currentNetworkId);
  const reduxSelectedNetwork = useSelector(
    (state) => selectNetworkById(state, reduxCurrentNetworkId),
  );
  const microChainId = useMemo(() => reduxSelectedNetwork?.micro_chain_id, [reduxSelectedNetwork]);

  const reduxAccountAddress = useSelector(accountAddress);

  useLayoutEffect(() => {
    const sessionAddress = sessionStorage.getItem('address');
    dispatch(setAccountAddress(sessionStorage.getItem('address') ?? ''));
    if (sessionAddress){
      dispatch(setUserInfo({
        EQAddress: sessionAddress
      }))
    }
  }, []);

  const setAccountWalletBalanceToRedux = useCallback(async () => unwrapResult(await dispatch(
    getAccountWalletBalance({
      address: reduxAccountAddress,
      microChainId,
    }),
  )), [reduxAccountAddress, microChainId]);

  const setAccountBalanceToRedux = useCallback(async () => unwrapResult(await dispatch(
    getAccountBalance({
      address: reduxAccountAddress,
      microChainId,
    }),
  )), [reduxAccountAddress, microChainId]);

  const setFavoritesToRedux = useCallback(
    async () => unwrapResult(await dispatch(
      getFavoritePairs({
        address: reduxAccountAddress,
        microChainId,
      }),
    )),
    [reduxAccountAddress, microChainId],
  );

  const setMatchedOrderFeeToRedux = useCallback(async () => {
    const result = unwrapResult(
      await dispatch(
        getMatchedOrderFee({
          address: reduxAccountAddress,
          microChainId,
        }),
      ),
    );
    dispatch(updateNetwork({ id: reduxCurrentNetworkId * 1, matchedFee: result * 1 }));
    return result;
  }, [reduxAccountAddress, microChainId, reduxCurrentNetworkId]);

  useEffect(() => {
    if (reduxAccountAddress && microChainId && reduxCurrentNetworkId) {
      setAccountWalletBalanceToRedux()
        .then(() => {
          setAccountBalanceToRedux().catch((e) => {
            handleError(e);
            // console.log('[App]::setAccountBalanceToRedux:', e);
          });
        })
        .catch((e) => {
          handleError(e);
          // console.log('[App]::setAccountWalletBalanceToRedux:', e);
        });
      setFavoritesToRedux().catch((e) => {
        // console.error('[App]::setFavoritesToRedux:', e);
        toast('something wrong during getting your favorites... please try again', { icon: '❌' });
      });
      setMatchedOrderFeeToRedux().catch((e) => {
        handleError(e);
        // console.log('[App]::setMatchedOrderFeeToRedux:', e);
      });
    }
  }, [microChainId, reduxAccountAddress, reduxCurrentNetworkId]);

  useEffect(() => {
    if (!reduxAccountAddress || reduxAccountAddress === '') {
      dispatch(clearAccountAssets());
    }
  }, [reduxAccountAddress]);

  return <Fragment key={'account'} />;
}

export default Trade;
