import React, { useMemo, useState } from "react";
import InlineContent from "view/common/InlineContent";
import "./HomeSection5.scss";
import newsWhisper from "../../../assets/images/news_whisper.png";
import newsVolare from "../../../assets/images/news_volare.png";
import newsVolareGame from "../../../assets/images/news_volare_game.png";
import newsPublicSale from "../../../assets/images/news_public_sale.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";
import { isMIUI } from "react-device-detect";

const NEWS_LIST = [
  {
    title: "Volare to Kick Start Public Sale of VOLR Token",
    description: "",
    name: "volare-public-sale",
    image: newsPublicSale,
    media: "Coinspeaker",
    date: "10. 05. 2022",
    link: "https://www.coinspeaker.com/volare-start-public-sale-volr-token/",
  },
  // {
  //   title: "Play-to-Rank is the Future of Blockchain Games",
  //   description: "",
  //   name: "news-volare-game",
  //   image: newsVolareGame,
  //   media: "Marketwatch",
  //   date: "09. 22. 2022",
  //   link: "https://finance.yahoo.com/news/play-rank-future-blockchain-games-094000953.html",
  // },
  // {
  //   title: "EQBR Holdings' Ultimate Web 3.0 Wallet & Messenger App 'Whisper MSG' Tops 1 million Downloads",
  //   description: "",
  //   name: "news-whisper",
  //   image: newsWhisper,
  //   media: "Yahoo! finance",
  //   date: "09. 05. 2022",
  //   link: "https://finance.yahoo.com/news/eqbr-holdings-ultimate-3-0-094100797.html",
  // },
  {
    title: "Volare Network, the First Commercial Blockchain Network, Releases Volare Business White Paper 1.0",
    description: "",
    name: "news-volare",
    image: newsVolare,
    media: "Coinspeaker",
    date: "08. 15. 2022",
    link: "https://www.coinspeaker.com/volare-network-releases-volare-business-white-paper/",
  },
];

function HomeSection5(props) {
  return (
    <InlineContent id={"NEWS"} backgroundClassName={"home-section5"} innerClassName={"home-section5-content"}>
      <NewsContainer data={NEWS_LIST} />
    </InlineContent>
  );
}

function NewsContainer(props) {
  const { data } = props || {};

  const isPc = useMediaQuery({
    minWidth: 1280,
  });
  const isLaptop = useMediaQuery({
    minWidth: 992,
    maxWidth: 1279,
  });
  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 991,
  });
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });

  return (
    <div className="notice-news-container">
      <div className="notice-news-header">
        <p className="notice-news-header-title">News</p>
        {/* <div className="notice-news-header-more-btn" onClick={() => goToReadmore()}>{`Read more >`}</div> */}
      </div>
      <div className="notice-card-wrapper">
        <Swiper slidesPerView={1.15} spaceBetween={10} breakpoints={{ 768: { slidesPerView: 2.2 }, 992: { slidesPerView: 2.4 }, 1280: { slidesPerView: 2.7 } }}>
          {data.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                <NoticeNewsCard data={el} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

const NoticeNewsCard = (props) => {
  const { data } = props || {};
  const { image, title, description, name, media, date, link } = data || {};
  return (
    <div className={`notice-news-card ${name}`} onClick={() => window.open(link)}>
      <div className="notice-news-card-image">
        <img src={image} className="notice-news-card-image-src" alt="notice-card" />
      </div>
      <div className="notice-news-card-info">
        <div className="notice-news-card-info-header">
          <div className="notice-news-card-info-header-type">{media}</div>
          <div className="notice-news-card-info-header-date">{date}</div>
        </div>
        <div className="notice-news-card-info-content">
          <div className="notice-news-card-info-content-title">{title && title}</div>
          <div className="notice-news-card-info-content-description">{description && description}</div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection5;
