import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Layout";
import Home from "./home/Home";
import Airdrop from "./airdrop/Airdrop";
import PublicSale from "./public-sale/PublicSale";
import PublicSaleJoin from "./public-sale/PublicSaleJoin";
import PublicSaleResult from "./public-sale/PublicSaleResult";
import PublicSaleHistory from "./public-sale/PublicSaleHistory";
import Trade from "./exchange/Trade";
import SumSubSdk from "./SumsubSDK";
import RedirectOAuth from "./redirect/RedirectOAuth";
import { useDispatch, useSelector } from "react-redux";
import { checkAddressKycStatus, refreshToken } from "../../store/user/userThunk";
import { user_access, user_info } from "../../store/user/userSlice";

const RootRouter = function () {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userInfo = useSelector(user_info);
  const userAccess = useSelector(user_access);

  const checkAddressKycStatus_ = async () => {
    try {
      await dispatch(
        checkAddressKycStatus({
          externalUserId: userInfo.addressUser.user_id,
        })
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  // useEffect(() => {
  //   dispatch(refreshToken());
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (Boolean(userAccess)) {
      checkAddressKycStatus_();
    }
  }, [userAccess]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="airdrop" element={<Airdrop />} />
        {/* TODO :: 27일 세일 시작 이전 페이지 접근 제한 */}
        <Route path="sale/*">
          <Route index element={<PublicSale />} />
          <Route path="result" element={<PublicSaleResult />} />
          <Route path="join" element={<PublicSaleJoin />} />

          {/*<Route path="result" element={<AuthFor address={''} isKYC={true}/>}>*/}
          {/*    <Route index element={<PublicSaleResult/>}/>*/}
          {/*</Route>*/}

          <Route path="history" element={<AuthFor address={""} />}>
            <Route index element={<PublicSaleHistory />} />
          </Route>
        </Route>

        {/* <Route path="exchange" element={<Trade />}>
          <Route path="network/:networkId" element={<Trade />}>
            <Route path="pair/:pairId" element={<Trade />} />
          </Route>
        </Route> */}
        <Route path="redirect/*">
          <Route index element={<RedirectOAuth />} />
        </Route>
      </Route>
      <Route path="volare-kyc/">
        <Route index element={<SumSubSdk />} />
      </Route>
    </Routes>
  );
};

function AuthFor(props) {
  const authorized = () => {
    const standards = Object.values(props);
    const index = standards.findIndex((el) => !el);
    return index < 0;
  };
  if (authorized()) {
    return <Outlet />;
  } else {
    return <Navigate to="../" />;
  }
}

export default RootRouter;
