import React, {
  useEffect, useState, useMemo, useCallback, useContext,
} from 'react';
import './OrderForm.scss';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import {
  currentNetworkId,
  selectNetworkById,
} from 'store/exchange/network/networksSlice';
import {
  accountAddress,
  selectAccountAssetByIdInDex,
} from 'store/exchange/account/accountSlice';
import ExtensionListener, { requestSignature, requestTransaction } from 'view/common/listener/ExtensionListener';
import { sendSignatureRequest } from 'view/common/listener/SignatureListener';
import {
  formatDigits, getAmountMaxDigits, getDecimals, getPriceMaxDigits, tmpPriceDigits,
} from 'view/router/exchange/util/numberUtil';
import { MAX_DIGITS, SIDE_BUY, SIDE_SELL } from 'view/router/exchange/util/literal';
import toast from 'react-hot-toast';
import _ from 'lodash';
import OrderFormTab from './OrderFormTab';
import OrderFormInfo from './OrderFormInfo';
import OrderFormSelector from './OrderFormSelector';
import FormInput from './FormInput';
import { TradeContext } from '../../context/TradeContext';

function OrderForm() {
  /* --- redux, context, props, hook 등으로 받은 전역값 --- */

  const {
    pair, price, setPrice, tradable,
  } = useContext(TradeContext);
  const networkId = useSelector(currentNetworkId);

  const {
    symbol, market, price: unitPrice,
  } = pair || {};

  const reduxAccountAddress = useSelector(accountAddress);
  const reduxSelectedNetwork = useSelector(
    (state) => selectNetworkById(state, networkId),
  );

  const {
    chain_id: chainId,
    chain_network_id: chainNetworkId,
    micro_chain_id: microChainId,
    contract_address: contractAddress,
    matchedFee,
  } = reduxSelectedNetwork || {};
  /* -------------------------------------------------------------------------------------------- */

  /* --- dex asset 정보 --- */
  const symbolAssetInfo = useSelector(
    (state) => selectAccountAssetByIdInDex(state, symbol),
  ) ?? {
    balance: '0',
    unit: symbol,
    average_price: 0,
  };
  const { balance: symbolBalance } = symbolAssetInfo || {};
  const marketAssetInfo = useSelector((state) => selectAccountAssetByIdInDex(state, market)) ?? {
    balance: '0',
    unit: market,
    average_price: 0,
  };
  const { balance: unitBalance } = marketAssetInfo || {};
  /* -------------------------------------------------------------------------------------------- */

  const [tabIndex, setTabIndex] = useState(SIDE_BUY); // Buy or Sell
  const [amount, setAmount] = useState(0); // orderForm 내 Amount

  /* --- 전략기획팀 요청으로 정해진 가격, 수량 단위 --- */
  const amountMaxDigits = useMemo(
    () => getAmountMaxDigits(new BigNumber(tmpPriceDigits(unitPrice ?? 0))),
    [unitPrice],
  );

  /* --- 체인팀 요청사항 : cancel fee 보다 적은 수량 거래 불가 --- */
  const {cancelSymbolFee, cancelMarketFee} = pair || {cancelSymbolFee: 0, cancelMarketFee: 0};
  const minimumAmount = useMemo(
      () => (tabIndex === SIDE_BUY ? cancelSymbolFee : cancelMarketFee),
      [tabIndex, cancelSymbolFee, cancelMarketFee],
  );

  const minimumAmountUnit = useMemo(
    () => new BigNumber(1).shiftedBy(-amountMaxDigits).toNumber(),
    [amountMaxDigits],
  );

  const maxAmount = useMemo(() => {
    if (tabIndex === SIDE_BUY) {
      return new BigNumber(unitBalance)
        .dividedBy(price)
        .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
        .dividedToIntegerBy(minimumAmountUnit)
        .multipliedBy(minimumAmountUnit)
        .toNumber();
    }
    return new BigNumber(symbolBalance)
      .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
      .dividedToIntegerBy(minimumAmountUnit)
      .multipliedBy(minimumAmountUnit)
      .toNumber();
  }, [amountMaxDigits, price, unitBalance, symbolBalance, tabIndex, minimumAmountUnit]);
  /* -------------------------------------------------------------------------------------------- */

  /* --- 비율 조절 버튼( 10 ~ 100 %) --- */
  const rateSelectorBtnHandler = useCallback(
    (percent) => {
      // 매수일 경우
      if (tabIndex === SIDE_BUY) {
        const targetPrice = new BigNumber(unitBalance).multipliedBy(percent / 100);
        if (price > 0) {
          setAmount(
            targetPrice
              .dividedBy(price)
              .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
              .dividedToIntegerBy(minimumAmountUnit)
              .multipliedBy(minimumAmountUnit)
              .toNumber(),
          );
        }
        return;
      }
      // 매도일 경우
      if (tabIndex === SIDE_SELL) {
        setAmount(
          new BigNumber(symbolBalance)
            .multipliedBy(percent / 100)
            .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
            .dividedToIntegerBy(minimumAmountUnit)
            .multipliedBy(minimumAmountUnit)
            .toNumber(),
        );
      }
    },
    [price, symbolBalance, tabIndex, amountMaxDigits, minimumAmountUnit],
  );
  /* -------------------------------------------------------------------------------------------- */

  /* --- ui 내 total --- */
  const total = useMemo(() => new BigNumber(price)
    .multipliedBy(amount ?? 0)
    .decimalPlaces(8)
    .toNumber(), [price, amount]);
  /* -------------------------------------------------------------------------------------------- */

  /* --- place order --- */
  const onClickOrderButton = useCallback(async () => {
    try {
      // add validation temp
      if (amount < (minimumAmount)) {
        window.alert(`trading amount should be bigger than ${minimumAmount}`);
        return;
      }
      const loginType = sessionStorage.getItem('loginType');
      const payload = {
        pair: `${symbol}_${market}`,
        order_id: `${Date.now()}-${Math.random() * 10000000}`,
        side: tabIndex === SIDE_BUY ? 'buy' : 'sell',
        price: new BigNumber(price).toString(),
        amount: new BigNumber(amount).toString(),
      };
      if (loginType * 1 === 1) {
        requestSignature({ id: 'PlaceOrder', ...payload });
      } else {
        sendSignatureRequest({
          network_id: networkId,
          chain_network_id: chainNetworkId,
          chain_id: chainId,
          micro_chain_id: microChainId,
          address: reduxAccountAddress,
          contract_address: contractAddress,
          function_name: 'PlaceOrder',
          tx_data: { ...payload },
          data_need_signature: true,
        });
      }
      setPrice(0);
      setAmount(0);
    } catch (e) {
      toast(e.message, { icon: '❌' });
    }
  }, [
    reduxAccountAddress,
    reduxSelectedNetwork,
    tabIndex,
    symbol,
    market,
    price,
    amount,
    minimumAmount,
  ]);

  const requestTransactionToExtensionAfterSignature = useCallback((payload) => {
    const { id, ...rest } = payload;
    if (id === 'PlaceOrder') {
      requestTransaction({
        networkId,
        chainNetworkId,
        chainId,
        microChainId,
        address: reduxAccountAddress,
        contractAddressQuery: contractAddress,
        nonceDomain: `${process.env.REACT_APP_EXCHANGE_SERVER_URL}${process.env.REACT_APP_API_VERSION}dex/request/nonce`,
        transactionDomain: `${process.env.REACT_APP_EXCHANGE_SERVER_URL}${process.env.REACT_APP_API_VERSION}dex/order/register`,
        functionName: 'PlaceOrder',
        tx_data: {
          pair: `${symbol}_${market}`,
          order_id: `${Date.now()}-${Math.random() * 10000000}`,
          price: new BigNumber(price).toString(),
          side: tabIndex === SIDE_BUY ? 'buy' : 'sell',
          amount: new BigNumber(amount).toString(),
          ...rest,
        },
      });
    }
  }, [
    reduxSelectedNetwork,
    reduxAccountAddress,
    tabIndex,
    symbol,
    market,
    price,
    amount,
  ]);

  const registerOrderBtnDisabled = useMemo(
    () => Boolean(new BigNumber(total).isLessThanOrEqualTo(0)
      || new BigNumber(amount).modulo(minimumAmountUnit).toNumber() !== 0),
    [total, amount, minimumAmountUnit],
  );
  /* -------------------------------------------------------------------------------------------- */

  /* --- 유저 입력 값을 제한 --- */
  useEffect(() => {
    if (new BigNumber(total).isGreaterThan(new BigNumber(unitBalance)) && tabIndex === SIDE_BUY) {
      setAmount(
        new BigNumber(unitBalance)
          .dividedBy(price)
          .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
          .toNumber(),
      );
    }
  }, [tabIndex, total, unitBalance, amountMaxDigits, minimumAmountUnit]);

  useEffect(() => {
    if (tabIndex === SIDE_SELL
      && new BigNumber(amount).isGreaterThan(new BigNumber(symbolBalance))) {
      setAmount(
        new BigNumber(symbolBalance)
          .decimalPlaces(amountMaxDigits <= 0 ? 0 : amountMaxDigits)
          .toNumber(),
      );
    }
  }, [tabIndex, symbolBalance, amount, amountMaxDigits]);

  useEffect(() => {
    if (!price || price < 0) {
      setPrice(0);
    }
  }, [price]);

  useEffect(() => {
    if (!amount || amount < 0) {
      setAmount(0);
    }
  }, [amount]);
  /* -------------------------------------------------------------------------------------------- */

  /* --- Buy, Sell 탭 옮길때마다 초기화 --- */
  useEffect(() => {
    setPrice(0);
    setAmount(0);
  }, [tabIndex]);
  /* -------------------------------------------------------------------------------------------- */

  return (
    <div id="order-form">
      <ExtensionListener setSignature={requestTransactionToExtensionAfterSignature} />
      <OrderFormTab data={{ tabIndex, setTabIndex, symbol }} />
      <div className="order-form-content-wrapper">
        <OrderFormInfo
          data={{
            label: 'Amount',
            amount: tabIndex === SIDE_BUY ? unitBalance * 1 : symbolBalance * 1,
            marketCurrency: tabIndex === SIDE_BUY ? market : symbol,
          }}
        />
        <FormInput
          data={{
            label: 'Price',
            inputValue: price,
            setInputValue: setPrice,
            decimalsLimit: getPriceMaxDigits(tmpPriceDigits(unitPrice * 1)),
          }}
          disabled={_.isEmpty(pair) || !tradable}
        />
        <div>
          <FormInput
            data={{
              label: `Amount(${symbol || ''})`,
              inputValue: amount,
              setInputValue: setAmount,
              decimalsLimit: Math.min(getAmountMaxDigits(tmpPriceDigits(unitPrice * 1)), getDecimals(cancelSymbolFee * 1)),
              addDisabled: maxAmount <= amount,
              maxAmount,
            }}
            disabled={reduxAccountAddress === '' || _.isEmpty(pair) || !tradable}
          />
          <div className="order-form-content-info" style={{ marginTop: 8 }}>
            <div />
            <OrderFormSelector rateSelectorBtnHandler={rateSelectorBtnHandler} />
          </div>
        </div>
        <OrderFormInfo data={{ label: 'Total', amount: total, marketCurrency: market }} />
        <div style={{ textAlign: 'right' }}>
          <span>
            minimum trading unit: {formatDigits(minimumAmountUnit, MAX_DIGITS, 0)} {symbol}
          </span>
          {
            reduxAccountAddress !== ''
            && <span style={{ marginLeft: 18 }}>Fee: {matchedFee * 100}%</span>
          }
        </div>
        <button
          className={`order-form-order-button ${tabIndex === SIDE_BUY ? 'buy' : 'sell'}`}
          type="button"
          disabled={registerOrderBtnDisabled}
          onClick={onClickOrderButton}>
          {`${tabIndex === SIDE_BUY ? 'Buy' : 'Sell'} ${symbol || ''}`}
        </button>
      </div>
    </div>
  );
}

export default OrderForm;
