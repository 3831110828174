import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";
import { errorCatcher } from "../../utils/CommonUtils";
import BigNumber from "bignumber.js";
import { currencyFor, weiFor } from "utils/EthereumUtil";
import Web3 from "web3";

const queryString = require("query-string");

let web3 = new Web3();

const api_version = process.env.REACT_APP_API_VERSION;
const hub_server_url = process.env.REACT_APP_HUB_SERVER_URL;

export const getSales = createAsyncThunk("sale/getSales", async ({ lastId, limit, category, stage, isVolare }) => {
  let result;
  const query = qs.stringify({ lastId, limit, category, stage, isEqhub: true }, { addQueryPrefix: true });
  result = await axios.get(hub_server_url + api_version + `sales${query}`);
  return result.data;
});

export const getSaleDetailsById = createAsyncThunk("sale/getSaleDetailsById", async ({ saleId }) => {
  let result;
  result = await axios.get(hub_server_url + api_version + `sales/${saleId}`);
  return result.data;
});

export const participateSaleBefore = createAsyncThunk("sale/participateSaleBefore", async ({ saleId, body }) => {
  let result;
  result = await axios.post(hub_server_url + api_version + `sales/${saleId}/participates`, body);
  return result.data;
});

export const participateSaleAfter = createAsyncThunk("sale/participateSale", async ({ saleId, body }) => {
  let result;
  result = await axios.post(hub_server_url + api_version + `sales/${saleId}/participates-check`, body);
  return result.data;
});

export const getSaleTypes = createAsyncThunk("sale/getSaleTypes", async () => {
  let result;
  result = await axios.get(hub_server_url + api_version + `sales/types`);
  return result.data;
});

export const getSalePlatforms = createAsyncThunk("sale/getSalePlatforms", async () => {
  let result;
  result = await axios.get(hub_server_url + api_version + `sales/platforms`);
  return result.data;
});

export const getSaleParticipantDetails = createAsyncThunk("sale/getSaleParticipantDetails", async ({ saleId, limit, lastId, receiverAddress }) => {
  let result;
  const query = qs.stringify({ limit, lastId, receiverAddress }, { addQueryPrefix: true });
  result = await axios.get(hub_server_url + api_version + `sales/${saleId}/participates${query}`);
  return result.data;
});

export const getContractAbiCodeByContractAddress = createAsyncThunk(
  "sale/getContractsAbiCodeByContractAddress",
  async ({ microChainId, contractAddress }) => {
    let result;
    const query = qs.stringify({ microChainId }, { addQueryPrefix: true });
    result = await axios.get(hub_server_url + api_version + `request/contract/address/${contractAddress}/abi-code${query}`);
    return result.data;
  }
);

export const getContracts = createAsyncThunk("sale/getContracts", async ({}) => {
  let result;
  result = await axios.get(hub_server_url + api_version + `eth-subscription/contracts`);
  return result.data;
});

export const getContractAbiCodeByName = createAsyncThunk("sale/getContractAbiCodeByName", async ({ contractName, eventName }) => {
  let result;
  const query = qs.stringify({ eventName }, { addQueryPrefix: true });
  result = await axios.get(hub_server_url + api_version + `eth-subscription/contracts/${contractName}${query}`);
  return result.data;
});

export const getMicroChainDetail = createAsyncThunk("sale/getMicroChainDetail", async ({ microChainId }) => {
  let result;
  result = await axios.get(hub_server_url + api_version + `micro-chains/${microChainId}`);
  return result.data;
});

export const getMicroChainCurrency = createAsyncThunk("sale/getMicroChainCurrency", async ({ microChainCurrencyId }) => {
  let result;

  result = await axios.get(hub_server_url + api_version + `micro-chain-currency/${microChainCurrencyId}`);
  return result.data;
});

export const getSaleParticipatePrice = createAsyncThunk("sale/getSaleParticipatePrice", async ({ saleId }) => {
  let result;

  result = await axios.get(hub_server_url + api_version + `sales/${saleId}/participate-price`);
  return result.data;
});

export const getSaleParticipateResult = createAsyncThunk("sale/getSaleParticipateResult", async ({ saleId }) => {
  let result;
  result = await axios.get(hub_server_url + api_version + `sales/${saleId}/participants-result`);
  return result.data;
});
