import KEY from './Key';

export const eqbrSignature = async (
  tx_data,
  priKey,
) => {
  const txHash = await KEY.MakeThash(tx_data);
  // tx = await JSON.stringify(tx);

  return {
    public_key: KEY.MakePublicKey(priKey),
    signature: await KEY.MakeSignature(
      txHash,
      priKey,
      KEY.MakePublicKey(priKey),
    ),
  };
};
