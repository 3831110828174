const abbreviation = [
  "K",
  "M",
  "B",
  "t",
  "q",
  "Q",
  "s",
  "S",
  "o",
  "n",
  "d",
  "U",
  "D",
  "T",
  "Qt",
  "Qd",
  "Sd",
  "St",
  "O",
  "N",
  "v",
  "c",
];

export const commaNum = (num, limit = 18) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: limit,
  })
    .format(parseFloat(num))
    .slice(1);
};

export const contractNum = (num, mode = "float") => {
  let result;
  switch (mode) {
    case "float":
      result = parseFloat(num).toFixed(2);
      break;
    case "int":
      result = parseInt(num, 10);
      break;
    default:
      result = Number(num);
  }
  let deleteNum = -1;
  while (result >= 1000) {
    result = parseInt(result / 10, 10);
    deleteNum += 1;
  }
  if (deleteNum % 3 === 0) {
    return (
      (result / 100).toFixed(1) + abbreviation[parseInt(deleteNum / 3, 10)]
    );
  }
  if (deleteNum > -1) {
    return (
      result / 10 ** (2 - (deleteNum % 3)) +
      abbreviation[parseInt(deleteNum / 3, 10)]
    );
  }
  return result;
};
