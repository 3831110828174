import React, {useContext} from 'react';
import "./modal.scss";
import "./ModalKYCVerification.scss"
import Button from "../button/Button";
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";
import {ModalContext} from "../../../App";

function ModalKYCVerification() {
    const {params, onModalClose, setResult} = useContext(ModalContext)

    const onClickRegister = () => {
        setResult({name: 'KYCVerification', success: true})
        onModalClose()
    }

    return (
        <div className={'modal-kyc-verification'}>
            <ModalHeader title={'KYC Required'}/>
            <ModalContent>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300}}>
                    ...
                </div>
                <Button className={'gradient-button'}
                        style={{display: 'flex', margin: '18px auto 0'}}
                        onClick={onClickRegister}
                >Go to register</Button>
            </ModalContent>
        </div>
    );
}

export default ModalKYCVerification;
