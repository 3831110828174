import { createAsyncThunk } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-cycle
import axios from "axios";
import qs from "qs";

const api_version = process.env.REACT_APP_VOLARE_API_VERSION;
const server_url = process.env.REACT_APP_VOLARE_SERVER_URL;
const levelName = "basic-kyc-level";

export const refreshToken = createAsyncThunk("user/refreshToken", async (query, { getState }) => {
  const result = await axios.post(server_url + api_version + `tokens/refresh`, {}, { withCredentials: true });
  return result.data;
});

export const logout = createAsyncThunk("user/logout", async (query, { getState }) => {
  const result = await axios.post(server_url + api_version + `users/logout`, {}, { withCredentials: true });
  return result.data;
});


export const oauthCallback = createAsyncThunk("user/oauthCallback", async ({ code }, { getState }) => {
  const query = qs.stringify({ code }, { addQueryPrefix: true });
  const result = await axios.get(server_url + api_version + `users/oauthCallback${query}`, {withCredentials: true});
  return result.data;
});

export const checkAddressKycStatus = createAsyncThunk("kyc/checkAddressKycStatus", async ({ externalUserId }, { getState }) => {
  const query = qs.stringify({ externalUserId }, { addQueryPrefix: true });
  const result = await axios.get(server_url + api_version + `kyc/check${query}`);
  return result.data;
});

export const createKycApplicant = createAsyncThunk("kyc/createKycApplicant", async ({ externalUserId }, { getState }) => {
  const result = await axios.post(server_url + api_version + `kyc/applicants?levelName=${levelName}`, { externalUserId });
  return result.data;
});

export const getKycAccessToken = createAsyncThunk("kyc/getKycAccessToken", async ({ userId }, { getState }) => {
  const query = qs.stringify({ userId, levelName: levelName, ttlInSecs: 300 }, { addQueryPrefix: true });
  const result = await axios.post(server_url + api_version + `kyc/access-token${query}`);
  return result.data;
});


// export const loginUser = createAsyncThunk("user/loginUser", async (query, { getState }) => {
//   const result = await axios.post(server_url + api_version + `users/login`, query, {
//     withCredentials: true,
//   });
//   return result.data;
// });
