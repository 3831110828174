import React from 'react';
import "./Loading.scss";
import loading from "../assets/images/loading.png";

function Loading(props) {
    return (
        <div>
            <img src={loading} className={'loading'}/>
        </div>
    );
}

export default Loading;