import { unwrapResult } from "@reduxjs/toolkit";
import { refreshToken } from "../store/user/userThunk";

let pending = false;

const isPending = () => {
  return pending;
};

const pendingOn = () => {
  pending = true;
};

const pendingOff = () => {
  pending = false;
};

export const dispatchRequest = async (dispatch, functionName, queryData) => {
  try {
    const result = await unwrapResult(await dispatch(functionName(queryData)));
    return result;
  } catch (e) {
    // alert(e.message)
  }
};

// eslint-disable-next-line consistent-return
export const tokenRefresh = async dispatch => {
  if (!isPending()) {
    pendingOn();
    try {
      const actionResult = await dispatch(refreshToken({}));
      pendingOff();
      return await unwrapResult(actionResult);
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};
