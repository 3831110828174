import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Header2.scss";
import logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as JoinImg } from "../../assets/images/join_img.svg";
import twitterImg from "../../assets/images/twitter_img.png";
import telegramImg from "../../assets/images/telegram_img.png";
import mediumImg from "../../assets/images/medium_img.png";
import pdf from "../../assets/pdf/volare.pdf";
import hamburgerImg from "../../assets/images/hamburger_img.png";
import closeImg from "../../assets/images/close_img.png";
import linkToImg from "../../assets/images/link_to_image.png";
import walletImg from "../../assets/images/wallet_img.png";
import disconnectWalletIng from "../../assets/images/disconnect_wallet_img.png";
import dropDownOpenImg from "../../assets/images/dropdown_open_img.png";
import dropDownCloseImg from "../../assets/images/dropdown_close_img.png";
import verifyKycImg from "../../assets/images/verify_kyc_img.png";
import searchAddressImg from "../../assets/images/search_img.png";
import disconnectWalletTabImg from "../../assets/images/close_fill_img.png";
import { useMediaQuery } from "react-responsive";
import MetamaskConnectButton from "../metamask/MetamaskConnectButton";
import EQWalletConnect from "../EQWalletConnect";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, user_info } from "../../../store/user/userSlice";
import { kycStatusTextInfo, statusLabel } from "../../../utils/KycUtil";
import { camelCase } from "../../../utils/CommonUtils";
import { dispatchRequest } from "../../../utils/dispatchRequest";
import { getSales } from "../../../store/public-sale/publicSaleThunk";
import _ from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { checkAddressKycStatus, createKycApplicant, getKycAccessToken } from "../../../store/user/userThunk";

export const VOLARE_KYC = "VOLAREKYC";

const HEADER_TAB = [
  {
    label: "PUBLIC SALE",
    to: "/sale",
  },
  {
    label: "AIRDROP",
    to: "/airdrop",
  },
  {
    label: "EXCHANGE",
    to: "/exchange",
    style: {},
  },
  {
    label: "WHITE PAPER",
    to: "???",
  },
  {
    label: "VOLARE DOCS",
    to: "???",
    isLink: true,
  },
  {
    label: "VOLRSCAN",
    to: "???",
    isLink: true,
  },
];

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(user_info);
  const { kycVerification, EQAddress } = userInfo || {};

  const [currentScroll, setCurrentScroll] = useState(false);
  const [selectedTab, setSelectedTab] = useState({});
  const [openWalletTab, setOpenWalletTab] = useState(false);
  const [isExistPublicSale, setIsExistPublicSale] = useState(false);

  const closeWalletTab = () => {
    setOpenWalletTab(false);
  };

  const getPublicSaleData = async () => {
    const sales = camelCase(
      await dispatchRequest(dispatch, getSales, {
        id: 0,
        limit: 1,
        category: "all",
        stage: "publicsale",
        isVolare: true,
      })
    );
    if (sales?.length > 0) setIsExistPublicSale(true);
  };

  const createKycApplicant_ = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          createKycApplicant({
            externalUserId: EQAddress,
          })
        )
      );
      return result;
    } catch (e) {
      alert(e.message);
    }
  };

  const getAccessTokenForKyc = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          getKycAccessToken({
            userId: EQAddress,
          })
        )
      );
      return result;
    } catch (e) {
      alert(e.message);
    }
  };

  const onClickTabFunction1 = async () => {
    switch (kycVerification.isVerify) {
      case 0: {
        const result = await createKycApplicant_().then(async (res) => {
          try {
            const result = await getAccessTokenForKyc();
            const { token } = result || {};
            if (token) {
              try {
                window.open(`/volare-kyc/${token}`, "Volare_KYC", "width=700px,height=800px,resizable=yes");
              } catch (e) {
                alert(e.message);
              }
            }
          } catch (e) {
            alert(e.message);
          }
        });
        return;
      }

      case 1:
        switch (kycVerification.checkKycResult.status) {
          case 0:
            {
              try {
                const result = await getAccessTokenForKyc();
                const { token } = result || {};
                if (token) {
                  try {
                    window.open(`/volare-kyc/${token}`, "Volare_KYC", "width=700px,height=800px,resizable=yes");
                  } catch (e) {
                    alert(e.message);
                  }
                }
              } catch (e) {
                alert(e.message);
              }
            }
            return;
          case 1:
            alert("Please waiting for approval");
            return;
          case 2:
            {
              try {
                const result = await getAccessTokenForKyc();
                const { token } = result || {};
                if (token) {
                  try {
                    window.open(`/volare-kyc/${token}`, "Volare_KYC", "width=700px,height=800px,resizable=yes");
                  } catch (e) {
                    alert(e.message);
                  }
                }
              } catch (e) {
                alert(e.message);
              }
            }
            return;
          case 3:
            alert("Your approval is finally rejected. Please contact our service team");
            return;
          case 4:
            return;
          default:
            return;
        }
      default:
        return;
    }
  };

  const onClickTabFunction2 = () => {
    window.alert("Explorer가 열린후 wallet address가 검색됩니다.");
  };

  const onClickTabFunction3 = () => {
    sessionStorage.removeItem("address");
    sessionStorage.removeItem("loginType");
    sessionStorage.removeItem("keystore");
    dispatch(setUserInfo({ ...userInfo, EQAddress: "" }));
    setOpenWalletTab(false);
  };

  const wallet_tab = [
    {
      icon: verifyKycImg,
      label: statusLabel(kycVerification, kycStatusTextInfo),
      onClickFunc: onClickTabFunction1,
    },
    {
      icon: searchAddressImg,
      label: "Open in Volrscan",
      additionalIcon: linkToImg,
      onClickFunc: onClickTabFunction2,
    },
    {
      icon: disconnectWalletTabImg,
      label: "Disconnect",
      onClickFunc: onClickTabFunction3,
    },
  ];

  const checkAddressKycStatus_ = async () => {
    try {
      return unwrapResult(
        await dispatch(
          checkAddressKycStatus({
            walletAddress: EQAddress,
          })
        )
      );
    } catch (e) {
      alert(e.message);
    }
  };

  const isPc = useMediaQuery({
    query: "(min-width:1200px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:992px)",
  });
  const isMobile = useMediaQuery({
    query: "(min-width:768px)",
  });
  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const checkScroll = useCallback(() => {
    if (location.pathname.includes("exchange")) {
      setCurrentScroll(101);
      return;
    }
    setCurrentScroll(window.scrollY);
  }, [location]);

  useEffect(() => {
    getPublicSaleData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
  }, [checkScroll]);

  useEffect(() => {
    setSelectedTab(HEADER_TAB.find((tab) => location.pathname.includes(tab.to)));
  }, [location.pathname]);

  const [mobileTab, setMobileTab] = useState(false);

  useEffect(() => {
    if (mobileTab) {
      document.body.classList.add("ReactModal__Body--open");
    } else {
      document.body.classList.remove("ReactModal__Body--open");
    }
  }, [mobileTab]);

  useEffect(() => {
    setMobileTab(false);
  }, [isTablet]);

  const headerTabClickHandler = (to) => {
    if (to === "???") {
      alert("Coming soon");
    } else {
      if (to !== "/sale" || isExistPublicSale) navigate(to);
      else alert("No public sale");
    }
    setMobileTab(false);
  };

  useEffect(() => {
    if (location.pathname.includes("exchange")) {
      setCurrentScroll(101);
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(EQAddress)) {
      checkAddressKycStatus_();
    }
  }, [EQAddress]);

  useEffect(() => {
    if (openWalletTab) {
      checkAddressKycStatus_();
      window.addEventListener("click", closeWalletTab);
      return () => {
        window.removeEventListener("click", closeWalletTab);
      };
    }
  }, [openWalletTab]);

  return (
    <header style={{ backgroundColor: currentScroll < 100 ? "rgba(255, 255, 255, 0.1)" : "black" }}>
      <nav id={"top-nav2"}>
        <Link to={"/"} className="header-logo-box">
          <img className="top-logo" src={logo} alt={"Volare"} />
        </Link>
        {isTablet ? (
          <>
            <div style={{ display: "flex" }}>
              {HEADER_TAB.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`top-nav-button${item?.label === selectedTab?.label ? " selected" : ""}`}
                    onClick={() => headerTabClickHandler(item.to)}
                  >
                    {item.label}
                    {item.isLink && <img className={"link-img"} src={linkToImg} alt={"link"} />}
                  </div>
                );
              })}
            </div>
            {/*<MetamaskConnectButton className={'gradient-button'}>*/}
            {/*    Connect Wallet*/}
            {/*</MetamaskConnectButton>*/}
            <div style={{ position: "relative" }}>
              <EQWalletConnect
                className={EQAddress ? "header-button connect" : "header-button"}
                isConnected={!!EQAddress}
                setOpenWalletTab={setOpenWalletTab}
              >
                {EQAddress ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img className={"disconnect-wallet-img"} src={disconnectWalletIng} alt="disconnect-wallet" />
                    {`${EQAddress.slice(0, 4)}...${EQAddress.slice(-4)}`}
                    <img className={"dropdown-navigate-img"} src={openWalletTab ? dropDownCloseImg : dropDownOpenImg} alt="dropdown" />
                  </div>
                ) : (
                  <>
                    <img className={"wallet-img"} src={walletImg} alt="wallet" />
                    Connect Wallet
                  </>
                )}
              </EQWalletConnect>
              {openWalletTab && (
                <div className="wallet-tab-wrapper">
                  {wallet_tab.map((el, index) => {
                    return (
                      <div className={"wallet-tab"} key={index} onClick={el.onClickFunc}>
                        <img className={"wallet-tab-icon"} src={el.icon} alt="tab-icon" />
                        {el.label}
                        {el.additionalIcon && <img className={"wallet-tab-icon additional"} src={el.additionalIcon} alt="tab-icon" />}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/*<EQWalletConnect id={'eq-wallet-connect'} className={'gradient-button header'} isConnected={!!EQAddress}>*/}
            {/*    {EQAddress ? `${EQAddress.slice(0,4)}...${EQAddress.slice(-4)}` :*/}
            {/*        <>*/}
            {/*            <img className={'wallet-img'} src={walletImg} alt=""/>*/}
            {/*            Connect Wallet*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</EQWalletConnect>*/}
            {/*<div className='sns-wrapper'>*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('twitter')} src={twitterImg} alt={'twitterImg'} />*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('telegram')} src={telegramImg} alt={'telegramImg'} />*/}
            {/*    <img className='header-sns-img' onClick={() => snsClickHandler('medium')} src={mediumImg} alt={'mediumImg'} />*/}
            {/*</div>*/}
          </>
        ) : (
          <button className="mobile-btn" onClick={() => setMobileTab(true)}>
            <img src={hamburgerImg} className="mobile-btn-img" alt="hamburger" />
          </button>
        )}
        {/* active mobile header */}
        {!isTablet && (
          <div className={`mobile-tab-box`} style={mobileTab ? { right: 0 } : {}}>
            <div className="mobile-tab-wrapper">
              <div className="mobile-close-tab">
                <button className="mobile-btn" onClick={() => setMobileTab(false)}>
                  <img src={closeImg} alt="close" className="mobile-btn-img" />
                </button>
              </div>
              {HEADER_TAB.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`mobile-tab${item?.label === selectedTab?.label ? " selected" : ""}`}
                    style={item.style}
                    onClick={() => headerTabClickHandler(item.to)}
                  >
                    {item.label}
                  </div>
                );
              })}
              <div className="mobile-tab">
                <EQWalletConnect
                  className={EQAddress ? "header-button connect" : "header-button"}
                  isConnected={!!EQAddress}
                  setOpenWalletTab={setOpenWalletTab}
                >
                  {EQAddress ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img className={"disconnect-wallet-img"} src={disconnectWalletIng} alt="disconnect-wallet" />
                      {`${EQAddress.slice(0, 4)}...${EQAddress.slice(-4)}`}
                      <img className={"dropdown-navigate-img"} src={openWalletTab ? dropDownCloseImg : dropDownOpenImg} alt="dropdown" />
                    </div>
                  ) : (
                    <>
                      <img className={"wallet-img"} src={walletImg} alt="wallet" />
                      Connect Wallet
                    </>
                  )}
                </EQWalletConnect>
              </div>
            </div>
          </div>
        )}
        {mobileTab && <div className="overlay" onClick={() => setMobileTab(false)} />}
      </nav>
    </header>
  );
}

export default Header;
