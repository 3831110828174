import React from "react";
import "./HomeSection2.scss";
import InlineContent from "../../../common/InlineContent";
import backgroundImg from "../../../assets/images/section2_bg.png";
import homeImage2 from "../../../assets/images/home-image2.png";

function HomeSection2(props) {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <img src={backgroundImg} alt={"background"} className={"section2-background-img"} />
      <InlineContent id={"VOLARE?"} backgroundClassName={"home-section2"} innerClassName={"home-section2-content"}>
        <div className="phone-img-box">
          <div className="blur" />
          <img src={homeImage2} className={"home-section2-img"} alt={"phoneImg"} />
        </div>
        <div className="section2-text-wrapper">
          <div className="section2-title">VOLARE?</div>
          <div className="section2-sub-title">
            {
              "Volare will be the first blockchain network project to prove the commercial viability of a new blockchain technology, the “Equilibrium”. The project intends to construct an expansive ecosystem based on blockchain networks deployed with Equilibrium technology (“Volare Network”). Volare Network will benefit from the high speed and scalability of Equilibrium for commercial utilization of blockchain technology across industries, and seeks to actively attract blockchain gaming dApps that are readily accessible."
            }
          </div>
        </div>
      </InlineContent>
    </div>
  );
}

export default HomeSection2;
