import { useState, useEffect } from 'react';

const calculatedScrollbar = (document.body.offsetWidth - document.body.clientWidth)
  || (window.innerWidth - document.body.offsetWidth);

export default function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = useState(calculatedScrollbar);

  useEffect(()=> {
    setScrollbarWidth((document.body.offsetWidth - document.body.clientWidth)
        || (window.innerWidth - document.body.offsetWidth));
  }, []);

  useEffect(() => {
    function handleResize() {
      setScrollbarWidth((document.body.offsetWidth - document.body.clientWidth)
        || (window.innerWidth - document.body.offsetWidth));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--scroll-width', `${scrollbarWidth}px`);
  }, [scrollbarWidth]);

  return { scrollbarWidth };
}
