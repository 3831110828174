import React, {useMemo} from 'react';
import "./ModalInput.scss";

function ModalInput(props) {

    const {name, label, subLabel, style, type, isUnit, ...rest} = props || {};



    if(type === 'textarea'){
        return (
            <div className={'modal-input'} style={style}>
                <label htmlFor={name}>{label}&nbsp;<span className={'sub-label'}>{subLabel ?? ""}</span></label>
                <textarea id={name} name={name} {...rest}/>
            </div>
        );
    }

    return (
        <div className={'modal-input'} style={style}>
            <label htmlFor={name}>{label}&nbsp;<span className={'sub-label'}>{subLabel ?? ""}</span></label>
            <input id={name} name={name} type={type} {...rest} style={{paddingRight: isUnit ? 60 : 16}}/>
        </div>
    );
}

export default ModalInput;