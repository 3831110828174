import React, { useContext, useMemo } from "react";
import Modal from "react-modal";
import { ModalContext } from "../../../App";
import ModalKYCVerification from "./ModalKYCVerification";
import ModalJoinSale from "./ModalJoinSale";
import ModalJoinResult from "./ModalJoinResult";
import ModalSignature from "./ModalSignature";
import ModalChecking from "./ModalChecking";
import ModalInvalidDevice from "./ModalInvalidDevice";
import ModalKYCStatus from "./ModalKYCStatus";

function AllModals(props) {
  const { activeModal, onModalClose, isOpen } = useContext(ModalContext);

  const renderModal = useMemo(() => {
    switch (activeModal) {
      case "KYCVerification":
        return <ModalKYCVerification />;
      // case "JoinSale":
      //     return <ModalJoinSale close={onModalClose}/>
      case "JoinResult":
        return <ModalJoinResult />;
      case "Signature":
        return <ModalSignature close={onModalClose} />;
      case "Checking":
        return <ModalChecking close={onModalClose} />;
      case "InvalidDevice":
        return <ModalInvalidDevice close={onModalClose} />;
      case "KycStatus":
        return <ModalKYCStatus close={onModalClose} />;
      default:
        return;
    }
  }, [activeModal]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        if (activeModal !== "JoinSale") {
          onModalClose();
        }
      }}
      className="modal-border"
      overlayClassName="modal"
      closeTimeoutMS={300}
    >
      {renderModal}
    </Modal>
  );
}

export default AllModals;
