export const makeObjToUrlQuery = (obj) => {
  if (typeof obj === 'object') {
    return `${Object.keys(obj).length > 0 ? '?' : ''}${Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((el) => `${key}[]=${encodeURIComponent(el)}`).join('&');
        }
        return `${key}=${value}`;
      })
      .join('&')}`;
  }
  return '';
};
