import { unwrapResult } from "@reduxjs/toolkit";
import { refreshToken } from "store/user/userThunk";

const _ = require("lodash");

export const toUpper = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1, str.length);
  }
  return "";
};

export const encodeBase64 = (str) => {
  return btoa(encodeURIComponent(str));
};

export const decodeBase64 = (str) => {
  try {
    return decodeURIComponent(atob(str));
  } catch (e) {
    return str;
  }
};

export const queryString = (query) => {
  return Object.keys(query)
    .map((key) => {
      if (query[key] !== undefined && query[key] !== null && query[key] !== "") return `${key}=${query[key]}`;
      return "";
    })
    .join("&");
};

export const thumbnailImg = (url) => {
  if (url === undefined || url === null)
    return "https://s3.ap-northeast-2.amazonaws.com/test-nftuser.eqbr.com/undefined/user/profile/img/49/3ab53295f32d749f71fff307b9dfa755034c21a51646730200954.jpg";
  return url;
};

export const errorCatcher = (e) => {
  if (e.response) {
    throw new Error(e.response.data.message);
  } else if (e.request) {
    console.log(e.request);
    throw e;
  } else {
    throw e;
  }
};

export const camelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        [_.camelCase(key)]: camelCase(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const detectChildWindow = async function (childWindow, closeCallback) {
  const interval = window.setInterval(function () {
    try {
      if (childWindow == null || childWindow.closed) {
        window.clearInterval(interval);
        closeCallback(childWindow);
      }
    } catch (e) {
      console.error(e.message);
    }
  }, 1000);
  return childWindow;
};

export const refreshToken_ = async (dispatch) => {
  try {
    const result = unwrapResult(await dispatch(refreshToken({})));
  } catch (e) {
    console.error(e.message);
  }
};
