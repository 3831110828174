import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_info } from "../../store/user/userSlice";
import { useLocation } from "react-router-dom";
import { detectChildWindow, refreshToken_ } from "../../utils/CommonUtils";
import { useMediaQuery } from "react-responsive";
import { ModalContext } from "../../App";
import {isMobile} from "react-device-detect";

function EQWalletConnect({ children, isConnected, setOpenWalletTab, ...rest }) {
  const { openModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const userInfo = useSelector(user_info);
  const {EQAddress} = userInfo || {};

  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const modalId = useRef(-1);

  const openModalInvalidDevice = () => {
    const newId = openModal("InvalidDevice", {});
    modalId.current = newId;
  };

  async function openConnection() {
    let popupX =(document.body.offsetWidth / 2) - (420 / 2);
    let popupY= (window.screen.height / 2) - (500 / 2);

    if (isMobile) {
      openModalInvalidDevice();
    } else {
      if(Boolean(EQAddress)){
        setOpenWalletTab(true)
      }else{
        const connectionWindow = window.open(
            `${process.env.REACT_APP_OAUTH_URL}?client_id=${process.env.REACT_APP_VOLARE_OAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
            "EQBR_Connect_Module",
            `width=420px,height=500px, left=${popupX}, top=${popupY},resizable=no`
        );
        await detectChildWindow(connectionWindow, async function () {
          await refreshToken_(dispatch);
        });
      }
    }
  }

  const { pathname } = useLocation();

  const [showOptions, setShowOptions] = useState(false);

  const isExchange = useMemo(() => {
    const exchangePattern = new RegExp("^\\/exchange\\/network(?:\\/([^\\/#\\?]+?))[\\/#\\?]?\\/pair(?:\\/([^\\/#\\?]+?))[\\/#\\?]?$");
    return exchangePattern.test(pathname);
  }, [pathname]);

  const onBlur = () => {
    if (isExchange) {
      setShowOptions(false);
      return;
    }
    return;
  };

  return (
    <>
      <>
        <button {...rest} onClick={openConnection} onBlur={onBlur}>
          {children}
          {/*{*/}
          {/*    isExchange &&*/}
          {/*    <ProfileMenu isProfileOpen={showOptions} setIsProfileOpen={setShowOptions} />*/}
          {/*}*/}
        </button>
      </>
    </>
  );
  return children;
}

export default EQWalletConnect;
