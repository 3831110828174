import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getFavoritePairs = createAsyncThunk(
  'favorites/getFavoritePairs',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetFavoritePairs', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`user/favorite/pair${urlQuery}`, 'get');
      }
      const { favorite_pairs: favoritePairs } = result || {};
      if (favoritePairs !== undefined) {
        return favoritePairs;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const setFavoritePair = createAsyncThunk(
  'favorites/setFavoritePair',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexSetFavoritePair', payload);
      } else {
        const { pairId, ...rest } = payload || {};
        const urlQuery = makeObjToUrlQuery(rest);
        result = await getApiResult(`user/favorite/pair${urlQuery}`, 'post', {
          pairId,
        });
      }
      const { favorite_pair: favoritePair } = result || {};
      if (favoritePair !== undefined) {
        return favoritePair;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const deleteFavoritePair = createAsyncThunk(
  'favorites/deleteFavoritePair',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexDeleteFavoritePair', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`user/favorite/pair${urlQuery}`, 'delete');
      }
      const { id } = payload || {};
      const { success } = result || {};
      if (success) {
        return id;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
