import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getCurrencies } from './currenciesThunk';

const currenciesAdapter = createEntityAdapter({
  selectId: (currency) => currency.id,
});

export const {
  selectAll: selectAllCurrencies,
  selectIds: selectAllCurrenciesIds,
  selectById: selectCurrencyById,
  selectTotal: selectCurrenciesTotal,
} = currenciesAdapter.getSelectors((state) => state.currencies);

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: currenciesAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    updateCurrenciesFees(state, action) {
      currenciesAdapter.updateMany(state, action.payload.map((c) => {
        const { id, ...rest } = c;
        return ({ id: c.id, changes: { ...rest } });
      }));
    },
  },
  extraReducers: {
    [getCurrencies.pending]: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    [getCurrencies.rejected]: (state, action) => {
      if (state.status === 'pending') {
        state.status = 'rejected';
        state.error = action.payload;
      }
    },
    [getCurrencies.fulfilled]: (state, action) => {
      if (state.status === 'pending') {
        currenciesAdapter.upsertMany(state, action.payload);
        state.status = 'fulfilled';
      }
    },
  },
});

// Action 영역
export const { updateCurrenciesFees } = currenciesSlice.actions;

// Store 데이터 영역
export const currencyStatus = (RootState) => RootState.currencies.status;

export default currenciesSlice.reducer;
