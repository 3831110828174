import { io } from 'socket.io-client';
import {
  socketCreated,
  socketClosed,
  socketOpened,
  socketSubscribed,
} from 'store/exchange/socket/socketSlice';
import { toast } from 'react-hot-toast';

let socket = null;
export const getSocket = () => socket;
const socketManager = (store) => (next) => (action) => {
  // SOCKET 세팅 하는 부분
  if (action.type.includes('socket')) {
    switch (action.type) {
      // CREATE SOCKET
      case socketCreated.type:
        if (socket) {
          socket.removeAllListeners();
        }
        socket = io(process.env.REACT_APP_EXCHANGE_SERVER_URL, {
          autoConnect: false,
          transports: ['websocket'],
          reconnection: true,
          reconnectionAttempts: 30,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 50000,
        });
        next(action);
        break;
      // OPEN SOCKET
      case socketOpened.type:
        if (socket) {
          socket.connect();
          socket.on('connect', () => {
            console.log('[socket]::connected');
            // 여기 도착 시 정상적으로 socket open;
            next(socketOpened());
          });
          socket.on('connect_error', (error) => {
            toast('Real-time data lookup may not be possible due to network errors.', { icon: '❌' });
            // socket.connect();
            next(socketClosed());
          });
          socket.on('disconnect', (reason) => {
            console.log('[socket]::disconnected', reason);
            if (reason === 'io server disconnect') {
              socket.connect();
            }
            next(socketClosed());
          });
          socket.io.on('reconnect', (attempt) => {
            console.log('reconnect attempt', attempt);
          });
          socket.io.on('reconnect_attempt', (attempt) => {
            console.log('reconnect attempt', attempt);
          });
          socket.io.on('reconnect_error', (error) => {
            console.log('reconnect_error', error);
          });
          socket.io.on('ping', () => {
            console.log('pong');
          });
        }
        break;
      // SUBSCRIBE CHANNEL
      case socketSubscribed.type:
        socket.on('error', (res) => {
          console.log('[socket channel]::error', res);
          window.alert(res);
        });
        next(action);
        break;
      case socketClosed.type:
        if (socket) {
          socket.disconnect();
          socket = null;
          next(action);
        } else {
          next({ type: 'socket/close/Error' });
        }
        break;
      default:
        break;
    }
  } else {
    next(action);
  }
};
export default socketManager;
