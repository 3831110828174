import React, { useCallback, useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/images/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as JoinImg } from "../../assets/images/join_img.svg";
import twitterImg from "../../assets/images/twitter_img.png";
import telegramImg from "../../assets/images/telegram_img.png";
import mediumImg from "../../assets/images/medium_img.png";
import hamburgerImg from "../../assets/images/hamburger_img.png";
import closeImg from "../../assets/images/close_img.png";
import { useMediaQuery } from "react-responsive";

const HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "WHITEPAPER", "VOLRSCAN"];
const MOBILE_HEADER_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "WHITEPAPER", "LITEPAPER", "VOLRSCAN"];

const WHITE_PAPER_URL = process.env.REACT_APP_WHITEPAPER_URL;
const LITE_PAPER_URL = process.env.REACT_APP_LITEPAPER_URL;

const PAPER_LIST = [
  {
    id: 1,
    label: "WHITEPAPER",
    url: WHITE_PAPER_URL,
  },
  {
    id: 2,
    label: "LITEPAPER",
    url: LITE_PAPER_URL,
  },
];

function Header(props) {
  const location = useLocation();

  const [currentScroll, setCurrentScroll] = useState(false);
  const [selectedTab, setSelcetedTab] = useState();

  const isPc = useMediaQuery({
    query: "(min-width:1200px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:992px)",
  });
  const isMobile = useMediaQuery({
    query: "(min-width:768px)",
  });
  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const checkScroll = useCallback(() => {
    setCurrentScroll(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
  }, [checkScroll]);

  const snsClickHandler = (sns) => {
    if (sns === "twitter") {
      window.open("https://twitter.com/volare_official");
    }
    if (sns === "telegram") {
      window.open("https://t.me/volareofficial");
    }
    if (sns === "medium") {
      window.open("https://medium.com/@Volare_Official");
    }
  };

  useEffect(() => {
    if (selectedTab) {
      window.location.href = `${window.location.origin}/#${selectedTab}`;
    }
  }, [selectedTab]);

  useEffect(() => {
    if (location.pathname.includes("airdrop")) {
      setSelcetedTab();
    }
  }, [location.pathname]);

  const [mobileTab, setMobileTab] = useState(false);

  useEffect(() => {
    if (mobileTab) {
      document.body.classList.add("ReactModal__Body--open");
    } else {
      document.body.classList.remove("ReactModal__Body--open");
    }
  }, [mobileTab]);

  useEffect(() => {
    setMobileTab(false);
  }, [isTablet]);

  const [isShowWhitePaperList, setIsShowWhitePaperList] = useState(false);

  const headerTabClickHandler = (item) => {
    if (item === "WHITEPAPER") {
      return;
    } else if (item === "VOLRSCAN") {
      window.open("https://volrscan.volare.network");
    } else {
      setSelcetedTab(item);
    }
  };

  const mobileHeaderTabClickHandler = (item) => {
    if (item === "WHITEPAPER") {
      window.open(WHITE_PAPER_URL);
    } else if (item === "LITEPAPER") {
      window.open(LITE_PAPER_URL);
    } else if (item === "VOLRSCAN") {
      window.open("https://volrscan.volare.network");
    } else {
      setSelcetedTab(item);
    }
    setMobileTab(false);
  };

  return (
    <header style={{ backgroundColor: currentScroll < 100 ? "transparent" : "black" }}>
      <nav id={"top-nav"}>
        <Link to={"/"} className="header-logo-box">
          <img className="top-logo" src={logo} alt={"Volare"} />
        </Link>
        {isTablet ? (
          <>
            <div style={{ display: "flex" }}>
              {HEADER_TAB.map((item, index) => {
                return (
                  <>
                    <div
                      key={`header-${index}`}
                      className={`top-nav-button`}
                      onClick={() => headerTabClickHandler(item)}
                      onMouseEnter={() => {
                        if (item === "WHITEPAPER") {
                          setIsShowWhitePaperList(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (item === "WHITEPAPER") {
                          setIsShowWhitePaperList(false);
                        }
                      }}
                    >
                      <p className={`top-nav-button-label  ${selectedTab === item ? "selected" : ""}`}>{item}</p>
                      {isShowWhitePaperList && item === "WHITEPAPER" && (
                        <div className="paper-list">
                          <div className="paper-list-box">
                            {PAPER_LIST.map((el, index) => {
                              return (
                                <div className="paper-list-box-item" key={`paper-${el.id}`} onClick={() => window.open(el.url)}>
                                  {el.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
              <Link to={"/airdrop"} className={"top-nav-button"} style={{ display: "flex", alignItems: "center" }}>
                <JoinImg className="join-img" />
                <p className="top-nav-button-label">mVOLR AIRDROP</p>
              </Link>
            </div>
            <div className="sns-wrapper">
              <img className="header-sns-img" onClick={() => snsClickHandler("twitter")} src={twitterImg} alt={"twitterImg"} />
              <img className="header-sns-img" onClick={() => snsClickHandler("telegram")} src={telegramImg} alt={"telegramImg"} />
              <img className="header-sns-img" onClick={() => snsClickHandler("medium")} src={mediumImg} alt={"mediumImg"} />
            </div>
          </>
        ) : (
          <button className="mobile-btn" onClick={() => setMobileTab(true)}>
            <img src={hamburgerImg} className="mobile-btn-img" alt="hamburger" />
          </button>
        )}
        {/* active mobile header */}
        {!isTablet && (
          <div className={`mobile-tab-box`} style={mobileTab ? { right: 0 } : {}}>
            <div className="mobile-tab-wrapper">
              <div className="mobile-close-tab">
                <button className="mobile-btn" onClick={() => setMobileTab(false)}>
                  <img src={closeImg} alt="close" className="mobile-btn-img" />
                </button>
              </div>
              {MOBILE_HEADER_TAB.map((item, index) => {
                return (
                  <div key={`tab-${index}`} className={`mobile-tab`} onClick={() => mobileHeaderTabClickHandler(item)}>
                    {item}
                  </div>
                );
              })}
              <Link to={"/airdrop"} onClick={() => setMobileTab(false)} className={"mobile-tab"} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                  <JoinImg className="join-img" />
                  mVOLR AIRDROP
                </div>
              </Link>
            </div>
          </div>
        )}
        {mobileTab && <div className="overlay" onClick={() => setMobileTab(false)} />}
      </nav>
    </header>
  );
}

export default Header;
