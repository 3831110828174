import React from "react";
import "./HomeSection3.scss";
import InlineContent from "../../../common/InlineContent";
import roadmap from "../../../assets/images/roadmap.png";
import ecosystemMap from "../../../assets/images/ecosystem-map.png";
import ecosystemImg1 from "../../../assets/images/ecosystem_img_1.png";
import ecosystemImg2 from "../../../assets/images/ecosystem_img_2.png";
import ecosystemImg3 from "../../../assets/images/ecosystem_img_3.png";
import seeMore from "../../../assets/images/see-more.png";

function HomeSection3(props) {
  return (
    <div style={{ position: "relative", maxWidth: "2560px", width: "100%" }}>
      <div className="section3-gradient" />
      <InlineContent backgroundClassName={"home-section3"} innerClassName={"home-section3-content"}>
        <div className="section3-title-wrapper gradient" id="ROADMAP">
          <div className="section3-title">ROADMAP</div>
          <div className="section3-sub-title">
            {`Volare Network is striving to fully utilize the advantages of the Equilibrium engine\n
                    to become the central network for blockchain-based games within the expansive ecosystem`}
          </div>
        </div>
        <div className="slide-img-view">
          <div className="left-blur" />
          <div className="right-blur" />
          <div className="slide-img-view-slide">
            <img className="home-section3-roadmap" src={roadmap} alt="roadmap" />
          </div>
        </div>

        <div className="section3-title-wrapper" id="ECOSYSTEM">
          <div className="section3-title">ECOSYSTEM</div>
          <div className="section3-sub-title">{`Volare Network supports healthy and developmental environment that can constantly enhance network ecosystem with various services.`}</div>
        </div>
        <div className="slide-img-view">
          <div className="left-blur" />
          <div className="right-blur" />
          <div className="slide-img-view-slide">
            <img className="home-section3-ecosystem-img" src={ecosystemMap} alt="ecosystem" />
          </div>
        </div>
        <div className={"home-section3-block"}>
          <div className="section3-text-box">
            <div className="section3-content-title">{"Development Support"}</div>
            <div className={"home-section3-divide-line"} />
            <div className="section3-content-description">{`Development Support in Volare Network was introduced for the purpose of significantly reducing development costs for developers who wish to join Volare Network. Volare Network provides useful and convenient tools to help developers to readily develop creative and productive blockchain services within the network.`}</div>
            <a className="see-more" href="https://medium.com/volare/volare-official-announcement-04-8b8b87c869ee" target="_blank" rel="noreferrer">
              SEE MORE <img className={"see-more-arrow"} src={seeMore} alt="more" />
            </a>
          </div>
          <img src={ecosystemImg1} className={"section3-img ecosystem-img-1"} alt={"ecosystemImg3"} />
        </div>
        <div className={"home-section3-block reverse"}>
          <img src={ecosystemImg2} className={"section3-img ecosystem-img-2"} alt={"ecosystemImg2"} />
          <div className="section3-text-box">
            <div className="section3-content-title">{"Business Support"}</div>
            <div className={"home-section3-divide-line"} />
            <div className="section3-content-description">{`Volare Network offers a wide range of services that assist developers in their effort to commercialize products or networks with a goal to expand their businesses.`}</div>
            <a className="see-more" href="https://medium.com/volare/volare-official-announcement-05-68c889f4fe58" target="_blank" rel="noreferrer">
              SEE MORE <img className={"see-more-arrow"} src={seeMore} alt="more" />
            </a>
          </div>
        </div>
        <div className={"home-section3-block"}>
          <div className="section3-text-box">
            <div className="section3-content-title">{"Communication Support"}</div>
            <div className="section3-content-sub-title">{"Whisper MSG"}</div>
            <div className={"home-section3-divide-line"} />
            <div className="section3-content-description">{`Under its global slogan, “Your privacy is our priority”, Whisper MSG provides highest level of data security using asymmetric key encryption technology based on an elliptic curve algorithm. Whisper’s blockchain-based P2P structure allows users to register and store all critical information as a decentralized identifier on user’s own devices, without the need for storing information on a central server. Whisper MSG is a gateway for users to travel around Volare Network, complete with a robust blockchain-based messenger with a built-in wallet feature.`}</div>
            <a className="see-more" href="https://medium.com/volare/volare-official-announcement-06-704a5b52ab6c" target="_blank" rel="noreferrer">
              SEE MORE <img className={"see-more-arrow"} src={seeMore} alt="more" />
            </a>
          </div>
          <img src={ecosystemImg3} className={"section3-img ecosystem-img-3"} alt={"ecosystemImg1"} />
        </div>
        <div style={{ height: 120 }} />
      </InlineContent>
    </div>
  );
}

export default HomeSection3;
