import React, { useCallback, useEffect } from 'react';

const message = {
  event: '',
  payload: '',
};

export const isOurSource = (event) => event.source === window;

export const isRightForm = (event) => {
  if (!event.data) {
    return false;
  }
  return !!(event.data.event && event.data.payload);
};

export const getEvent = (event) => event.data.event;

export const requestTransaction = (payload) => {
  setTimeout(() => {
    const newMessage = { ...message };
    newMessage.event = 'makeTransaction';
    newMessage.payload = payload;
    window.postMessage(newMessage, window.origin);
  }, 200);
};

export const requestAddress = () => {
  setTimeout(() => {
    const newMessage = { ...message };
    newMessage.event = 'getAddress';
    newMessage.payload = {};
    window.postMessage(newMessage, window.origin);
  }, 200);
};

export const requestSignature = (payload) => {
  setTimeout(() => {
    const newMessage = { ...message };
    newMessage.event = 'makeSignature';
    newMessage.payload = payload;
    window.postMessage(newMessage, window.origin);
  }, 200);
};

function ExtensionListener(props) {
  const { setAddress, setTransaction, setSignature } = props || {};

  const messageEventHandler = useCallback(
    async (event) => {
      if (!isOurSource(event)) {
        return;
      }
      if (!isRightForm(event)) {
        return;
      }
      if (!event.data.event.includes('Response')) {
        return;
      }
      switch (getEvent(event)) {
        case 'getAddressResponse':
          if (setAddress) {
            if (event.data.payload.success) await setAddress(event.data.payload.data.address);
            else {
              console.warn('login failed');
            }
          }
          break;
        case 'makeTransactionResponse':
          if (setTransaction) {
            if (event.data.payload && event.data.payload.success) {
              // console.log(event.data.payload.data);
              // await setTransaction(event.data.payload.data.transaction);
            } else console.warn('login failed');
          }
          break;
        case 'makeSignatureResponse':
          if (setSignature) {
            if (event.data.payload.success) {
              const data = { ...event.data.payload.data };
              const { publicKey, ...rest } = data;
              const newData = { public_key: publicKey, ...rest };
              await setSignature(newData);
            }
          }
          break;
        default:
          console.warn('unexpected message', getEvent(event));
          break;
      }
    },
    [setAddress, setTransaction],
  );

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, [setAddress, setTransaction]);

  return <></>;
}

export default ExtensionListener;
