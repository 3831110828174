import wETHc from 'view/assets/images/exchange/asset/WETH-color.png';
import wBTCc from 'view/assets/images/exchange/asset/WBTC-color.png';
import EQWc from 'view/assets/images/exchange/asset/EQW-color.png';
import EQDc from 'view/assets/images/exchange/asset/EQD-color.png';

export const tokenImageColor = {
  WETH: wETHc,
  WBTC: wBTCc,
  EQW: EQWc,
  EQD: EQDc,
};
