import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { deleteFavoritePair, getFavoritePairs, setFavoritePair } from './favoritesThunk';

const favoritesAdapter = createEntityAdapter({
  selectId: (favorite) => favorite.id,
});

export const {
  selectAll: selectAllFavorites,
  selectIds: selectFavoritesIds,
  selectById: selectFavoriteById,
} = favoritesAdapter.getSelectors((state) => state.favorites);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: favoritesAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  extraReducers: {
    [getFavoritePairs.pending]: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    [getFavoritePairs.rejected]: (state, action) => {
      if (state.status === 'pending') {
        state.status = 'rejected';
        state.error = action.payload;
      }
    },
    [getFavoritePairs.fulfilled]: (state, action) => {
      if (state.status === 'pending') {
        favoritesAdapter.setAll(state, action.payload);
        state.status = 'fulfilled';
      }
    },
    [setFavoritePair.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
    [setFavoritePair.fulfilled]: (state, action) => {
      favoritesAdapter.addOne(state, action.payload);
    },
    [deleteFavoritePair.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
    [deleteFavoritePair.fulfilled]: (state, action) => {
      favoritesAdapter.removeOne(state, action.payload);
    },
  },
});

export const favoriteStatus = (RootState) => RootState.favorites.status;

export default favoritesSlice.reducer;
