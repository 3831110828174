import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';

export const getMarkets = createAsyncThunk(
  'markets/getMarkets',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetMarketsByMicroChainId', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`market${urlQuery}`, 'get');
      }
      const { markets } = result || {};
      if (markets !== undefined) {
        return markets;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
