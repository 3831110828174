import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMicroChainDetail } from 'store/public-sale/publicSaleThunk';
import { dispatchRequest } from 'utils/dispatchRequest';

const useMicroChainDetail = ({microChainId}) => {
  const dispatch = useDispatch();
  const [microChainDetail, setMicroChainDetail] = useState();
  const requestGetMicroChain = useCallback(async (microChainId) => {
    return await dispatchRequest(dispatch, getMicroChainDetail, {microChainId})
  }, [dispatch])
  useEffect(() => {
    if(microChainId && microChainDetail?.micro_chain?.id !== microChainId){
      requestGetMicroChain(microChainId).then(res => setMicroChainDetail(res.micro_chain_detail))
    }
  }, [microChainId, microChainDetail, requestGetMicroChain])
  return microChainDetail
};

export default useMicroChainDetail;
