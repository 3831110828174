import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getPairOrderBook = createAsyncThunk(
  'order/getPairOrderBook',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetPairOrderBook', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`order-history/order-book${urlQuery}`, 'get');
      }
      const { order_book: orderBook } = result || {};
      if (orderBook !== undefined) {
        return orderBook;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getPairMatchedOrder = createAsyncThunk(
  'order/getPairMatchedOrder',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetPairMatchedOrder', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`matched-order${urlQuery}`, 'get');
      }
      const { matched_order: matchedOrder } = result || {};
      if (matchedOrder !== undefined) {
        return matchedOrder;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getPairMatchedOrderByAddress = createAsyncThunk(
  'order/getPairMatchedOrderByAddress',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetPairMatchedOrderByAddress', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`matched-order/address${urlQuery}`, 'get');
      }
      const { matched_order: matchedOrder } = result || {};
      if (matchedOrder !== undefined) {
        return matchedOrder;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getPairOrderHistoryByAddress = createAsyncThunk(
  'order/getPairOrderHistoryByAddress',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetPairOrderHistoryByAddress', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`order-history/address${urlQuery}`, 'get');
      }
      const { order_history: orderHistory } = result || {};
      if (orderHistory !== undefined) {
        return orderHistory;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const registerOrder = createAsyncThunk(
  'order/registerOrder',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexRegisterOrder', payload);
      } else {
        const {
          microChainId, chainNetworkId, chainId, ...rest
        } = payload || {};
        const urlQuery = makeObjToUrlQuery({ microChainId, chainNetworkId, chainId });
        result = await getApiResult(`order/register${urlQuery}`, 'post', rest);
      }
      const { success, message } = result || {};
      if (success) {
        return success;
      }
      return invalidServerResponseHandler(message, [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const cancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async (query, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexCancelOrder', query);
      } else {
        const {
          microChainId, chainNetworkId, chainId, ...rest
        } = query || {};
        const urlQuery = makeObjToUrlQuery({ microChainId, chainNetworkId, chainId });
        result = await getApiResult(`order/cancel${urlQuery}`, 'post', rest);
      }
      const { success, message } = result || {};
      if (success) {
        return success;
      }
      return invalidServerResponseHandler(message, [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
