import { createSlice } from '@reduxjs/toolkit';

// Adapter 영역 - 선언부
const initialState = {
  status: 'CLOSED', // CREATED, OPEN, CLOSED
  subscribed: [],
};

// Slice 영역 - 선언부
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    socketCreated(state) {
      state.status = 'CREATED';
    },
    socketOpened(state) {
      state.status = 'OPENED';
    },
    socketClosed(state) {
      state.status = 'CLOSED';
    },
    socketSubscribed(state, action) {
      state.subscribed = action.payload;
    },
  },
});

// Action 영역
export const {
  socketCreated, socketOpened, socketClosed, socketSubscribed,
} = socketSlice.actions;

// Store 데이터 영역
export const socketStatus = (RootState) => RootState.socket.status;
export const subscribed = (RootState) => RootState.socket.subscribed;

// Reducer 영역
export default socketSlice.reducer;
