import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getCurrencies = createAsyncThunk(
  'currencies/getCurrencies',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetMicroChainCurrencies', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`micro-chain-currency${urlQuery}`, 'get');
      }
      const { micro_chain_currency: microChainCurrency } = result || {};
      if (microChainCurrency !== undefined) {
        return microChainCurrency;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
