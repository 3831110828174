import React, {useContext} from 'react';
import "./ModalHeader.scss";
import iconCloseModal from "../../../assets/images/icon-close-modal.png";
import {ModalContext} from "../../../../App";

function ModalHeader({title}) {

    const {onModalClose} = useContext(ModalContext)

    return (
        <div className={'modal-header'}>{title}
            <button onClick={onModalClose}>
                <img src={iconCloseModal} className={'modal-close-button'}/>
            </button>
        </div>
    );
}

export default ModalHeader;