import React from 'react';
import "./ModalContent.scss";

function ModalContent({children, ...rest}) {
    return (
        <div className={'modal-content'} {...rest}>
            {children}
        </div>
    );
}

export default ModalContent;
