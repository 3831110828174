import React, { Fragment, useContext } from 'react';
import OrderForm from './orderForm/OrderForm';
import OrderHistory from './orderHistory/OrderHistory';
import OrderBook from './orderBook/OrderBook';
import Trades from './trades/Trades';
import { TradeContext } from '../context/TradeContext';

const tabInfo = ['Chart', 'Trade', 'Orderbook', 'History'];

function MobileLayout() {
  const { selectedTab, setSelectedTab } = useContext(TradeContext);

  return (
    <Fragment>
      <div className={'mobile-content'}>
        <div className={'mobile-scroll'}>
          <div style={{ flex: 1, display: selectedTab === 1 ? 'block' : 'none' }}>
            <section key="order-form" style={{ height: 434 }}>
              <OrderForm />
            </section>
            <section key="order-history" style={{ height: 255, marginTop: 10 }}>
              <OrderHistory />
            </section>
          </div>
          <section key="order-book" style={{ flex: 1, display: selectedTab === 2 ? 'block' : 'none' }}>
            <OrderBook />
          </section>
          <section key="trades" style={{ flex: 1, display: selectedTab === 3 ? 'block' : 'none' }}>
            <Trades />
          </section>
        </div>
      </div>
      <div className={'mobile-tab'}>
        {
          tabInfo.map((info, index) =>
            <label>
              <input type={'radio'}
                     name='mobile-tab'
                     value={index}
                     checked={selectedTab === index}
                     onChange={(e)=> setSelectedTab(e.target.value * 1)}
              />
              <span>{info}</span>
            </label>
          )
        }
      </div>
    </Fragment>
  );
}

export default MobileLayout;
