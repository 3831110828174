import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getAccountWalletBalance = createAsyncThunk(
  'account/getAccountWalletBalance',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetBalances', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`user/balance${urlQuery}`, 'get');
      }
      const { data } = result || {};
      if (data !== undefined) return data;
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getAccountBalance = createAsyncThunk(
  'account/getAccountBalance',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetDepositBalances', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`user/balance/deposit${urlQuery}`, 'get');
      }
      const { data } = result || {};
      if (data !== undefined) return data;
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getNonceByAddress = createAsyncThunk(
  'account/getNonceByAddress',
  async (payload, { getState, rejectWithValue }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetNonceByAddress', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`request/nonce${urlQuery}`, 'get');
      }
      const { nonce } = result || {};
      if (nonce !== undefined) {
        return nonce;
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
