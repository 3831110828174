import React, {
  Fragment, useCallback, useContext, useEffect,
} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getPriceLast24h } from 'store/exchange/pairs/pairsThunk';
import { getCancelOrderFee } from 'store/exchange/fee/feeThunk';
import { updatePairs } from 'store/exchange/pairs/pairsSlice';
import { currentNetworkId, selectNetworkById } from 'store/exchange/network/networksSlice';
import { useErrorHandler } from 'react-error-boundary';
import { TradeContext } from '../context/TradeContext';

function CallsForSelectedPair({ status, setStatus }) {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const reduxCurrentNetworkId = useSelector(currentNetworkId);
  const reduxCurrentNetwork = useSelector(
    (state) => selectNetworkById(state, reduxCurrentNetworkId),
  );
  const { micro_chain_id: microChainId } = reduxCurrentNetwork || {};

  const { pair } = useContext(TradeContext);
  const { market, symbol, id: currentPairId } = pair || {};

  const setPairs24hInfosToRedux = useCallback(async () => unwrapResult(await dispatch(
    getPriceLast24h({
      pairId: currentPairId,
    }),
  )), [currentPairId]);

  useEffect(() => {
    if (reduxCurrentNetworkId && currentPairId) {
      setPairs24hInfosToRedux().catch((e) => {
        handleError(e);
        // console.error('[Trade]::setPairs24hInfosToRedux:', e);
      });
    }
  }, [reduxCurrentNetworkId, currentPairId]);

  const setPairsCancelFeeToRedux = useCallback(async () => {
    const { id, symbol, market } = pair;
    const cancelSymbolFee = unwrapResult(await dispatch(
      getCancelOrderFee({
        symbol,
        microChainId,
      }),
    ));
    const cancelMarketFee = unwrapResult(await dispatch(
      getCancelOrderFee({
        symbol: market,
        microChainId,
      }),
    ));
    dispatch(updatePairs({ id, cancelSymbolFee, cancelMarketFee }));
    return ({ id, cancelSymbolFee, cancelMarketFee });
  }, [pair, microChainId]);

  useEffect(() => {
    if (reduxCurrentNetworkId && currentPairId && market && symbol && status === 'pending') {
      setPairsCancelFeeToRedux()
        .then((res) => {
          setStatus('fulfilled');
        })
        .catch((e) => {
          setStatus('rejected');
          handleError(e);
          // console.error('[Trade]::setPairsCancelFee:', e);
        });
    }
  }, [reduxCurrentNetworkId, currentPairId, market, symbol, status]);

  return (
    <Fragment key='calls-for-selected-pair' />
  );
}

export default CallsForSelectedPair;
