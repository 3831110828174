import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSaleParticipatePrice } from 'store/public-sale/publicSaleThunk';
import { dispatchRequest } from 'utils/dispatchRequest';

const useSaleParticipatePrice = ({saleId}) => {
  const dispatch = useDispatch();
  const [saleParticipatePrice, setSaleParticipatePrice] = useState();
  const requestGetSaleParticipatePrice = useCallback(async (saleId) => {
    return await dispatchRequest(dispatch, getSaleParticipatePrice, {saleId})
  }, [dispatch])
  useEffect(() => {
    if(saleId){
      requestGetSaleParticipatePrice(saleId).then(res => setSaleParticipatePrice(res))
    }
  }, [saleId, requestGetSaleParticipatePrice])
  return saleParticipatePrice
};

export default useSaleParticipatePrice;
