import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./PublicSale.scss";
import { useDispatch, useSelector } from "react-redux";
import { user_info } from "../../../store/user/userSlice";
import { useNavigate } from "react-router-dom";
import InlineContent from "../../common/InlineContent";
import medium from "../../assets/images/sale-icon-medium.png";
import discord from "../../assets/images/sale-icon-discord.png";
import ooo from "../../assets/images/sale-icon-ooo.png";
import telegram from "../../assets/images/sale-icon-telegram.png";
import twitter from "../../assets/images/sale-icon-twitter.png";
import Button from "../../common/button/Button";
import RoundKeyValueBox from "../../common/RoundKeyValueBox";
import RoundStateBox from "../../common/RoundStateBox";
import { ModalContext } from "../../../App";
import EQWalletConnect from "../../common/EQWalletConnect";
import { dispatchRequest } from "../../../utils/dispatchRequest";
import {
  getSaleDetailsById,
  getSaleParticipantDetails,
  getSaleParticipateResult,
  getSales,
  getSaleTypes,
} from "../../../store/public-sale/publicSaleThunk";
import { camelCase } from "../../../utils/CommonUtils";
import { currencyFor } from "../../../utils/EthereumUtil";
import { UTCTimestamp } from "../../../utils/TimeUtil";
import { useMediaQuery } from "react-responsive";
import { commaNum } from "utils/NumberUtil";
import useMicroChainDetail from "view/hooks/useMicroChainDetail";
import useMicroChainCurrency from "view/hooks/useMicroChainCurrency";
import moment from "moment";
import { checkAddressKycStatus } from "../../../store/user/userThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import MetamaskConnectButton from "view/common/metamask/MetamaskConnectButton";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import useSaleParticipatePrice from "../../hooks/useSaleParticipatePrice";
import GradientBox from "./GradientBox";

const _ = require("lodash");

const icons = [ooo, twitter, telegram, discord, medium];

const PRIVATE_SALE_AMOUNT = 62000000;

const Stages = {
  1: "Pre-Sale",
  2: "Public sale",
};

function PublicSale(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  const { EQAddress, addressUser } = useSelector(user_info);

  const [saleId, setSaleId] = useState(0);
  const [publicSaleData, setPublicSaleData] = useState({});
  const [participants, setParticipants] = useState([]);

  const microChainDetail = useMicroChainDetail({ microChainId: publicSaleData.microChainId });
  const saleCurrency = useMicroChainCurrency({ microChainCurrencyId: publicSaleData?.coinId });
  const purchaseCurrency = useMicroChainCurrency({ microChainCurrencyId: publicSaleData?.purchaseCoin });
  const saleParticipatePrice = useSaleParticipatePrice({ saleId: publicSaleData.saleId });

  const { id, result, openModal } = useContext(ModalContext);
  const modalId = useRef(-1);

  const now = new Date();

  const privateSaleText = () => {
    return (
      <span className={"private-sale-text-normal"}>
        <span className={"private-sale-text-amount"}>{commaNum(PRIVATE_SALE_AMOUNT)}</span> <span className={"private-sale-text-small"}>VOLR</span>
      </span>
    );
  };

  const firstSalePayableAmount = 2696874;
  const secondSalePayableAmount = 117621;
  const secondPlusSalePayableAmount = 261989;

  const firstSaleText = () => {
    return (
      <span className={"private-sale-text-normal"}>
        <span className={"private-sale-text-amount"}>{commaNum(firstSalePayableAmount, 0)}</span>{" "}
        <span className={"private-sale-text-small"}>VOLR</span>
      </span>
    );
  };
  const secondSaleText = () => {
    return (
      <span className={"private-sale-text-normal"}>
        <span className={"private-sale-text-amount"}>{commaNum(secondSalePayableAmount, 0)}</span>{" "}
        <span className={"private-sale-text-small"}>VOLR</span>
      </span>
    );
  };

  const secondPlusSaleText = () => {
    return (
      <span className={"private-sale-text-normal"}>
        <span className={"private-sale-text-amount"}>{commaNum(secondPlusSalePayableAmount, 0)}</span>{" "}
        <span className={"private-sale-text-small"}>VOLR</span>
      </span>
    );
  };

  const privateSaleData = {
    title: "Private Sale",
    period: "~ 2022-09-27",
    text: privateSaleText(),
  };

  const firstPreSaleData = {
    title: "Volare 1st Pre-Sale",
    period: "~ 2022-10-04 23:59 UTC",
    text: firstSaleText(),
  };

  const secondPreSaleData = {
    title: "Volare 2nd Pre-Sale",
    period: "~ 2022-10-10 23:59 UTC",
    text: secondSaleText(),
  };

  const secondPlusPreSaleData = {
    title: "Volare 2nd Pre-Sale +",
    period: "~ 2022-10-20 23:59 UTC",
    text: secondPlusSaleText(),
  };

  const checkAddressKycStatus_ = async () => {
    try {
      return unwrapResult(
        await dispatch(
          checkAddressKycStatus({
            externalUserId: addressUser.user_id,
          })
        )
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  // const getFirstSaleParticipateResult_ = async () => {
  //   try {
  //     const result = unwrapResult(
  //       await dispatch(
  //         getSaleParticipateResult({
  //           saleId: 1,
  //         })
  //       )
  //     );
  //     const { total_payable_amount } = result || {};
  //     setFirstSalePayableAmount(total_payable_amount);
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // };
  //
  // const getSecondSaleParticipateResult_ = async () => {
  //   try {
  //     const result = unwrapResult(
  //       await dispatch(
  //         getSaleParticipateResult({
  //           saleId: 2,
  //         })
  //       )
  //     );
  //     const { total_payable_amount } = result || {};
  //     setSecondSalePayableAmount(total_payable_amount);
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // };
  //
  // const getSecondPlusSaleParticipateResult_ = async () => {
  //   try {
  //     const result = unwrapResult(
  //       await dispatch(
  //         getSaleParticipateResult({
  //           saleId: 3,
  //         })
  //       )
  //     );
  //     const { total_payable_amount } = result || {};
  //     setSecondPlusSalePayableAmount(total_payable_amount);
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // };

  const convertEthToDollar = useMemo(() => {
    // return commaNum((Number(currencyFor(publicSaleData?.saleParticipateResult?.totalDepositAmount)) * Number(saleParticipatePrice?.price)).toFixed(2))
    return commaNum(Number(currencyFor(publicSaleData?.saleParticipateResult?.totalPayableAmount) * Number(publicSaleData?.salePrice)).toFixed(2));
  }, [saleParticipatePrice, publicSaleData]);

  const openModalJoinSale = async () => {
    let popupX = document.body.offsetWidth / 2 - 700 / 2;
    let popupY = window.screen.height / 2 - 800 / 2;
    const kycStatus = await checkAddressKycStatus_();
    switch (kycStatus.is_verify) {
      case 0: {
        window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px, left=${popupX}, top=${popupY},resizable=yes`);
        return;
      }
      case 1:
        switch (kycStatus.checkKycResult.status) {
          case 0: {
            window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px, left=${popupX}, top=${popupY}, resizable=yes`);
            return;
          }
          case 1: {
            const newId = openModal("KycStatus", {
              status: 1,
            });
            modalId.current = newId;
            return;
          }
          case 2: {
            window.open(`/volare-kyc`, "Volare_KYC", `width=700px,height=800px,left=${popupX}, top=${popupY}, resizable=yes`);
            return;
          }
          case 3: {
            const newId = openModal("KycStatus", {
              status: 3,
            });
            modalId.current = newId;
            return;
          }
          case 4: {
            const newId = openModal("JoinSale", {
              VOLRAddress: EQAddress,
              saleData: publicSaleData,
            });
            modalId.current = newId;
            return;
          }
          default:
            return;
        }
      default:
        return;
    }
  };

  const [saleTypes, setSaleTypes] = useState([]);

  const getPublicSaleData = async () => {
    const saleTypes = await dispatchRequest(dispatch, getSaleTypes, {});
    setSaleTypes(saleTypes);
    const sales = camelCase(
      await dispatchRequest(dispatch, getSales, {
        lastId: 0,
        limit: 10000,
        category: "all",
        stage: "presale",
      })
    );
    if (sales?.length > 0) {
      const saleDetail = camelCase(
        await dispatchRequest(dispatch, getSaleDetailsById, {
          saleId: sales[0].saleId,
        })
      );
      setSaleId(sales[0].saleId);
      setPublicSaleData(saleDetail);
    } else {
      alert("No public sale");
      navigate(-1);
    }
  };

  const getParticipants = async () => {
    // const myJoins = await dispatchRequest(dispatch, getSaleParticipantDetails, {
    //   saleId,
    //   limit: 1000,
    //   lastId: 0,
    //   receiverAddress: EQAddress,
    // });
    setParticipants([]);
  };

  const states = useMemo(() => {
    return [
      {
        label: "Coin Name & Symbol",
        text: `${saleCurrency?.currency?.name} (${saleCurrency?.currency?.unit})`,
      },

      {
        label: "Sale Price",
        text: `1 ${saleCurrency?.currency?.unit} = $ ${publicSaleData?.salePrice}`,
      },
      {
        label: "Network",
        text: microChainDetail?.network?.label,
      },
      {
        label: "Total Sale Amount",
        text: (
          <span style={{ fontSize: !isMinimum ? 16 : 10 }}>
            {commaNum(Number(publicSaleData?.totalSaleAmount))} {saleCurrency?.currency?.unit}
          </span>
        ),
      },
      {
        label: "Microchain",
        text: microChainDetail?.micro_chain?.label,
      },
      {
        label: "Settlement period",
        text: (
          <span style={{ fontSize: !isMinimum ? 13 : 11 }}>Purchased VOLR will be distributed within 12 hours from the end of the sales period.</span>
        ),
      },
    ];
  }, [publicSaleData, microChainDetail, saleCurrency, purchaseCurrency, isMinimum]);

  const digitBlockStyle = useMemo(() => {
    return {
      width: !isMinimum ? 50 : 22,
      height: !isMinimum ? 60 : 40,
      fontSize: !isMinimum ? 26 : 18,
      backgroundColor: "#404040",
    };
  }, [isMinimum]);

  const navigateResult = () => {
    navigate("/sale/result");
  };

  useEffect(() => {
    switch (result.name) {
      case "JoinSale":
        if (result.isJoin) getParticipants();
        break;
    }
  }, [result]);

  useEffect(() => {
    if (EQAddress.length > 0 && saleId > 0) getParticipants();
  }, [EQAddress, saleId]);


  return (
    <div id={"public-sale"}>
      <div className="public-sale-header">
        <span className="public-sale-header-text">NOW LIVE</span>
        <span className="public-sale-header-text">TOTAL RAISED</span>
        <span className="public-sale-header-text">
          <span className="amount">
            {commaNum(
              (
                Number(PRIVATE_SALE_AMOUNT) +
                Number(firstSalePayableAmount) +
                Number(secondSalePayableAmount) +
                Number(secondPlusSalePayableAmount)
              ).toFixed(0)
            )}
          </span>
          <span className="unit">&nbsp;VOLR</span>
        </span>
      </div>
      <InlineContent backgroundClassName={"public-sale-background"} innerClassName={"public-sale-content"}>
        {/* 상단 뒤로가기 영역 */}
        {/* <div style={{display: "flex", alignItems: 'center'}}>
                    <button onClick={onClickBack}>
                        <img src={backButton} className={'public-sale-back-button'}/>
                    </button>
                    <span style={{marginLeft: 12}} className={'public-sale-project-text'}>Projects</span>
                </div> */}
        {/* sale 영역 */}

        <GradientBox data={privateSaleData} />
        <GradientBox data={firstPreSaleData} />
        <GradientBox data={secondPreSaleData} />
        <GradientBox data={secondPlusPreSaleData} />

        {/* <div className={"public-sale-layout"} style={{ marginTop: 40, marginBottom: 40 }}>
          <div className={"public-sale-project"} style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <img src={publicSaleData?.logoImage} className={"public-sale-project-image"} />
              <div style={{ marginLeft: 25 }}>
                <div style={{ display: "flex" }}>
                  <RoundStateBox>{Stages[publicSaleData?.stage]}</RoundStateBox>
                </div>
                <p className={"public-sale-project-name"} style={{ marginTop: 14 }}>
                  {publicSaleData?.projectName}
                </p>
                <p className={"public-sale-project-net"} style={{ marginTop: 6 }}>
                  {`${microChainDetail?.network?.label} / ${microChainDetail?.micro_chain?.label}`}
                </p>
              </div>
            </div>
            <p className={"public-sale-project-content"} style={{ marginTop: 24 }}>
              {publicSaleData?.detailDescription}
            </p>
            <div className={"public-sale-project-links"} style={{ marginTop: 24 }}>
              {publicSaleData?.salePlatforms?.map((icon, index) => (
                <a
                  key={index.toString()}
                  href={`${publicSaleData?.salePlatforms[index]?.link !== "" ? publicSaleData?.salePlatforms[index]?.link : "/"}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={icons[index]} style={{ marginRight: 13 }} className={"public-sale-project-link-icon"} />
                </a>
              ))}
            </div>

            <div className="button-container">
              <div
                className={"join-button bold button-animation"}
                onClick={() =>
                  window.open("https://s3.ap-northeast-2.amazonaws.com/cs-files.eqbr.com/VOLARE/Volare_Public_Sale_Preparation_Manual.pdf")
                }
              >
                How to Join - Part I
              </div>
              <div
                className={"join-button bold button-animation"}
                onClick={() => window.open("https://s3.ap-northeast-2.amazonaws.com/cs-files.eqbr.com/VOLARE/Volare_Public_Sale_Manual.pdf")}
              >
                How to Join - Part II
              </div>

              {EQAddress.length === 0 && <EQWalletConnect className={"sale-gradient-button bold"}>Connect Wallet</EQWalletConnect>}
              {EQAddress.length > 0 &&
                moment(UTCTimestamp(now)).isBetween(
                  moment(UTCTimestamp(publicSaleData?.saleDate?.saleStartDate)),
                  moment(UTCTimestamp(publicSaleData?.saleDate?.saleEndDate))
                ) && (
                  <MetamaskConnectButton className={"sale-gradient-button bold join-sale-button"} onSuccess={openModalJoinSale}>
                    Join Sale
                  </MetamaskConnectButton>
                )}
              {participants?.length > 0 && (
                <Button className={"common-button border-red bold"} onClick={navigateResult}>
                  History
                </Button>
              )}
            </div>
          </div>
          <div className={"public-sale-state"} style={{ paddingTop: 20 }}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 40 }}>
              <FlipClockCountdown
                to={moment(publicSaleData?.saleDate?.saleEndDate).utc().valueOf()}
                labels={["Days", "Hours", "Minutes", "Seconds"]}
                labelStyle={{ fontSize: 12, fontWeight: 500, color: "#ffffff" }}
                digitBlockStyle={digitBlockStyle}
                dividerStyle={{ color: "#47494c", height: 1 }}
                duration={0.7}
              />
            </div>
            <div className={"public-sale-state-view"}>
              <div
                style={
                  isMinimum
                    ? { display: "flex", flexDirection: "column" }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }
                }
              >
                <div className="public-sale-state-container">
                  <div className={"public-sale-state-main"}>
                    <p className={"public-sale-state-main-title"}>Total raised</p>
                    <p>
                      <span className={"public-sale-state-main-amount"}>
                        {commaNum(Number(currencyFor(publicSaleData?.saleParticipateResult?.totalPayableAmount)).toFixed(0))}
                      </span>
                      <span className={"public-sale-state-main-unit"}> {saleCurrency?.currency?.unit}</span>
                    </p>
                  </div>
                  <div className={"public-sale-state-sub"}>≈ ${convertEthToDollar}</div>
                </div>
              </div>
              <div style={{ marginTop: 10, marginBottom: 10 }} />
              <RoundKeyValueBox key={states[0].label} {...states[0]} />
              <div className={"public-sale-state-grid"} style={{ marginTop: 10, marginBottom: 10 }}>
                {states.slice(1, 5).map((state) => (
                  <RoundKeyValueBox key={state.label} {...state} />
                ))}
              </div>
              <RoundKeyValueBox key={states[5].label} {...states[5]} />
              <p className="public-sale-state-info">{`The price of VOLR in USD($) is fixed. However, the exchange ratio of ETH to VOLR will be determined based on the CoinMarketCap price of ETH at the time of the transaction is confirmed.`}</p>
              <div style={{ marginTop: 10, marginBottom: 10 }} />
              <p className="public-sale-state-info">{`The VOLR will be distributed after the settlement period.`}</p>
            </div>
          </div>
        </div> */}
      </InlineContent>
    </div>
  );
}

export default PublicSale;
