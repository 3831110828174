import React from 'react';
import './GradientBox.scss'
function GradientBox({data}) {
    return (
        <div className='gradient-box'>
            <div className="gradient-box-left">
                <div className="gradient-box-left-title">
                    {data.title}
                </div>
                <div className="gradient-box-left-period">
                    {data.period}
                </div>
            </div>
            <div className="gradient-box-right">
                <div className="gradient-box-right-text">
                    {data.text}
                </div>
            </div>
        </div>
    );
}

export default GradientBox;