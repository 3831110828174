import React, {useContext} from 'react';
import "./ModalJoinResult.scss"
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";
import ModalInput from "./detail/ModalInput";
import {ModalContext} from "../../../App";
import Button from "../button/Button";
import ModalInputWithUnit from "./detail/ModalInputWithUnit";
import {currencyFor} from "../../../utils/EthereumUtil";
import {UTCTimestamp} from "../../../utils/TimeUtil";
import BigNumber from 'bignumber.js';
import useMicroChainCurrency from 'view/hooks/useMicroChainCurrency';
import { useMediaQuery } from 'react-responsive';

function ModalJoinResult(props) {

    const {params, onModalClose} = useContext(ModalContext)

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });
    
    const saleCurrency = useMicroChainCurrency({microChainCurrencyId: params?.saleData?.coinId}); 
    const purchaseCurrency = useMicroChainCurrency({microChainCurrencyId: params?.saleData?.purchaseCoin});

    return (
        <div className={'modal-join-result'}>
            <ModalHeader title={'Join History'}/>
            <ModalContent>
                <p className={'modal-join-result-info'}>{'The settlement of the project you are currently participating in is in progress.'}</p>
                <div style={{padding: '36px 22px 0'}}>
                    <ModalInput name={'saleProjectName'}
                                label={'Sale Project Name'}
                                value={params?.saleData.projectName}
                                disabled={true}/>
                    <ModalInput name={'salePrice'}
                                label={'Sale Price'}
                                value={params?.saleData.salePrice}
                                style={{marginTop: 17}}
                                disabled={true}/>
                    <ModalInput name={'whisperAddress'}
                                label={'Whisper Address'}
                                value={params?.item.receiverAddress}
                                style={{marginTop: 17}}
                                disabled={true}/>
                    <ModalInputWithUnit name={'depositAmount'}
                                        label={'Deposit Amount'}
                                        value={currencyFor(params?.item.depositAmount)}
                                        style={{marginTop: 17}}
                                        unit={purchaseCurrency?.currency?.unit}
                                        disabled={true}/>
                    <ModalInput name={'joinDate'}
                                label={'Join Date'}
                                value={UTCTimestamp(params?.item.createdAt)}
                                style={{marginTop: 17}}
                                disabled={true}/>


                    <ModalInputWithUnit name={'expectedReward'}
                                        label={'Expected Reward'}
                                        value={BigNumber(currencyFor(params?.item.depositAmount)).dividedBy(BigNumber(params?.saleData.salePrice)).toFixed()}
                                        style={{marginTop: 17}}
                                        unit={saleCurrency?.currency?.unit}
                                        disabled={true}/>
                    <p style={{
                        padding: isMobile ? '10px 0 0 10px' : '10px 0 0 160px',
                        fontSize: 10,
                        color: '#de4014',
                        fontWeight: 500,
                        whiteSpace: 'break-spaces'
                    }}>
                        {'* The amount shown above is not the exact amount, but an estimate.\n' +
                            'It can be changed depending on final calculation.'}
                    </p>


                    <ModalInput name={'calculationPeriod'}
                                label={'Calculation Period'}
                                value={`${UTCTimestamp(params.saleData?.saleDate?.calculateStartDate)} ~ ${UTCTimestamp(params.saleData?.saleDate?.calculateEndDate)}`}
                                style={{marginTop: 17}}
                                disabled={true}/>

                    <ModalInput name={'distributionPeriod'}
                                label={'Distribution Period'}
                                value={`${UTCTimestamp(params.saleData?.saleDate?.distributionStartDate)} ~ ${UTCTimestamp(params.saleData?.saleDate?.distributionEndDate)}`}
                                style={{marginTop: 17}}
                                disabled={true}/>
                </div>
                <Button className={'gradient-button'}
                        style={{display: 'flex', margin: '14px auto 0'}}
                        onClick={onModalClose}>
                    Close
                </Button>

            </ModalContent>
        </div>
    );
}

export default ModalJoinResult;