import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getApiResult, getEmittedResult, isSocketConnected } from 'store/exchange/utils/communicationUtills';
import { errorCatcher } from 'store/exchange/utils/errorCatcher';
import { makeObjToUrlQuery } from 'store/exchange/utils/makeObjToUrlQuery';
import { invalidServerResponseHandler } from 'store/exchange/utils/invalidServerResponseHandler';

export const getPairs = createAsyncThunk('pairs/getPairs', async (payload, { getState, rejectWithValue }) => {
  try {
    const { marketIds, ...rest } = payload || {};
    const result = await Promise.all(marketIds.map(async (marketId) => {
      let pairResult;
      if (isSocketConnected(getState)) {
        pairResult = await getEmittedResult('dexGetPairsByMarketId', {
          marketId,
        });
      } else {
        const urlQuery = makeObjToUrlQuery({ marketId, ...rest });
        pairResult = await getApiResult(`pair${urlQuery}`, 'get');
      }
      const { pairs } = pairResult || {};
      if (pairs !== undefined) {
        const marketName = getState().markets.entities[marketId].name;
        return pairs.map((el) => ({ ...el, market: marketName }));
      }
      return invalidServerResponseHandler('Invalid Server Response', [rejectWithValue]);
    }));
    return _.flattenDepth(result);
  } catch (e) {
    throw errorCatcher(e);
  }
});

export const getCurrentPrice = createAsyncThunk(
  'pairs/getCurrentPrice',
  async (payload, { getState }) => {
    try {
      let result;
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetCurrentPrice', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`chart/current-price${urlQuery}`, 'get');
      }
      return result;
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);

export const getPriceLast24h = createAsyncThunk(
  'pairs/getPriceLast24h',
  async (payload, { getState }) => {
    try {
      let result;
      const { pairId } = payload || {};
      if (isSocketConnected(getState)) {
        result = await getEmittedResult('dexGetPriceLast24h', payload);
      } else {
        const urlQuery = makeObjToUrlQuery(payload);
        result = await getApiResult(`chart/price-last-24h${urlQuery}`, 'get');
      }
      const {
        change: twentyFourChange,
        gap: twentyFourGap,
        high: twentyFourHigh,
        low: twentyFourLow,
        volume: twentyFourVolume,
      } = result;
      return {
        id: pairId,
        twentyFourChange: twentyFourChange ?? 0,
        twentyFourGap: twentyFourGap ?? 0,
        twentyFourHigh: twentyFourHigh ?? 0,
        twentyFourLow: twentyFourLow ?? 0,
        twentyFourVolume: twentyFourVolume ?? 0,
      };
    } catch (e) {
      throw errorCatcher(e);
    }
  },
);
