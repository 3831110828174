import React from "react";
import "./HomeSection1.scss";
import InlineContent from "../../../common/InlineContent";
import mainVideo from "../../../assets/video/main_video.mp4";
import TextLoop from "react-text-loop";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as JoinImg } from "../../../assets/images/join_img.svg";

function HomeSection1(props) {
  const isMinimum = useMediaQuery({
    query: "(max-width:767px)",
  });

  return (
    <>
      <div className="main-helper" />
      <div className="video-box">
        <video autoPlay={true} loop={true} muted={true} playsInline={true} id="video" className="main-video">
          <source src={mainVideo} type="video/mp4" />
        </video>
      </div>
      <InlineContent backgroundClassName={"home-section1"} innerClassName={"home-section1-content"}>
        <div className="main-title-wrapper">
          <div className="sub-title top">{`Volare, a game network only for blockchain`}</div>
          <div className="main-title">
            {`Volare${isMinimum ? "\n" : " "}`}
            <TextLoop interval={3000} delay={1000} fade={true}>
              <span>Development</span>
              <span>Business</span>
              <span>Communication</span>
            </TextLoop>
            {`\nSupport`}
          </div>
          <div className="sub-title bottom">{`For the benefits of developers and users alike`}</div>
        </div>
        <div className="volrscan-button-wrapper">
          <div className="volrscan-button volrscan" onClick={() => window.open("https://volrscan.volare.network")}>
            <JoinImg className="join-img" />
            <span>VOLRSCAN</span>
          </div>
        </div>
      </InlineContent>
    </>
  );
}

export default HomeSection1;
