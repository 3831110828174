import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import "./PublicSaleResult.scss";
import {useDispatch, useSelector} from "react-redux";
import InlineContent from "../../common/InlineContent";
import backButton from "../../assets/images/back-button.png";
import Button from "../../common/button/Button";
import {useNavigate} from "react-router-dom";
import {user_info} from "../../../store/user/userSlice";
import {dispatchRequest} from "../../../utils/dispatchRequest";
import {
    getSaleDetailsById,
    getSaleParticipantDetails,
    getSales,
    getSaleTypes
} from "../../../store/public-sale/publicSaleThunk";
import {camelCase} from "../../../utils/CommonUtils";
import InputTag from "../../common/input/InputTag";
import HistoryCard from "./HistoryCard";
import {getUTCOffset, UTCTimestamp} from "../../../utils/TimeUtil";
import moment from "moment";
import Table from "view/common/table/Table";
import {currencyFor} from "utils/EthereumUtil";
import {commaNum} from "utils/NumberUtil";
import {ModalContext} from "App";
import useMicroChainCurrency from "view/hooks/useMicroChainCurrency";
import {useMediaQuery} from "react-responsive";
import BigNumber from "bignumber.js";
import ReactTooltip from 'react-tooltip';
import tooltipIcon from '../../assets/images/exclamation-circle.svg'

function PublicSaleResult(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const {EQAddress, metamaskAddress} = useSelector(user_info);

    const [publicSaleData, setPublicSaleData] = useState({});
    const [participants, setParticipants] = useState([]);

    const saleCurrency = useMicroChainCurrency({microChainCurrencyId: publicSaleData?.coinId});
    const purchaseCurrency = useMicroChainCurrency({microChainCurrencyId: publicSaleData?.purchaseCoin});

    const {id, result, openModal} = useContext(ModalContext);
    const modalId = useRef(-1);
    const openModalJoinResult = useCallback(
        (item) => {
            const newId = openModal("JoinResult", {
                item,
                saleData: publicSaleData,
            });
            modalId.current = newId;
        },
        [publicSaleData]
    );

    const initSaleResult = async () => {
        const sales = camelCase(
            await dispatchRequest(dispatch, getSales, {
                lastId: 0,
                limit: 10000,
                category: "all",
                stage: "presale",
            })
        );
        if (sales) {
            const saleDetail = camelCase(
                await dispatchRequest(dispatch, getSaleDetailsById, {
                    saleId: sales[0].saleId,
                })
            );
            setPublicSaleData(saleDetail);
            const myJoins = camelCase(
                await dispatchRequest(dispatch, getSaleParticipantDetails, {
                    saleId: sales[0].saleId,
                    limit: 1000,
                    lastId: 0,
                    receiverAddress: EQAddress,
                })
            );
            setParticipants(
                myJoins.map((element, index) => ({
                    ...element,
                    no: myJoins.length - index,
                })) ?? []
            );
        }
    };

    const onClickBack = () => {
        navigate("/sale");
    };

    useEffect(() => {
        if (!EQAddress.length > 0) navigate(-1);
    }, [EQAddress]);

    const headers = useMemo(
        () => [
            ...(!isMobile ? [{title: "No", accessor: "no"}] : []),
            {title: "Transferred", accessor: "amount"},
            {title: "Alloc", accessor: "price"},
            ...(!isMobile ? [{title: "Date", accessor: "date"}] : []),
            ...(!isMobile ? [{title: "Status", accessor: "state"}] : []),
            ...(isMobile ? [{title: "Date & State", accessor: "dateAndState"}] : []),
        ],
        [isMobile]
    );
    const renderer = useCallback(
        (data) => {
            return ({
            amount: `${commaNum(Number(currencyFor(data?.depositAmount)))} ${purchaseCurrency?.currency?.unit}`,
            price: <div style={{position: 'relative', width: 'fit-content',  margin: '0 auto', padding: 0, whiteSpace: 'nowrap'}}>
                {`${commaNum((parseFloat(currencyFor(data?.depositAmount)) * parseFloat(data?.price) / parseFloat(publicSaleData?.salePrice)).toFixed(0))} ${saleCurrency?.currency?.unit}`}
                <img
                    data-tip
                    data-for={`send-msg-tool-tip${data.no}`}
                    className="tooltip-icon"
                    src={tooltipIcon}
                    alt="info"
                />
                <ReactTooltip
                    id={`send-msg-tool-tip${data.no}`}
                    place={isMobile ? "top" : "right"}
                    type="success"
                    effect="solid"
                    backgroundColor="#242628FF"
                    textColor="#ffffff"
                    border={true}
                    borderColor="#646875"
                >
                    <div className="tool-tip-text">{`1 ${purchaseCurrency?.currency?.unit} = $ ${BigNumber(data?.price).toFixed(3)} ${moment(parseInt(data?.time)).format("HH:mm")} ${getUTCOffset()}`}</div>
                </ReactTooltip>
            </div>,
            date: moment(parseInt(data.updateDate)).format("YYYY. MM. DD. HH:mm"),
            // state: data?.isAccept ? <div style={{color: "#22de8f"}}>Success</div> :
            //     <div style={{color: "#de1f1f"}}>Rejected</div>,
            state: <div style={{color: "#22de8f"}}>Success</div>,
            dateAndState: (
                <div style={{textAlign: 'left', whiteSpace: 'nowrap'}}>
                    <div style={{color: "#22de8f", textAlign: 'left'}}>Success</div>
                    {moment(data.createdAt).format("YYYY. MM. DD. HH:mm")}
                </div>
            ),
        })},
        [publicSaleData, saleCurrency, purchaseCurrency]
    );

    useEffect(() => {
        initSaleResult();
    }, []);

    return (
        <div id={"public-sale-result"}>
            <InlineContent backgroundClassName={"public-sale-result-background"}
                           innerClassName={"public-sale-result-content"}>
                {/* 상단 뒤로가기 영역 */}
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                    <button onClick={onClickBack}>
                        <img src={backButton} className={"public-sale-result-back-button"}/>
                    </button>
                    <span className={"public-sale-result-project-text"}>History</span>
                    <div className={"public-sale-result-back-button"}/>
                </div>

                <div className="public-sale-result-notice">
                    <p className="public-sale-result-notice-label">Notice</p>
                    <p className="public-sale-result-notice-text">{`The details shown below are information about the transaction that occurred on the Ethereum network.\nThe transaction information may not be timely reflected due to delays in the Ethereum network.\nWe reserve the right to reject any subscription that does not meet requirements prescribed in the Pre-Sale policies set forth by us or violates any rules thereunder, whether willful or not, at our full discretion.`}</p>
                </div>

                <p className={"public-sale-result-label"} style={{marginTop: 60}}>
                    Whisper Address
                </p>
                <p className="public-sale-result-info-text">{EQAddress}</p>

                <p className={"public-sale-result-label"} style={{marginTop: 60}}>
                    History
                </p>
                <div className="public-sale-result-table">
                    <Table headers={headers} 
                        renderer={renderer}
                        data={participants}
                        // onClick={openModalJoinResult}
                        />
                </div>

                <div style={{width: "100%"}}>
                    <Button className={"gradient-button"} style={{display: "flex", margin: "36px auto"}}
                            onClick={onClickBack}>
                        Back
                    </Button>
                </div>
            </InlineContent>
        </div>
    );
}

export default PublicSaleResult;
