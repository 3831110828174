import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMicroChainCurrency } from 'store/public-sale/publicSaleThunk';
import { dispatchRequest } from 'utils/dispatchRequest';

const useMicroChainCurrency = ({microChainCurrencyId}) => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState();
  const requestGetCurrency = useCallback(async (microChainCurrencyId) => {
    return await dispatchRequest(dispatch, getMicroChainCurrency, {microChainCurrencyId})
  }, [dispatch])
  useEffect(() => {
    if(microChainCurrencyId){
      requestGetCurrency(microChainCurrencyId).then(res => setCurrency(res))
    }
  }, [microChainCurrencyId, requestGetCurrency])
  return currency
};

export default useMicroChainCurrency;
