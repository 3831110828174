import React, { useEffect, useMemo, useState } from 'react';
import Modal from "react-modal";
import './modal.scss';
import './JoinAirdropModal.scss';
import "react-toggle/style.css";
import webIcon from '../../assets/images/airdrop_join_web.png';
import twitterIcon from '../../assets/images/airdrop_join_twitter.png';
import telegramIcon from '../../assets/images/airdrop_join_telegram.png';
import discordIcon from '../../assets/images/airdrop_join_discord.png';
import mediumIcon from '../../assets/images/airdrop_join_medium.png';
import { useMediaQuery } from "react-responsive"; // for ES6 modules
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { joinAirdrop } from '../../../store/airdrop/airdropThunk';

const TERMS_DUMMY = [
  {
    id: 1,
    description: 'I hereby authorize Volare to share pertinent details with the respective third-party platform for the purpose of selecting the winners from its pool of eligible users. Volare will then cross-reference my Whisper MSG wallet address and Twitter address against its database of users who comply with the contest requirements.'
  },
  {
    id: 2,
    description: 'I understand and acknowledge that fulfilling the aforementioned conditions DOES NOT guarantee success as the winners will be determined solely by the airdrop partner according to their selection criteria.'
  },
  {
    id: 3,
    description: 'I will contact Volare directly if I have any queries about the results or distribution.'
  }
]

function JoinAirdropModal({ isOpen, openModal, closeModal, airdropInfo, timer, timeRemaining }) {

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: isMobile ? 20 : 25,
      maxHeight: '100vh'
    },
  };

  const [walletAddressLength, setWalletAddressLength] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [mediumUser, setMediumUser] = useState('');
  const [youtubeUser, setYoutube] = useState('');
  const [telegramAddress, setTelegramAddress] = useState('');
  const [retweet, setRetweet] = useState('');

  const walletAddressHandler = (e) => {
    setWalletAddress(e.target.value)
    setWalletAddressLength(e.target.value.length)
  }
  const twitterHandleHandler = (e) => {
    setTwitterHandle(e.target.value)
  }
  const telegramAddressHandler = (e) => {
    setTelegramAddress(e.target.value)
  }
  const retweetHandler = (e) => {
    setRetweet(e.target.value)
  }
  const mediumHandler = (e) => {
    setMediumUser(e.target.value)
  }
  const youtubeHandler = (e) => {
    setYoutube(e.target.value)
  }

  // const [timeRemaining, setTimeRemaining] = useState();

  // useEffect(() => {
  //   if (airdropInfo?.remain_time) {
  //     setTimeRemaining(airdropInfo.remain_time)
  //   }
  // }, [airdropInfo?.remain_time])

  // useEffect(() => {
  //   if (airdropInfo?.remain_time) {
  //     const ticker = setInterval(() => {
  //       setTimeRemaining(prevState => {
  //         return prevState - 1;
  //       })
  //     }, 1000);
  //     return () => {
  //       clearInterval(ticker)
  //     }
  //   }
  // }, [timeRemaining, airdropInfo?.remain_time])

  // const timer = useMemo(() => {
  //   return {
  //     days: timeRemaining > 0 ? parseInt(timeRemaining / (24 * 60 * 60), 10) : 0,
  //     hours: timeRemaining > 0 ? parseInt((timeRemaining / (60 * 60)) % 24, 10) : 0,
  //     minutes: timeRemaining > 0 ? parseInt((timeRemaining / 60) % 60, 10) : 0,
  //     seconds: timeRemaining > 0 ? timeRemaining % 60 : 0
  //   }

  // }, [timeRemaining])

  const [termsAgree, setTermsAgree] = useState([]);
  const checkAgree = (checked, index) => {
    setTermsAgree(termsAgree.map(item => item.id === index ? { ...item, agree: checked } : item))
  }

  useEffect(() => {
    setTermsAgree(TERMS_DUMMY.map((item, index) => item.id === index + 1 && { id: item.id, agree: false }))
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setWalletAddress('')
      setTwitterHandle('')
      setTelegramAddress('')
      setRetweet('')
      setTermsAgree(TERMS_DUMMY.map((item, index) => item.id === index + 1 && { id: item.id, agree: false }))
    }
  }, [isOpen])

  const [joinAirdropProceeding, setJoinAirdropProceeding] = useState(false);

  const joinActiveValidation = useMemo(() => {
    if (!isOpen) {
      return false
    }
    if (airdropInfo?.airdrop_events.find(item => item.sns === 'twitter' && item.user_action === 'follow') && twitterHandle === '') {
      return false
    }
    if (airdropInfo?.airdrop_events.find(item => item.sns === 'medium' && item.user_action === 'follow') && mediumUser === '') {
      return false
    }
    if (airdropInfo?.airdrop_events.find(item => item.user_action === 'retweet') && retweet === '') {
      return false
    }
    if (airdropInfo?.airdrop_events.find(item => item.user_action === 'join') && telegramAddress === '') {
      return false
    }
    if (airdropInfo?.airdrop_events.find(item => item.sns === 'youtube') && youtubeUser === '') {
      return false
    }
    if (termsAgree?.find(item => item.agree === false)) {
      return false
    }
    if (walletAddressLength !== 48) {
      return false
    }
    if (walletAddress.substring(0, 4) !== '0x1k') {
      return false
    }
    if (airdropInfo?.remain_time <= 0) {
      return false
    }
    if (joinAirdropProceeding) {
      return false
    }
    return true
  }, [airdropInfo, twitterHandle, telegramAddress, isOpen, retweet, termsAgree, walletAddressLength, walletAddress, joinAirdropProceeding, mediumUser, youtubeUser])

  const joinAirdrop_ = async () => {
    setJoinAirdropProceeding(true)
    try {
      const data = {
        airdropId: airdropInfo.id,
        address: walletAddress,
        twitterHandle: twitterHandle === '' ? null : twitterHandle,
        telegramAddress: telegramAddress === '' ? null : telegramAddress,
        retweetPost: retweet === '' ? null : retweet,
        medium: mediumUser === '' ? null : mediumUser,
        terms: termsAgree
      }
      const result = unwrapResult(await dispatch(joinAirdrop(data)))
      if (result === 'OK') {
        alert('Airdrop join completed !!');
        setJoinAirdropProceeding(false)
        closeModal();
      }
    } catch (e) {
      alert(e.message)
      setJoinAirdropProceeding(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-border"
      overlayClassName="modal"
      ariaHideApp={false}
    >
      <div id="JoinAirdropModal">
        <div className="join-airdrop-modal-wrapper">
          <div className="top-wrapper">
            <div className="top-left-wrapper">
              <div className="img-wrapper">
                <img className="card-img" src={airdropInfo?.logo_image} alt="logo_image" />
              </div>
              <div className="data-wrapper">
                <div className="name-wrapper">
                  {airdropInfo?.project_name}
                </div>
                {/* <div className="network-chain-wrapper"> */}
                {/* <span className="data-wrapper">
                    {airdropInfo?.network_name}
                  </span> */}
                {/* <span className="data-text">/</span> */}
                {/* <span className="data-wrapper">
                    {airdropInfo?.micro_chain_name}
                  </span> */}
                {/* </div> */}
                <div className="sns-wrapper">
                  {
                    airdropInfo?.website !== '' &&
                    <a href={airdropInfo?.website} target='_blank' rel="noreferrer">
                      <img className="sns-img" src={webIcon} alt="sns-img" />
                    </a>
                  }
                  {
                    airdropInfo?.twitter !== '' &&
                    <a href={airdropInfo?.twitter} target='_blank' rel="noreferrer">
                      <img className="sns-img" src={twitterIcon} alt="sns-img" />
                    </a>
                  }
                  {
                    airdropInfo?.telegram !== '' &&
                    <a href={airdropInfo?.telegram} target='_blank' rel="noreferrer">
                      <img className="sns-img" src={telegramIcon} alt="sns-img" />
                    </a>
                  }
                  {
                    airdropInfo?.discord !== '' &&
                    <a href={airdropInfo?.discord} target='_blank' rel="noreferrer">
                      <img className="sns-img" src={discordIcon} alt="sns-img" />
                    </a>
                  }
                  {
                    airdropInfo?.medium !== '' &&
                    <a href={airdropInfo?.medium} target='_blank' rel="noreferrer">
                      <img className="sns-img" src={mediumIcon} alt="sns-img" />
                    </a>
                  }
                </div>
              </div>
            </div>
            <div className="top-right-wrapper">
              <div className="time-remaining-wrapper">
                <div className="time-remaining-title">Time Remaining</div>
                <div className="time-remaining-row-wrapper">
                  <div className="time-remaining day">
                    <div className="time-data">{timer.days}</div>
                    <div className="time-data-unit">Day</div>
                  </div>
                  <div className="time-remaining">
                    <div className="time-data">{timer.hours}</div>
                    <div className="time-data-unit">hour</div>
                  </div>
                  <div className="dot">:</div>
                  <div className="time-remaining">
                    <div className="time-data">{timer.minutes}</div>
                    <div className="time-data-unit">min</div>
                  </div>
                  <div className="dot">:</div>
                  <div className="time-remaining">
                    <div className="time-data">{timer.seconds}</div>
                    <div className="time-data-unit">sec</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="type-wrapper">
            <div className="text-wrapper">
              In order to participate in this airdrop you need to fulfil the below requirements:
            </div>
            <div className="requirement-wrapper">
              {
                airdropInfo?.airdrop_events.filter(item => (item.sns === 'twitter' && item.user_action === 'follow')).map((item, index) => {
                  return (
                    <div className="requirement-row" key={index}>
                      <div className="title">{item.content}</div>
                      <a className="link-btn" href={`https://twitter.com/${item.link}`} target={'_blank'} rel="noreferrer">
                        Follow @{item.link}
                      </a>
                    </div>
                  )
                })
              }
              {
                airdropInfo?.airdrop_events.filter(item => item.user_action === 'join').map((item, index) => {
                  return (
                    <div className="requirement-row" key={index}>
                      <div className="title">{item.content}</div>
                      <a className="link-btn" href={`https://t.me/${item.link}`} target={'_blank'} rel="noreferrer">
                        Join Telegram
                      </a>
                    </div>
                  )
                })
              }
              {
                airdropInfo?.airdrop_events.filter(item => item.user_action === 'retweet').map((item, index) => {
                  return (
                    < div className="requirement-row" key={index} >
                      <div className="title">{item.content}</div>
                      <a className="link-btn" href={item.link} target={'_blank'} rel="noreferrer">
                        Retweet
                      </a>
                    </div>
                  )
                })
              }
              {
                airdropInfo?.airdrop_events.filter(item => (item.sns === 'medium' && item.user_action === 'follow')).map((item, index) => {
                  return (
                    < div className="requirement-row" key={index} >
                      <div className="title">{item.content}</div>
                      <a className="link-btn" href={`https://medium.com/@${item.link}`} target={'_blank'} rel="noreferrer">
                        Follow @{item.link}
                      </a>
                    </div>
                  )
                })
              }
              {
                airdropInfo?.airdrop_events.filter(item => (item.sns === 'youtube')).map((item, index) => {
                  return (
                    < div className="requirement-row" key={index} >
                      <div className="title">{item.content}</div>
                      <a className="link-btn" href={`${item.link}`} target={'_blank'} rel="noreferrer">
                        Subscribe
                      </a>
                    </div>
                  )
                })
              }
            </div>
            <div className="input-box-wrapper">
              <div className="input-wrapper">
                <div className="input-title">Wallet Address</div>
                <div style={{ position: 'relative' }}>
                  <input className="input-box" type="text" maxLength={48} placeholder="Enter Whisper wallet address" onChange={(e) => walletAddressHandler(e)} />
                  <div className='length-size'><span className='length'>{walletAddressLength}</span> / 48</div>
                </div>
              </div>
              {
                airdropInfo?.airdrop_events.find(item => (item.sns === 'twitter' && item.user_action === 'follow')) &&
                <div className="input-wrapper">
                  <div className="input-title">Twitter Handle</div>
                  <input className="input-box" placeholder="Enter your Twitter handle" onChange={(e) => twitterHandleHandler(e)} />
                </div>
              }
              {
                airdropInfo?.airdrop_events.find(item => item.user_action === 'join') &&
                <div className="input-wrapper">
                  <div className="input-title">Telegram Address</div>
                  <input className="input-box" placeholder="Enter your Telegram handle" onChange={(e) => telegramAddressHandler(e)} />
                </div>
              }
              {
                airdropInfo?.airdrop_events.find(item => item.user_action === 'retweet') &&
                <div className="input-wrapper">
                  <div className="input-title">Link to retweeted post</div>
                  <input className="input-box" placeholder="Enter link to retweeted post" onChange={(e) => retweetHandler(e)} />
                </div>
              }
              {
                airdropInfo?.airdrop_events.find(item => (item.sns === 'medium' && item.user_action === 'follow')) &&
                <div className="input-wrapper">
                  <div className="input-title">Medium username</div>
                  <input className="input-box" placeholder="Enter your Medium username" onChange={(e) => mediumHandler(e)} />
                </div>
              }
              {
                airdropInfo?.airdrop_events.find(item => (item.sns === 'youtube')) &&
                <div className="input-wrapper">
                  <div className="input-title">Youtube username</div>
                  <input className="input-box" placeholder="Enter your Youtube username" onChange={(e) => youtubeHandler(e)} />
                </div>
              }
            </div>

          </div>
          {/* {
            airdropInfo?.type === 1 &&

            <div className="type-wrapper">
              <div className="input-box-wrapper">
                <div className="text-box-wrapper">
                  <div className="text-box-title">Notice</div>
                  <textarea type="text" rows="10" className="content-input" placeholder={"Notice"} />
                </div>
                <div style={{ height: 10 }} />
                <div className="input-wrapper">
                  <div className="input-title">Wallet Address</div>
                  <input className="input-box" type="text" placeholder="Enter your BRX chain wallet address" maxLength="48" />
                </div>
              </div>
            </div>
          } */}
          <div className="toggle-wrapper">
            {
              TERMS_DUMMY.map((item, index) => (
                <div className='toggle-row' key={index}>

                  {/* <Toggle defaultChecked={false} icons={false} className='custom-toggle'
                    onChange={(e) => checkAgree(e.target.checked, item.id)} /> */}
                  <input
                    type={'checkbox'}
                    id={`terms-${item.id}`}
                    className="terms-check-box"
                    defaultChecked={false}
                    onChange={(e) => checkAgree(e.target.checked, item.id)} />
                  <label htmlFor={`terms-${item.id}`} className='text-wrapper'>
                    {item.description}
                  </label>
                </div>
              ))
            }
          </div>
          <div className="btn-wrapper">
            <button className="confirm-btn" disabled={!joinActiveValidation || timeRemaining <= 0} onClick={() => joinAirdrop_()}>
              {
                timeRemaining <= 0 ?
                  'Terminate Airdrop' :
                  'Join Airdrop'
              }
            </button>
          </div>
        </div>
      </div>
    </Modal >
  );
}

export default JoinAirdropModal;
