export const kycStatusTextInfo = [
    {
        isVerify: 0,
        label: 'Verify KYC',
        textColor: '#e5e5e5',
    },
    {
        isVerify: 1,
        label: 'KYC : ',
        textColor: '#e5e5e5',
        currentStatus: [
            {
                status: 4,
                text: 'Approved',
                textColor: '#39f756',
            },
            {
                status: 3,
                text: 'Rejected',
                textColor: '#de4014',
            },
            {
                status: 2,
                text: 'Retry',
                textColor: '#de4014',
            },
            {
                status: 1,
                text: 'Pending',
                textColor: '#ffcd26',
            },
            {
                status: 0,
                text: 'Incomplete',
                textColor: '#8b8b8b',
            }
        ]
    }
]

export const statusLabel = (kycStatus, kycStatusTextInfo) => {
    const statusObject = kycStatusTextInfo.find(el => el?.isVerify === kycStatus.isVerify)
    if(!kycStatus.isVerify){
        return(
            <div style={{color: `${statusObject?.textColor}`}}>
                {statusObject?.label}
            </div>
        )
    }else{
        const currentStatusTextInfo = statusObject?.currentStatus.find(el => el?.status === kycStatus.checkKycResult.status)
        return(
            <div style={{display: 'flex', color: `${statusObject?.textColor}`}}>
                {statusObject?.label}
                <div style={{color: `${currentStatusTextInfo?.textColor}`}}>
                    {currentStatusTextInfo?.text}
                </div>
            </div>
        )
    }
}