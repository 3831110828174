import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getNetworks } from './networkThunk';

const networksAdapter = createEntityAdapter({
  // selectId: (network) => network.networkId,
  // todo:: change
  selectId: (network) => network.id,
});

export const {
  selectAll: selectAllNetworks,
  selectIds: selectNetworksIds,
  selectById: selectNetworkById,
  selectTotal: selectNetworksTotal,
} = networksAdapter.getSelectors((state) => state.networks);

const networksSlice = createSlice({
  name: 'networks',
  initialState: networksAdapter.getInitialState({
    currentNetworkId: '',
    status: 'idle',
    error: null,
  }),
  reducers: {
    setCurrentNetworkId(state, action) {
      state.currentNetworkId = action.payload;
    },
    updateNetwork(state, action) {
      const { id, ...rest } = action.payload;
      networksAdapter.updateOne(state, { id, changes: rest });
    },
  },
  extraReducers: {
    [getNetworks.pending]: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    [getNetworks.rejected]: (state, action) => {
      if (state.status === 'pending') {
        state.status = 'rejected';
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error;
        }
      }
    },
    [getNetworks.fulfilled]: (state, action) => {
      if (state.status === 'pending') {
        networksAdapter.upsertMany(
          state,
          action.payload.map((el) => ({ ...el, price: 1 })),
        );
        state.status = 'fulfilled';
      }
    },
  },
});

// Action 영역
export const { setCurrentNetworkId, updateNetwork } = networksSlice.actions;

// Store 데이터 영역
export const currentNetworkId = (RootState) => RootState.networks.currentNetworkId;
export const networkStatus = (RootState) => RootState.networks.status;

export default networksSlice.reducer;
