import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { create } from 'qrcode';
import qs from 'qs'
import { errorCatcher } from '../../utils/CommonUtils';
const queryString = require('query-string');

const api_version = process.env.REACT_APP_API_VERSION;
const whisper_api_version = process.env.REACT_APP_WHISPER_API_VERSION;
const hub_server_url = process.env.REACT_APP_HUB_SERVER_URL;
const whisper_server_url = process.env.REACT_APP_WHISPER_SERVER_URL;

export const getVolareAirdrops = createAsyncThunk(
  "airdrop/getVolareAirdrops",
  async ({ id, limit, category, isVolare }, { getState }) => {
    let result;
    const query = qs.stringify({ id, limit, category, isVolare }, { addQueryPrefix: true, encode: false })
    result = await axios.get(hub_server_url + api_version + `airdrops/${query}`)
    return result.data;
  }
)

export const getAirdropDetailById = createAsyncThunk(
  "airdrop/getAirdropDetailById",
  async ({ id }) => {
    let result;
    result = await axios.get(queryString.stringifyUrl({
      url: `${hub_server_url + api_version}airdrops/${id}`
    }))
      if(result.data.id === 15 || result.data.id === 16|| result.data.id === 17){
          result.data.airdrop_events.push({
              content:'Subscribe our youtube channel',
              link:'https://www.youtube.com/channel/UCwjHVefVkToqi9FfGTlFakw/featured',
              sns:'youtube',
              user_action:'Subscribe'
          })
      }
    return result.data;
  }
)

export const joinAirdrop = createAsyncThunk(
  'airdrop/joinAirdrop',
  async (data, { getState }) => {
    try {

      let result;
      result = await axios.post(hub_server_url + api_version + `airdrops/${data.airdropId}/participate/${data.address}`, {
        twitterHandle: data.twitterHandle,
        telegramAddress: data.telegramAddress,
        retweetPost: data.retweetPost,
        terms: data.terms,
        medium: data.medium
      })
      return result.data;
    } catch (e) {
      errorCatcher(e)
    }
  }
)

export const getHtmlResources = createAsyncThunk(
  'airdrop/getHtmlResources',
  async ({ type }, { getState }) => {
    try {
      let result;
      const query = qs.stringify({ type }, { addQueryPrefix: true, encode: false })
      result = await axios.get(whisper_server_url + whisper_api_version + `pages` + query, {})
      return result.data;
    } catch (e) {
      errorCatcher(e)
    }
  }
)
