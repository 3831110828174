import React, { useEffect, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useDispatch, useSelector } from "react-redux";
import { user_access, user_info } from "../../store/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { createKycApplicant, getKycAccessToken } from "../../store/user/userThunk";
import Loading from "../common/Loading";

function SumSubSdk(props) {
  const userAccess = useSelector(user_access);
  const userInfo = useSelector(user_info);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);

  const createKycApplicant_ = async () => {
    try {
      await dispatch(
        createKycApplicant({
          externalUserId: JSON.stringify(userInfo.addressUser.user_id),
        })
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  const getAccessTokenForKyc = async () => {
    try {
      return unwrapResult(
        await dispatch(
          getKycAccessToken({
            userId: JSON.stringify(userInfo.addressUser.user_id),
          })
        )
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  const requestKycHandler = async () => {
    switch (userInfo.kycVerification.isVerify) {
      case 0: {
        await createKycApplicant_().then(async () => {
          const { token } = await getAccessTokenForKyc();
          setToken(token);
        });
        return;
      }
      case 1: {
        const { token } = await getAccessTokenForKyc();
        setToken(token);
        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    if (userInfo.kycVerification.isVerify !== null) {
      requestKycHandler();
    }
  }, [userInfo]);

  useEffect(() => {
    if (Boolean(token)) {
      setIsLoading(false);
    }
  }, [token]);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
          <Loading />
        </div>
      ) : Boolean(token) ? (
        <div style={{ display: "block", width: "100%" }}>
          <SumsubWebSdk
            accessToken={token}
            // updateAccessToken={() => console.log("updateAccessToken")}
            expirationHandler={() => Promise.resolve(token)}
            config={{
              lang: "en",
              i18n: {
                document: {
                  subTitles: {
                    IDENTITY: "Upload a document that proves your identity",
                  },
                },
              },
              onMessage: (type, payload) => {
                console.log("WebSDK onMessage", type, payload);
              },
              onError: (error) => {
                console.error("WebSDK onError", error);
              },
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            onMessage={(type, payload) => {
              console.log("onMessage", type, payload);
            }}
            onError={(data) => console.log("onError", data)}
          />
        </div>
      ) : (
        <div>Invalid access</div>
      )}
    </>
  );
}

export default SumSubSdk;
