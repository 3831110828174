import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import transactionReducer from "./transaction/transactionSlice";
import socketReducer from './exchange/socket/socketSlice';
import networkReducer from './exchange/network/networksSlice';
import pairsReducer from './exchange/pairs/pairsSlice';
import marketsReducer from './exchange/markets/marketsSlice';
import currenciesReducer from './exchange/currencies/currenciesSlice';
import accountReducer from './exchange/account/accountSlice';
import favoritesReducer from './exchange/favorites/favoritesSlice';
import feeReducer from './exchange/fee/feeSlice';
import socketManager from "../middleware/socketManager";

export const store = configureStore({
  reducer: {
    user: userReducer,
    transaction: transactionReducer,
    socket: socketReducer,
    networks: networkReducer,
    pairs: pairsReducer,
    markets: marketsReducer,
    account: accountReducer,
    favorites: favoritesReducer,
    currencies: currenciesReducer,
    fee: feeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketManager),
  devTools: process.env.NODE_ENV !== 'production',
});

export const RootState = store.getState();
