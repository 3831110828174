import { createSlice } from '@reduxjs/toolkit';
import { getMatchedOrderFee } from './feeThunk';

const feeSlice = createSlice({
  name: 'fee',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [getMatchedOrderFee.pending]: (state) => {
      if (state.status === 'idle') {
        state.status = 'pending';
      }
      state.error = null;
    },
    [getMatchedOrderFee.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [getMatchedOrderFee.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
    },
  },
});

export const feeStatus = (RootState) => RootState.fee.status;

export default feeSlice.reducer;
