import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-cycle

// Adapter 영역 - 선언부
const initialState = {
    result: {
        txHash: ''
    }
};

// Slice 영역 - 선언부
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setTransaction(state, action) {
            Object.keys(action.payload).forEach(el => {
                state[el] = action.payload[el];
            });
        },
    },
    extraReducers: {},
});

// Action 영역
export const { setTransaction } = userSlice.actions;

// Store 영역
export const transaction_info = state => state.transaction

// Reducer 영역
export default userSlice.reducer;
