import React from 'react';
import './Table.scss'

function Table(props) {
    const {headers, data, renderer, onClick, colGroup, addRow} = props || {};

    return (
        <table className={'main-table'}>
            {
                !!colGroup &&
                <colgroup>
                    {
                        colGroup.map((col, index) => <col key={`col-${index}`} {...col} />)
                    }
                </colgroup>
            }
            <thead>
            <tr>
                {
                    headers.map((header, index) => <th key={header.title || `header-${index}`}>{header.title || '' }</th>)
                }
            </tr>
            </thead>
            <tbody>
            {
                data.map((el, index) =>
                    <tr key={`data-${index}`} onClick={()=> onClick && onClick(el,index)}>
                        {
                            headers.map(header => <td key={header.accessor}>{(!!renderer && renderer(el)[header.accessor]) || el[header.accessor]}</td>)
                        }
                    </tr>
                )
            }
            {
                addRow && <tr onClick={addRow}><td colSpan={headers.length} className={'add-row'}>+</td></tr>
            }
            </tbody>
        </table>
    );
}

export default Table;