import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getCurrentPrice, getPairs, getPriceLast24h } from './pairsThunk';

const pairsAdapter = createEntityAdapter({
  selectId: (pair) => pair.id,
});

export const {
  selectAll: selectAllPairs,
  selectIds: selectPairsIds,
  selectById: selectPairById,
  selectTotal: selectPairsTotal,
} = pairsAdapter.getSelectors((state) => state.pairs);

const pairsSlice = createSlice({
  name: 'pairs',
  initialState: pairsAdapter.getInitialState({
    status: 'idle',
    error: null,
  }),
  reducers: {
    updatePairs(state, action) {
      const { id, ...rest } = action.payload;
      pairsAdapter.updateOne(state, { id, changes: { ...rest } });
    },
    clearPairs: pairsAdapter.removeAll,
  },
  extraReducers: {
    [getPairs.pending]: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    [getPairs.rejected]: (state, action) => {
      if (state.status === 'pending') {
        state.status = 'rejected';
        state.error = action.payload;
      }
    },
    [getPairs.fulfilled]: (state, action) => {
      pairsAdapter.setMany(
        state,
        action.payload.map((el) => ({
          ...el,
          price: '0',
          change: 0,
          volume: '0',
          gap: 0,
          low: '0',
          high: '0',
          previousClose: 0,
          twentyFourChange: 0,
          twentyFourGap: 0,
          twentyFourHigh: 0,
          twentyFourLow: 0,
          twentyFourVolume: 0,
        })),
      );
      state.status = 'fulfilled';
    },
    [getCurrentPrice.fulfilled]: (state, action) => {
      pairsAdapter.updateMany(
        state,
        action.payload.map((priceData) => {
          const {
            pair_id: pairId,
            previous_close_price: previousClose,
            ...rest
          } = priceData || {};
          return { id: pairId, changes: { previousClose, ...rest } };
        }),
      );
    },
    [getPriceLast24h.fulfilled]: (state, action) => {
      const { id, ...rest } = action.payload;
      pairsAdapter.updateOne(state, { id, changes: { ...rest } });
    },
  },
});

// Action 영역
export const { updatePairs, clearPairs } = pairsSlice.actions;

// Store 데이터 영역
export const pairStatus = (RootState) => RootState.pairs.status;

export default pairsSlice.reducer;
