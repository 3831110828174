import { ethers } from "ethers";
import BigNumber from "bignumber.js";

// const Web3 = require('web3');

// let web3 = new Web3('ws://localhost:8546');

export const decToBigHex = (decimalNum) => {
  switch (typeof decimalNum) {
    case "number":
      return ethers.utils.parseEther(decimalNum.toString())._hex;
    case "string":
      try {
        return ethers.utils.parseEther(decimalNum)._hex;
      } catch (e) {
        return "0x00";
      }
    default:
      return "0x00";
  }
};

export const formatAddress = (address) => {
  if (address.length !== 42) throw new Error("address length is mismatched");
  return ethers.utils.hexlify(ethers.utils.zeroPad(address, 32));
};

// export const aaa = async () => {
//     if (window.ethereum) {
//         // const web3 = getWeb3();
//
//         if (!web3) {
//             throw new Error('web3 is null');
//         }
//
//         try {
//             // await this.ethereum.enable();
//             // const accounts = await web3.eth.getAccounts();
//             let encodedData = web3.eth.abi.encodeFunctionCall({
//                 name: 'wrapETH',
//                 type: 'function',
//                 inputs: [
//                     {
//                         "internalType": "bytes32",
//                         "name": "targetNetChainId",
//                         "type": "bytes32"
//                     },
//                     {
//                         "internalType": "bytes32",
//                         "name": "recipient",
//                         "type": "bytes32"
//                     }
//                 ]
//             }, [formatEqbrNetChainId("EQHUB_11","EQBHUB_1"), formatEqbrAddress("0x1kasda")]);
//
//             const transactionParameters = {
//                 // gasLimit: '0x989680', // 10,000,000
//                 to: '0x9e838A7a963B11Aa59b7fa043b43446dA22be4b1', // Required except during contract publications.
//                 from: metamaskAddress, // must match user's active address.
//                 value: decToBigHex(0.1), // 9184e72a000
//                 data: encodedData,
//                 chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
//             };
//
//             setWaitingForRequest(true);
//
//             const txHash = await window.ethereum.request({
//                 method: 'eth_sendTransaction',
//                 params: [transactionParameters]
//             });
//
//             console.log('thash ', txHash);
//         } catch (error) {
//             console.log(error.message);
//             alert(error.message);
//         } finally {
//             setWaitingForRequest(false);
//         }
//     }
// }

export function stringToBytes16(str) {
  return ethers.utils.hexlify(ethers.utils.zeroPad(ethers.utils.toUtf8Bytes(str), 16));
}

export function formatEqbrNetChainId(networkId, chainId) {
  const nId = stringToBytes16(networkId);
  const cId = stringToBytes16(chainId);
  return ethers.utils.hexConcat([nId, cId]);
}

function formatEqbrAddress(address) {
  if (address.length !== 48) throw new Error("address length is mismatched");
  const contentAfterPrefix = "0x" + address.slice(4);
  const zero10byte = ethers.utils.hexZeroPad("0x0", 10);
  return ethers.utils.hexConcat([zero10byte, contentAfterPrefix]);
}

export const weiFor = (number) => {
  return new BigNumber(number).multipliedBy("1e+18").toString();
};

export const currencyFor = (wei) => {
  return new BigNumber(wei).dividedBy("1e+18").toString();
};
