import React, {useContext, useMemo} from 'react';
import "./modal.scss";
import './ModalKYCStatus.scss';
import Button from "../button/Button";
import ModalHeader from "./detail/ModalHeader";
import ModalContent from "./detail/ModalContent";
import {ModalContext} from "../../../App";

function ModalKYCStatus(props) {

    const {params, onModalClose} = useContext(ModalContext);

    const onClickConfirm = () => {
        onModalClose();
    }

    const renderText = useMemo(() => {
        switch(params.status){
            case 1:
                return 'The process of reviewing the KYC document is in progress.'
            case 3:
                return 'KYC verification rejected.\n' + 'To retry KYC verification, please contact help@volare.network'
        }
    },[params])

    return (
        <div className={'modal-kyc-status'}>
            <ModalHeader title={'KYC status'}/>
            <ModalContent>
                <div className={'content-text'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom:'30px', whiteSpace: 'pre-wrap', textAlign: "center"}}>
                    {renderText}
                </div>
                <Button className={'gradient-button'}
                        style={{display: 'flex', margin: '18px auto 0'}}
                        onClick={onClickConfirm}
                >OK</Button>
            </ModalContent>
        </div>
    );
}

export default ModalKYCStatus;


