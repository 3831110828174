import React, { useEffect, useMemo, useState } from "react";
import "./Airdrop.scss";
import InlineContent from "../../common/InlineContent";
import logo from "../../assets/images/VOLR_logo.png";
import backgroundImg from "../../assets/images/airdrop_bg_img.png";
import { useDispatch } from "react-redux";
import JoinAirdropModal from "../../common/modal/JoinAirdropModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAirdropDetailById, getHtmlResources, getVolareAirdrops } from "../../../store/airdrop/airdropThunk";
import axios from "axios";
import { dispatchRequest } from "../../../utils/dispatchRequest";

function Airdrop(props) {
  const dispatch = useDispatch();

  const [joinAirdropModalOpen, setJoinAirdropModalOpen] = useState(false);
  const [selectedAirdropId, setSelectedAirdropId] = useState(0);

  const openJoinAirdropModal = (id) => {
    setSelectedAirdropId(id);
    setJoinAirdropModalOpen(true);
  };

  const closeJoinAirdropModal = () => {
    setJoinAirdropModalOpen(false);
  };

  const [volareAirdropInfo, setVolareAirdropInfo] = useState();
  const [rule, setRule] = useState("");
  const [participate, setParticipate] = useState("");

  const getVolareAirdrops_ = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          getVolareAirdrops({
            id: 0,
            limit: 100,
            category: "open",
            isVolare: true,
          })
        )
      );
      setVolareAirdropInfo(result);
    } catch (e) {
      // alert(e.message)
    }
  };

  const getHtml = async (url) => {
    const html = await axios.get(url);
    return html?.data;
  };

  const _getHtmlResources = async () => {
    // TODO :: html 경로를 받아오는 router 요청
    const result1 = await dispatchRequest(dispatch, getHtmlResources, {
      type: 0,
    });
    if (result1) setRule(await getHtml(result1.location));
    const result2 = await dispatchRequest(dispatch, getHtmlResources, {
      type: 1,
    });
    if (result2) setParticipate(await getHtml(result2.location));
  };

  useEffect(() => {
    getVolareAirdrops_();
    _getHtmlResources();
  }, []);

  const [airdropInfo, setAirdropInfo] = useState();

  const getAirdropDetail_ = async () => {
    if (Array.isArray(volareAirdropInfo) && volareAirdropInfo.length > 0) {
      try {
        const result = unwrapResult(await dispatch(getAirdropDetailById({ id: volareAirdropInfo[0].id })));
        if (result !== undefined) {
          setAirdropInfo(result);
        }
      } catch (e) {
        // alert(e.message)
      }
    }
  };

  useEffect(() => {
    if (volareAirdropInfo) {
      getAirdropDetail_();
    }
  }, [volareAirdropInfo]);
  const [timeRemaining, setTimeRemaining] = useState();

  useEffect(() => {
    if (airdropInfo?.remain_time) {
      setTimeRemaining(airdropInfo.remain_time);
    }
  }, [airdropInfo?.remain_time]);

  useEffect(() => {
    if (airdropInfo?.remain_time) {
      const ticker = setInterval(() => {
        setTimeRemaining((prevState) => {
          return prevState - 1;
        });
      }, 1000);
      return () => {
        clearInterval(ticker);
      };
    }
  }, [timeRemaining, airdropInfo?.remain_time]);

  const timer = useMemo(() => {
    return {
      days: timeRemaining > 0 ? parseInt(timeRemaining / (24 * 60 * 60), 10) : 0,
      hours: timeRemaining > 0 ? parseInt((timeRemaining / (60 * 60)) % 24, 10) : 0,
      minutes: timeRemaining > 0 ? parseInt((timeRemaining / 60) % 60, 10) : 0,
      seconds: timeRemaining > 0 ? timeRemaining % 60 : 0,
    };
  }, [timeRemaining]);

  return (
    <>
      {joinAirdropModalOpen && (
        <JoinAirdropModal
          isOpen={joinAirdropModalOpen}
          openModal={openJoinAirdropModal}
          closeModal={closeJoinAirdropModal}
          airdropInfo={airdropInfo}
          timer={timer}
          timeRemaining={timeRemaining}
        />
      )}
      <img src={backgroundImg} className="airdrop-section1-background" alt={"backgroundImg"} />
      <InlineContent backgroundClassName={"airdrop-container1"} innerClassName={"airdrop-content1"}>
        <div className="airdrop-section1">
          <div className="main-title-box">
            <div className="airdrop-main-title">mini VOLR?</div>
            <div className="airdrop-sub-title">
              {`mini VOLR is a Event Token of VOLR prior to Volare Mainnet's Launching. mini VOLR mainly performs the role that promotes Volare project. Join various airdrop events through several channels and receive mini VOLR as a reward for participating. Since mini VOLR will be exchanged for VOLR after mainnet is launched, mini VOLR helps many people come into VOLR mainnet with low entry barriers and eventually contributes to vitalize VOLR mainnet ecosystem. Get mini VOLR, Get ready for future!`}
            </div>
            {volareAirdropInfo?.map((item, index) => {
              return (
                <button className="join-btn" key={index} onClick={() => openJoinAirdropModal(item.id)}>
                  {`Join Airdrop`}
                </button>
              );
            })}
          </div>
          <div className="logo-img-box">
            <img src={logo} className="logo-img" alt={"logo"} />
          </div>
        </div>
      </InlineContent>

      <InlineContent backgroundClassName={"airdrop-container2"} innerClassName={"airdrop-content2"}>
        <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: rule }} />
      </InlineContent>

      <InlineContent backgroundClassName={"airdrop-container3"} innerClassName={"airdrop-content3"}>
        <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: participate }} />
      </InlineContent>
    </>
  );
}

export default Airdrop;
