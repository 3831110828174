import {createSlice} from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-cycle
import {refreshToken, checkAddressKycStatus, logout} from "./userThunk";

// Adapter 영역 - 선언부
// const initialState = {
//   address: '',
//   publicKey: '',
//   EQAddress: '',
//   metamaskAddress: '',
//   kycVerification: {
//     isVerify: null,
//     checkKycResult: {
//       walletAddress: "",
//       status: null,
//       applicantId: null,
//       createdAt: '',
//       updatedAt: ''
//     }
//   }
//
// };
const initialState = {
    addressUser: {
        address: '',
        created_at: "",
        nickname: "",
        updated_at: "",
        user_id: null,
        user_type: null,
        wallet_address: ''
    },
    token: {
        access: {
            expires: '',
            token: ''
        },
        refresh: {
            expires: '',
            token: ''
        }
    },
    metamaskAddress: "",
    EQAddress: "",
    kycVerification: {
        isVerify: null,
        checkKycResult: {
            walletAddress: "",
            status: null,
            applicantId: null,
            createdAt: '',
            updatedAt: ''
        }
    }
};

// Slice 영역 - 선언부
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserInfo(state, action) {
            Object.keys(action.payload).forEach((el) => {
                state[el] = action.payload[el];
            });
        },
        setMetamaskAddress(state, action) {
            state.metamaskAddress = action.payload;
        },
        signOut(state, action) {
            Object.keys(initialState).forEach((el) => {
                state[el] = initialState[el];

            });
        },
        expire(state, action) {
            state.expire = true;
        },
        setKycStatus(state, action) {
            Object.keys(action.payload).forEach((el) => {
                state[el] = action.payload[el];
            });
        },
    },
    extraReducers: {
        [refreshToken.fulfilled]: (state, action) => {
            Object.keys(action.payload).forEach((el) => {
                state[el] = action.payload[el];
            });
            state.EQAddress = action.payload.addressUser.address;
        },
        [checkAddressKycStatus.fulfilled]: (state, action) => {
            state.kycVerification.isVerify = action.payload.is_verify;
            if (action.payload.is_verify) {
                state.kycVerification.checkKycResult = action.payload.checkKycResult;
            }
        },
        [logout.fulfilled]: (state, action) => {
            Object.keys(initialState).forEach((el) => {
                state[el] = initialState[el];
            });
        }
    },
});

// Action 영역
export const {setUserInfo, signOut, setMetamaskAddress} = userSlice.actions;

// Store 영역
export const user_info = (state) => state.user;
export const user_access = (state) => state.user.token?.access?.token;

// Reducer 영역
export default userSlice.reducer;
