import React from 'react';
import './PublicSaleJoin.scss';
import { useDispatch } from 'react-redux';

function PublicSale(props) {
  const dispatch = useDispatch();

  return (
    <div id={'public-sale-join'}>
        <div className='main-helper' />
        Public Sale Join
    </div>
  );
}

export default PublicSale;
