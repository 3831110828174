import React, { useState } from "react";
import "./Footer.scss";
import footerLogo from "../../assets/images/footer_logo.png";
import joinImg from "../../assets/images/footer_join_img.png";
import twitterImg from "../../assets/images/twitter_img.png";
import telegramImg from "../../assets/images/telegram_img.png";
import mediumImg from "../../assets/images/medium_img.png";
import { Link } from "react-router-dom";
import axios from "axios";
import whitepaper from "../../assets/pdf/whitepaper.pdf";

// const FOOTER_MENU_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS", "WHITEPAPER", "LITEPAPER", "VOLRSCAN"];
const FOOTER_MENU_TAB = ["VOLARE?", "ROADMAP", "ECOSYSTEM", "NEWS", "VOLRSCAN"];

function Footer(props) {
  const [email, setEmail] = useState("");

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    let body = {
      email: email,
      title: "volare",
      content: "volare Subscribe mail",
    };
    axios
      .post("https://gateway.eqbr.com/api/v1/CS/services/1/inquiries", body)
      .then((res) => {
        if (res.status === 200) {
          alert("Thank you for signing up. We will always send you our updated news.");
          setEmail("");
        }
      })
      .catch((err) => {
        alert("Please enter the correct email format.");
      });
  };

  const snsClickHandler = (sns) => {
    if (sns === "twitter") {
      window.open("https://twitter.com/volare_official");
    }
    if (sns === "telegram") {
      window.open("https://t.me/volareofficial");
    }
    if (sns === "medium") {
      window.open("https://medium.com/@Volare_Official");
    }
  };

  const footerTabClickHandler = (tab) => {
    if (tab === "WHITEPAPER") {
      window.open(process.env.REACT_APP_WHITEPAPER_URL);
      return;
    }
    if (tab === "LITEPAPER") {
      window.open(process.env.REACT_APP_LITEPAPER_URL);
      return;
    }
    if (tab === "VOLRSCAN") {
      window.open("https://volrscan.volare.network");
      return;
    }
    window.location.href = `${window.location.origin}/#${tab}`;
  };

  return (
    <footer>
      <div className="footer-wrapper">
        <div className="top-wrapper">
          <a href="/">
            <img src={footerLogo} className="footer-logo" alt="footer-logo" />
          </a>
          <div className="mailing-wrapper">
            <div className="mailing-text">Join our mailing list!</div>
            <div className="mailing-input-box">
              <input type={"email"} onChange={(e) => emailHandler(e)} className="mailing-input" placeholder="Enter your email" />
              <button className="mailing-btn" onClick={(e) => submitHandle(e)}>
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper" style={{ borderTop: "1px solid #2a2d33", borderBottom: "1px solid #2a2d33" }}>
        <div className="middle-wrapper">
          <div className="middle-box">
            <div className="middle-content-title">Menu</div>
            <ul className="middle-menu-list">
              {FOOTER_MENU_TAB.map((item, index) => {
                return (
                  <li className="middle-menu-item" key={index} onClick={() => footerTabClickHandler(item)}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="middle-box">
            <div className="middle-content-title">Address</div>
            <div className="footer-info">
              <div>111 Somerset Road,</div>
              <div>#15-06 111</div>
              <div>Somerset Singapore</div>
              <div>(238164)</div>
            </div>
          </div>
          <div className="middle-box">
            <div className="middle-content-title">Contact</div>
            {/* <div className='footer-info'>
                            <div>Tel: +82 2 533 281</div>
                        </div> */}
            <div className="footer-sns-wrapper">
              <img src={twitterImg} className="footer-sns" onClick={() => snsClickHandler("twitter")} alt="twitter" />
              <img src={telegramImg} className="footer-sns" onClick={() => snsClickHandler("telegram")} alt="telegram" />
              <img src={mediumImg} className="footer-sns" onClick={() => snsClickHandler("medium")} alt="medium" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="bottom-box">
          <p className="copyright">© 2022 D Dream Foundation LTD</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
