import React from 'react';
import './PublicSaleHistory.scss';
import { useDispatch } from 'react-redux';

function PublicSaleHistory(props) {
  const dispatch = useDispatch();

  return (
    <div id={'public-sale-history'}>
        <div className='main-helper' />
        Public Sale History
    </div>
  );
}

export default PublicSaleHistory;
